<template>
  <div class="MS-bg">
    <div class="MS-hd">
      <div class="c">
        <span class="title">{{ $t('MINE.H1') }}</span>
        <span class="l" >{{ $filters.currencySymbol(me.availableAmount) }}</span>
        <span class="r" @click="toMyPlayList()"><i> </i></span>
        <span class="r2" @click="toGameLobby()"><i> </i></span>
      </div>
    </div>

    <div class="MS-main">

      <div class="MS-tabs">
        <button v-for="(item, index) in rowCountTab" :key="index" type="button" 
          :class="currentGame.rowCount === item ? 'btn-tabs-select active' : 'btn-tabs-select'" 
          @click="gamePrepare(item)">
          {{item}} x {{item}}
        </button>
      </div>

      <div class="checkerboard" v-show="loadingMask">
        <div class="checkerboard-c">
          <ul :class="'x'+ currentGame.rowCount">
            <li v-for="(item, index) in currentGame.gridBtnList" :key="index" :class="'xx' + (index + 1)">
              <img class="bg" src="../assets/images/game_mine/bg113.png" />
              <div class="bg-off" @click="sweepClick(item)"  v-if="item.pickax || !item.open"></div>
              <div class="bg-on" v-show="item.open">
                <img v-if="item.pickax" class="pickax-icon" src="../assets/images/game_mine/bg141b.gif" />
                <div v-else-if="item.status === 'safe' || item.status === 'end'" class="gold-box">
                  <div class="gold-icon" v-show="item.gold"><span><i v-show="currentGame.rowCount !== 6">+</i>{{ $filters.currencySymbol(currentGame.rowCountAmount) }}</span></div>
                </div>
                <div v-else-if="item.status === 'bomb'" class="bomb-box"></div>
              </div>
            </li>
          </ul>
          <div class="over-lay" v-show="!currentGame.playing && currentGame.gridBtnList.length > 1"></div>
          <button type="button" class="btn-start" v-show="!currentGame.playing && currentGame.gridBtnList.length > 1" @click="gameStart"></button>
        </div>
      </div>

      <div class="bet-info" v-show="currentGame.playing">
        <div class="l">
          <h4>{{ $t('MINE.BONUS') }}</h4>
          <strong>{{ $filters.currencySymbol(currentGame.winAmount) }}</strong>
          <p></p>
        </div>
        <div class="r">
          <h4>{{ $t('MINE.NEXT') }}</h4>
          <strong>{{ $filters.currencySymbol(currentGame.nextAmount) }}</strong>
          <p></p>
        </div>
        <button type="button" class="btn-stop" @click="gameEnd"></button>
        <div class="tx">{{ $t('MINE.STOP_AND_GET_BONUS') }}</div>
        <div class="b">
          <span>{{$t('game.betAmount')}}: 
            <strong>{{ currentGame.order.originBetAmount > 0 ? $filters.currencySymbol(currentGame.order.originBetAmount) : $filters.currencySymbol(myBet.betAmount)}}</strong>
          </span>
          <!-- <span>{{ $t('PARITY.FEE') }}: {{ currentGame.config.feeRatio }}%</span> -->
        </div>
      </div>

      <div class="order-box" v-show="loadingMask">
        <div class="order-tabs">
          <strong :class="orderTabActive ? 'a' : ''" @click="playersList">{{ $t('PARITY.EVERYONE_ORDER') }}</strong>
          <strong :class="orderTabActive ? '' : 'a'" @click="myHistoryList">{{ $t('PARITY.MY_ORDER') }}</strong>
        </div>
        <div class="g-players">
            <!--**************************************** 其他人订单 ****************************************-->
            <ul v-show="orderTabActive">
              <li>
                <span class="l" style="width:70px;color: #85e9ff;">{{ $t('PARITY.USER') }}</span>
                <span class="l" style="width:30px;color: #85e9ff;">{{ $t('MINE.ROW') }}</span>
                <span class="l" style="width:70px;color: #85e9ff;">{{ $t('MINE.POINT') }}</span>
                <span class="l" style="width:70px;color: #85e9ff;">{{ $t('PARITY.AMOUNT') }}</span>
                <span class="r" style="color: #85e9ff;">{{ $t('MINE.SELECT') }}</span>
              </li>
              <li v-for="(item, index) in everyoneOrders" :key="index" style="height:60px">
                <span class="l" style="width:70px">{{ item.mobile }}</span>
                <span class="l" style="width:30px">{{ item.rowCount }}x{{ item.rowCount }}</span>
                <span class="l" style="width:70px">{{ $filters.currencySymbol(item.betAmount) }}</span>
                <span class="l" style="width:70px;color: #ffe611;">{{ $filters.currencySymbol(item.winAmount) }}</span>
                <span class="r" style="float: right;padding-right: 0px;">
                  <dl :class="'x' + item.rowCount">
                    <dd v-for="i of item.rowCount * item.rowCount"  :key="i">
                      <div v-for="ii in item.sweptCells" :key="ii" :class="i === ii ? 'c' : ''"></div>
                      <div v-if="i === item.bombCell" class="b"></div>
                    </dd>
                  </dl>
                </span>
              </li>
            </ul>
            <!--**************************************** 我的订单 ****************************************-->
            <ul v-show="!orderTabActive">
              <li>
                <span class="l" style="width:75px;color: #85e9ff;">{{ $t('MINE.ORDER_NUM') }}</span>
                <span class="l" style="width:35px;color: #85e9ff;">{{ $t('MINE.ROW') }}</span>
                <span class="l" style="width:55px;color: #85e9ff;">{{ $t('MINE.POINT') }}</span>
                <span class="l" style="width:40px;color: #85e9ff;">{{ $t('PARITY.FEE') }}</span>
                <span class="r" style="color: #85e9ff;">{{ $t('MINE.WIN_AMOUNT') }}</span>
              </li>
              <li v-for="(item, index) in myPlays" :key="index">
                <span class="l" style="width:75px">***{{ item.orderNum.substring((item.orderNum.length-6),item.orderNum.length) }}</span>
                <span class="l" style="width:35px">{{ item.rowCount }}</span>
                <span class="l" style="width:55px">{{ $filters.currencySymbol(item.originBetAmount) }}</span>
                <span class="l" style="width:40px">{{ item.feeAmount }}</span>
                <span v-if="item.winAmount" class="r" style="color: #ffe611;">+ {{ $filters.currencySymbol(item.winAmount) }}</span>
                <span v-else class="r" style="color: #bbaf78;"> </span>
              </li>
              <li style="text-align: center;opacity: .5;" @click="myPlayList">{{ $t('game.more') }} >></li>
            </ul>
        </div>
      </div>

      <nut-overlay v-model:visible="overlayShow" :close-on-click-overlay="false" :z-index="2000" overlay-class="overlay-div overlay-bg0">
      </nut-overlay>

    </div>
  
    <div class="rule-btn"  @click="rulePopClick"></div>

    <!--**************************************** 下注面板 ****************************************-->
    <nut-popup position="bottom" closeable round v-model:visible="showBetPanel" pop-class="betPanelBox">
      <div class="betting-panel">
        <h2>{{ $t('MINE.CHOOSE_GAME_AMOUNT') }}</h2>
        <div class="panel-hd">
          <div class="l">
            <span>{{ $t('PARITY.BALANCE') }}:</span>
            <br/>
            <strong>{{ $filters.currencySymbol(me.availableAmount) }}</strong>
            <nut-button size="small" type="primary" @click="rechargeClick" style="min-winth:70px">
              {{ $t('home.recharge') }}
            </nut-button>
          </div>
          <div class="r">
            <li>
              <span>{{ $t('PARITY.FEE') }}:</span>
              <strong>{{currentGame.config.feeRatio}}%</strong>
            </li>
          </div>
        </div>

        <div class="panel-bd">
          <h6>{{ $t('PARITY.CONTRACT_POINT') }}</h6>
          <div class="btnbox">
            <button v-for="(item, index) in currentGame.recommendBetAmountsList" :key="index" 
              :class="currentGame.btnActiveInx === index ? 'btn a' : 'btn'" @click="btnBetClick(item, index)"
              :disabled="(item >= currentGame.minBetAmount && item <= currentGame.maxBetAmount) ? false : true">
              {{ item }}
            </button>
            <button :class="currentGame.btnActiveInx === currentGame.recommendBetAmountsList.length ? 'btn a' : 'btn'" @click="btnBetClick(me.availableAmount, currentGame.recommendBetAmountsList.length)" >
              {{ $t('PARITY.ALL') }}
            </button>
          </div>

          <h6>{{ $t('PARITY.NUMBER') }} <span>({{ currentGame.minBetAmount + '~' + currentGame.maxBetAmount}})</span></h6>
          <div class="inpbox">
            <button class="btn-bet btn-sub" @click="btnSubtract">-</button>
            <input v-model="myBet.betAmount" class="inp-bet" type="text" readonly @click="showKeyBoard"/>
            <button class="btn-bet btn-plus" @click="btnAddition">+</button>
          </div>

          <div class="btn-confirm">
            <nut-button @click="bet" block type="info" class="btn-bg-g" :disabled="myBet.betting">
              <nut-icon v-if="myBet.betting" name="loading" size="20" style="margin-right:10px"></nut-icon>
              <span>{{ $t('PARITY.CONFIRM') }}</span>
            </nut-button>
          </div>
        </div>
      </div>
    </nut-popup>

    <!--**************************************** 结算面板 ****************************************-->
    <nut-popup pop-class="pop-mine-inventory" v-model:visible="showSettlePanel" :close-on-click-overlay="false">
      <div :class="currentGame.winAmount > 0 ? 'main win' : 'main'">
        <div class="hd">
          <strong v-if="currentGame.winAmount > 0">+{{ currentGame.winAmount }}</strong>
          <span v-else>+0</span>
        </div>
        <div class="bd">
          <ul :class="'x'+ currentGame.rowCount">
            <li v-for="(item, index) in currentGame.gridBtnList" :key="index" :class="item.open ? 'open' : ' '">
              <img v-if="item.status === 'bomb'" :src="require('../assets/images/game_mine/bg130.png')"/>
            </li>
          </ul>
        </div>
        <div class="btn">
          <nut-button type="primary" @click="closeSettlePanel">{{ $t('global.okText') }}</nut-button>
        </div>
      </div>
    </nut-popup>

    <!--**************************************** 游戏规则 ****************************************-->
    <nut-popup position="top" closeable round :style="{ height: '70%',  background:'#30284C' }" v-model:visible="rulePopup">
        <GameRulesMine></GameRulesMine>
    </nut-popup>


    <!--**************************************** Big win ****************************************-->
    <nut-popup pop-class="pop-top-bigwin" v-model:visible="bigwinData.show" position="left" :closeable="true" :overlay="false" style="top: 5px !important;">
      <div class="bd">
        <div class="t1"><img class="avatar" :src="require('../assets/images/global/bigwin.png')" >
            <span>{{ bigwinData.data.mobile }}</span>
          </div>
        <div v-if="bigwinData.data.appTypeCode === 'crash'" class="t2 crash"><span>{{ bigwinData.data.crashEscapeRatio }}x</span></div>
        <div v-else-if="bigwinData.data.appTypeCode === 'parity'" class="t2 parity"><span>{{ bigwinData.data.parityRoomName }}</span></div>
        <div v-else-if="bigwinData.data.appTypeCode === 'wheel'" class="t2 wheel"><span> </span></div>
        <div v-else-if="bigwinData.data.appTypeCode === 'mine'" class="t2 mine"><span>{{ bigwinData.data.mineRowCount }} X {{ bigwinData.data.mineRowCount }}</span></div>
        <div v-else-if="bigwinData.data.appTypeCode === 'andar'" class="t2 andar"><span></span></div>
        <div v-else-if="bigwinData.data.appTypeCode === 'dice'" class="t2 dice"><span></span></div>
        <div class="t3"><span>WIN</span><strong>{{ bigwinData.data.winAmount }}</strong></div>
      </div>
    </nut-popup>

    <!--**************************************** Broadcast Msg ****************************************-->
    <nut-popup pop-class="pop-top-broadcast-msg" v-model:visible="broadcastMsgData.show" position="left" :closeable="true" :overlay="false" style="top: 5px !important;">
      <div class="bd">
        <div class="t4" v-if="broadcastMsgData.type === 'parity_prediction'">&nbsp;</div>
        <div class="t5"><span>{{ broadcastMsgData.message}}</span></div>
      </div>
    </nut-popup>


  </div>
</template>

<script>
import { reactive, toRefs } from "vue"
import { useRouter } from "vue-router"
import { Toast } from "@nutui/nutui"
import { getCountry,setLastTimeGameName } from "@/assets/js/environment"
import {isBlank, isNotBlank} from "@/assets/js/commonUtil"
import axios from "axios"
import router from "@/router"
import GameRulesMine from "../components/GameRulesMine.vue"
import {commonEvent, createSocket, disconnectSocket, gameType, initGame} from "@/assets/js/game";
import { getCommonInfoAttr } from "@/assets/js/commonInfo";

export default {
  name: "mineHome",
  components: {

    GameRulesMine: GameRulesMine,
  },
  data() {
    return {
      country: null,  //当前国家
      commonInfo: null,  //公共信息
      showBetPanel: false, //下注面板显示开关
      showSettlePanel: false, //结算面板显示开关
      rulePopup: false, //规则显示开关
      showDialogFill: true,
      loadingMask: false,
      orderTabActive: true, //订单列表 tab 切换开关
      rowCountTab: [], //棋盘Tab 列表
      overlayShow: true,
      imgUrl : localStorage.getItem('countryImgUrl'),
      me:{
        availableAmount: '',
        inRookieProcess: '',
      },
      myBet: {
        betAmount: 20,
        betting: false,
      },
      currentGame:{
        btnActiveInx: '',
        rowCount: '',  //单元格行数
        gridTotal: '',  //单元格总数
        gridBtnList: [], //单元格按钮 列表
        config: '',
        recommendBetAmountsList: [],
        defaultBetAmount: 10,
        minBetAmount: 10,
        maxBetAmount: 100000,
        order: '',
        playing: false,
        orderNum: null,
        winAmount: 0,
        nextAmount: null,
        rowCountAmount: 0,
      },
      bigwinData: {
        show: false,
        start: false,
        list: [],
        data: {}
      },
      broadcastMsgData: {
        show: false,
        type: '',
        message: '',
      },
      myPlays: [],
      everyoneOrders: [],
      appTaskStatus: '',
      appTaskReward: '',
    };
  },
  created() {
    this.country = getCountry() //当前国家
    this.commonInfo = JSON.parse(localStorage.getItem("commonInfo"))
    this.currentGame.recommendBetAmountsList = this.commonInfo.recommendBetAmountList,
    this.currentGame.minBetAmount =  Math.floor(this.commonInfo.betAmountRange.min)
    this.currentGame.maxBetAmount =  Math.floor(this.commonInfo.betAmountRange.max)
  },
  setup() {
    const state = reactive({
      tabbarActiveIndex: -1,
    });
    const router = useRouter();
    const methods = {
      backClick() {
        router.go(-1);
      }
    };
    return {
      ...toRefs(state),
      ...methods,
    };
  },
  
  // ======================================== mounted ========================================
  mounted() {
    Toast.loading('Loading...', {
      id: "init",
      duration: 0,
      bgColor: "rgba(0, 0, 0, 0.9)",
      cover: true,
    });
    //初始化页面
    initGame(gameType.MINE).then((response) => {
      Toast.hide("init");
      const data = response.data.data;
      if(isNotBlank(data.appTaskStatus)){
        this.appTaskReward = data.appTaskReward
        this.appTaskStatus = data.appTaskStatus
      }
      //游客id
      if (isNotBlank(data.vtoken)) {
        localStorage.setItem("vtoken", data.vtoken);
      }
      this.me.availableAmount = data.availableAmount
      this.me.inRookieProcess = data.inRookieProcess
      this.rowCountTab = data.rowCounts

      if (isNotBlank(localStorage.getItem("mineCurrentRowCount"))) {
        this.currentGame.rowCount = localStorage.getItem("mineCurrentRowCount")
      }else{
        if (data.rowCounts.length > 1) {
          this.currentGame.rowCount = data.rowCounts[1];
        }else{
          this.currentGame.rowCount = data.rowCounts[0];
        }
      }

      //初始化socket
      this.initSocket();

      //准备当前行
      this.gamePrepare(this.currentGame.rowCount)
      this.loadingMask = true

      setLastTimeGameName('mine','lobby')
      this.overlayShow = false
    });
  },

  // ======================================== beforeRouteLeave ========================================
  beforeRouteLeave() {
    //断开游戏socket
    disconnectSocket(gameType.MINE, this.socket);
    Toast.hide()
  },

  // ======================================== methods ========================================
  methods: {
    /**
     * 初始化socket
     */
    initSocket() {
      if (this.socket != null) {
        return;
      }
      this.socket = createSocket(gameType.MINE,this.bigwinData, this.broadcastMsgData);

      /**
       * 就绪
       */
      this.socket.on(commonEvent.ready, () => {
        this.socket.emit(commonEvent.join, {t: gameType.MINE});
      });

      /**
       * 其他人的订单
       */
      this.socket.on("s_mn_orders", (event) => {
        this.everyoneOrders.unshift(event)
        if (this.everyoneOrders.length >= 6) {
          this.everyoneOrders.pop()
        }
      });

    },
    //跳转到游戏大厅
    toGameLobby(){
      router.push({path: "lobby"});
    },
    //跳转到我的下注历史
    toMyPlayList() {
      router.push({path: "myPlayListMine"});
    },
    //准备游戏
    gamePrepare(rowCount) {
      Toast.loading("Loading", {
        id: 'prepare',
        duration: 0
      });
      this.currentGame = {
        rowCount: '',
        gridTotal: '',
        gridBtnList: [],
        config: '',
        recommendBetAmountsList: this.commonInfo.recommendBetAmountList,
        defaultBetAmount: Math.floor(this.commonInfo.betAmountRange.min),
        minBetAmount: Math.floor(this.commonInfo.betAmountRange.min),
        maxBetAmount: Math.floor(this.commonInfo.betAmountRange.max),
        order: '',
        playing: false,
        orderNum: null,
        winAmount: 0,
        nextAmount: null,
      },
      axios.get("/api/mine/prepare?rowCount=" + rowCount).then((res) => {
        Toast.hide('prepare');
        this.currentGame.config = res.data.data.config
        this.currentGame.rowCount = res.data.data.config.rowCount
        this.currentGame.gridTotal = res.data.data.config.rowCount * res.data.data.config.rowCount
        this.currentGame.gridBtnList = []
        for (let i = 0; i < (this.currentGame.gridTotal); i++) {
          this.currentGame.gridBtnList.push({ cell: i+1, open: false, status: 'new', pickax: true })  // new safe bomb end
        }
        if(isNotBlank(res.data.data.order)){
          this.currentGame.order = res.data.data.order
          this.currentGame.playing = true
          this.currentGame.orderNum =  res.data.data.order.orderNum
          this.currentGame.winAmount = res.data.data.order.winAmount
          this.currentGame.nextAmount = res.data.data.nextAmount
          let sweptCells = res.data.data.order.sweptCells
          if(sweptCells.length > 0){
            for(var i in sweptCells){	
              this.currentGame.gridBtnList[sweptCells[i] -1].open = true
              this.currentGame.gridBtnList[sweptCells[i] -1].status = 'safe'
              this.currentGame.gridBtnList[sweptCells[i] -1].pickax = false
            }
          }
        }
      })
    },
    //唤起下注面板
    gameStart(){
      this.showBetPanel = true
      if (this.me.inRookieProcess) {
        this.myBet.betAmount = this.currentGame.minBetAmount;
      }
    },
    //下注按钮点击
    btnBetClick(a, i) {
      this.currentGame.btnActiveInx = i
      if (a > this.currentGame.maxBetAmount) {
        this.myBet.betAmount = this.currentGame.maxBetAmount
      } else if (a < this.currentGame.minBetAmount) {
        this.myBet.betAmount = this.currentGame.minBetAmount
      } else {
        this.myBet.betAmount = a
      }
    },
    //下注加减
    btnAddition() {
      if (isBlank(this.myBet.betAmount)) {
        this.myBet.betAmount = this.currentGame.defaultBetAmount
        return
      }
      if (this.myBet.betAmount <= 99) {
        this.myBet.betAmount = this.$math.add(this.myBet.betAmount, 10)
      } else if (this.myBet.betAmount <= 999) {
        this.myBet.betAmount = this.$math.add(this.myBet.betAmount, 100)
      } else if (this.myBet.betAmount <= 9999) {
        this.myBet.betAmount = this.$math.add(this.myBet.betAmount, 1000)
      } else if (this.myBet.betAmount <= 99999) {
        this.myBet.betAmount = this.$math.add(this.myBet.betAmount, 10000)
      } else if (this.myBet.betAmount <= 999999) {
        this.myBet.betAmount = this.$math.add(this.myBet.betAmount, 100000)
      } else if (this.myBet.betAmount <= 9999999) {
        this.myBet.betAmount = this.$math.add(this.myBet.betAmount, 1000000)
      } else if (this.myBet.betAmount <= 999999999) {
        this.myBet.betAmount = this.$math.add(this.myBet.betAmount, 1000000)
      }
      if (this.myBet.betAmount > this.currentGame.maxBetAmount) {
        Toast.text("Maxmum of " + this.currentGame.maxBetAmount);
        this.myBet.betAmount = this.currentGame.maxBetAmount
      }
    },
    btnSubtract() {
      if (isBlank(this.myBet.betAmount) || this.myBet.betAmount <= this.currentGame.minBetAmount) {
        Toast.text("Minimum of " + this.currentGame.minBetAmount);
        return
      }
      if (this.myBet.betAmount <= 100) {
        if (this.myBet.betAmount > this.currentGame.minBetAmount)this.myBet.betAmount = this.$math.subtract(this.myBet.betAmount, 10)
      } else if (this.myBet.betAmount <= 1000) {
        this.myBet.betAmount = this.$math.subtract(this.myBet.betAmount, 100)
      } else if (this.myBet.betAmount <= 10000) {
        this.myBet.betAmount = this.$math.subtract(this.myBet.betAmount, 1000)
      } else if (this.myBet.betAmount <= 100000) {
        this.myBet.betAmount = this.$math.subtract(this.myBet.betAmount, 10000)
      } else if (this.myBet.betAmount <= 1000000) {
        this.myBet.betAmount = this.$math.subtract(this.myBet.betAmount, 100000)
      } else if (this.myBet.betAmount <= 10000000) {
        this.myBet.betAmount = this.$math.subtract(this.myBet.betAmount, 1000000)
      } else if (this.myBet.betAmount <= 1000000000) {
        this.myBet.betAmount = this.$math.subtract(this.myBet.betAmount, 1000000)
      }
      if (this.myBet.betAmount < this.currentGame.minBetAmount) {
        this.myBet.betAmount = this.currentGame.minBetAmount
      }
    },
    //下注
    bet() {
      //新手下注限制
      if (this.me.inRookieProcess && this.myBet.betAmount > this.currentGame.minBetAmount) {
        Toast.fail(this.$t('global.NON_VIP_CAN_ONLY_INPUT') + this.currentGame.minBetAmount);
        this.myBet.betAmount = this.currentGame.minBetAmount;
        return;
      }

      if (isBlank(this.myBet.betAmount) || this.myBet.betAmount <= 0) {
        Toast.fail(this.$t('PARITY.PLEASE_CONFIRM_YOUR_INPUT'))
        return;
      }
      this.myBet.betting = true;
      axios.post("/api/mine/bet",
          {
            rowCount: this.currentGame.rowCount,
            betAmount: this.myBet.betAmount
          }
      ).then((res) => {
        this.myBet.betting = false;
        let data = res.data.data;
        if (res.data.code === 0) {
          this.showBetPanel = false;
          this.currentGame.playing = true
          Toast.success(this.$t('global.successfully') + ' ' + this.myBet.betAmount);
          this.me.availableAmount = data.availableAmount;
          this.currentGame.orderNum = data.orderNum;
          this.currentGame.nextAmount = data.nextAmount;
          localStorage.setItem("mineCurrentRowCount", this.currentGame.rowCount);
        } else if (res.data.bizCode === 'GAME_BET_NEED_WITHDRAW') {
          this.showBetPanel = false;
        }
      })
    },
    // 挖
    sweepClick(o){
      if(o.open || o.status === 'on') return
      o.open = true
      axios.post("/api/mine/sweep",
          {
            orderNum: this.currentGame.orderNum,
            cell: o.cell
          }
      ).then((res) => {
        let data = res.data.data;
        if (res.data.code === 0 && data.valid) {
          o.pickax = false
          this.currentGame.rowCountAmount = this.currentGame.nextAmount
          if(data.result === 'safe'){
            o.status = 'safe'
            this.currentGame.winAmount = data.winAmount
            this.currentGame.nextAmount = data.nextAmount;
            o.gold = true
          }else if(data.result === 'bomb'){
            o.status = 'bomb'
            this.currentGame.winAmount = 0
            this.currentGame.nextAmount = 0
            this.currentGame.gridBtnList[data.bombCell -1].status = 'bomb'
            this.me.availableAmount = data.availableAmount
            setTimeout(() => {
              this.showSettlePanel = true
              this.currentGame.playing = false
            }, 1000);
          }else{
            o.status = 'end'
            o.gold = true
            this.currentGame.gridBtnList[data.bombCell -1].status = 'bomb'
            this.me.availableAmount = data.availableAmount
            setTimeout(() => {
              this.showSettlePanel = true
              this.currentGame.playing = false
            }, 1000);
          }
        }else{
          o.pickax = false
          o.open = false
        }
      });
    },
    //手动结束游戏
    gameEnd(){
      Toast.loading("Loading", {
        id: 'end',
        duration: 0
      });
      axios.post("/api/mine/end",
          {
            orderNum: this.currentGame.orderNum,
          }
      ).then((res) => {
        let data = res.data.data;
        if (res.data.code === 0) {
          this.currentGame.winAmount = data.winAmount
          this.currentGame.gridBtnList[data.bombCell -1].status = 'bomb'
          this.me.availableAmount = data.availableAmount
          this.showSettlePanel = true
        }
        Toast.hide('end');
      });
    },
    //关闭结算面板
     closeSettlePanel() {
      this.showSettlePanel = false;
      this.gamePrepare(this.currentGame.rowCount)
    },
    //去充值
    rechargeClick() {
      if(this.commonInfo.firstRechargeAvailable){
        router.push('/firstRechargeBonus');
      }else{
        router.push('/rechargeBalance');
      }
    },
    //去提现
    withdrawClick() {
      router.push('/withdrawalBalance?amountType=coin');
    },
    //试玩
    toGameDemo(){
      if(localStorage.getItem('token') === null){
        this.$router.push('/userRegister');
      }
    },
    rulePopClick(){
      this.rulePopup = true
    },
    //其他玩家的订单
    playersList() {
      this.orderTabActive = true
    },
    //我的订单
    myHistoryList() {
      this.orderTabActive = false
      axios({
        url: "/api/mine/order/paginate",
        method: "get",
        params: {
          current: 1,
          queryLimit: 'today'
        }
      }).then((res) => {
        const data = res.data.data;
        this.myPlays = data.records
      });
    },
    //跳转到我的下注历史
    myPlayList() {
      router.push({
        path: "myPlayListMine"
      });
    },
    commonInfoAttr(key) {
      return getCommonInfoAttr(key);
    },

    //下载app
    downloadApp(){
      window.open(getCommonInfoAttr('appDownloadUrl'))
    }

  },
};
</script>

<style scoped src="../assets/css/game_mine.css"></style>
<style scoped>
</style>
<style>
.pop-mine-inventory{ background-color: transparent !important;}
</style>



