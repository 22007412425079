<template>
  <div>
    <nut-navbar
    :left-show="false"
        :title="$t('transfer.h1')"
        fixed=true
        class="mb0 nav-icon-right nut-navbar--fixed"
      >
      <template #left>
        <i class="nutui-iconfont nut-icon nut-icon-left" @click="backClick"></i>
      </template>
    </nut-navbar>

    <div class="recharge-bd" style="padding-top: 60px;">
      
      <div class="transfer-box">
        <div class="l">
          <div class="w150">
            <span>{{$t('withdrawal.agentAmount')}}</span>
            <h5>{{fundAccount.cashAccount}}</h5>
          </div>
        </div>
        <div class="c">

        </div>
        <div class="r">
          <div class="w150">
            <span>{{$t('withdrawal.gameAccount')}}</span>
            <h5>{{fundAccount.coinAccount}}</h5>
          </div>
        </div>
      </div>

      <nut-form class="label-w150">
        <nut-form-item :label="$t('transfer.transferAmount')">
          <input
              class="nut-input-text"
              type="text"
              v-model="transferAmount"
              v-on:input="inputChange"
              :readonly="!allowCustomAmount"
          />
        </nut-form-item>
        <span class="allbtn" @click="allClick">ALL</span>
      </nut-form>

      <div class="login-btn m10">
        <nut-button block type="info" @click="transferSubmit" :disabled="btnDisabled">{{$t('transfer.btntx')}}</nut-button>
      </div>
      <div class="bt-tx">
        <p><strong>{{$t('withdrawal.description')}}</strong></p>
        <p>{{$t('transfer.tx1')}}</p>
        <p>{{$t('transfer.tx2')}}</p>
      </div>    
    </div>

    <tabbar :activeIndex="tabbarActiveIndex"></tabbar>

  </div>
</template>

<script>
import {reactive, toRefs, onMounted, getCurrentInstance} from "vue";
import {useRouter} from "vue-router";
import TabbarMain from "../components/TabbarMain.vue";
import axios from "axios";
import {Toast} from "@nutui/nutui";
import { isBlank } from '@/assets/js/commonUtil';
import { getCountry } from "@/assets/js/environment";

export default {
  components: {
    tabbar: TabbarMain,
  },
  data(){
    return{
      country:'',
      i18n:{
        loading: this.$t('global.loading'),
        failEnter: this.$t('recharge.failEnter'),
        submittedSuccess: this.$t('transfer.success'),
      }
    }
  },
  created() {
    this.country = getCountry()
    this.textToast(this.$t('global.loading'))
  },
  setup() {
    const _data = getCurrentInstance();
    const state = reactive({
      eMail:'',
      allowCustomAmount: true,
      tabbarActiveIndex: -1,
      btnDisabled: true,
      transferAmount: 0,
      fundAccount:{
        cashAccount:'',
        coinAccount:''
      }
    });
    const router = useRouter();
    
    const methods = {
      backClick() {
        router.go(-1);
      },
      textToast(msg) {
        Toast.loading(msg, {
          id: "loading",
          duration: 0,
          bgColor: "rgba(0, 0, 0, 0.9)",
          cover: true,
        });
      },
      inputChange() {
        if(state.transferAmount <= 0 || state.transferAmount === null || state.transferAmount === ''){
          return
        }
        state.btnDisabled = false;
      },
      allClick(){
        state.transferAmount = state.fundAccount.cashAccount
        this.inputChange()
      },
      transferSubmit() {
        state.btnDisabled = true;
        if(state.transferAmount > state.fundAccount.cashAccount){
          Toast.fail(_data.data.i18n.failEnter)
          return
        }
        axios
          .post("/api/fund/transfer/cash-to-coin", {
            amount: state.transferAmount
          })
          .then(function (res) {
            if (res.data.code === 0) {
              Toast.success(_data.data.i18n.submittedSuccess);
              methods.getFundAccount()
            }
            state.btnDisabled = false;
          })
          .catch(function (error) {
            state.btnDisabled = false;
            console.log(error);
          });
      },
      getFundAccount(){
        axios
          .get("/api/user/home", {})
          .then(function (response) {
            state.fundAccount.cashAccount = response.data.data.cashAccount.availableAmount;
            state.fundAccount.coinAccount = response.data.data.coinAccount.availableAmount;
            Toast.hide("loading");
          })
          .catch(function (error) {
            console.log(error);
            Toast.hide("loading");
          })
      }
    };
    onMounted(() => {
      
      let commonInfo = JSON.parse(window.localStorage.getItem("commonInfo"));
      state.eMail = isBlank(commonInfo) ? ' ': commonInfo.serviceEmail
      methods.getFundAccount()
    });
    return {
      ...toRefs(state),
      ...methods,
    };
  },
};
</script>

<style scoped>
.transfer-box{ height: 150px; display: flex; }
.transfer-box .l{ width: 40%; margin-left: 5%;padding-top: 60px; background: url(../assets/images/global_icon/202405-28.png) center 0px no-repeat; background-size:50px;}
.transfer-box .r{ width: 40%; margin-right: 5%;padding-top: 60px; background: url(../assets/images/global_icon/202405-24.png) center 0px no-repeat; background-size:50px;}
.transfer-box .c{width: 10%;background: url(../assets/images/global/bg147.png) center 25px no-repeat; background-size:30px;}
.transfer-box .w150{background:#30284C; width: 85%; height: 70px; margin: 0 auto; border-radius:10px; text-align: center; padding-top: 10px;}
.transfer-box .w150 span{ color: #fff;font-size: 12px; line-height: 30px;}
.transfer-box .w150 h5{ margin: 0; padding: 0; color: #ffd908; font-size: 20px;}
.allbtn{ position: absolute; right: 37px; top:24px;  color: #fff; border-radius:14px;}
.label-w150 {position: relative;}
</style>
