<template>
  <div>
    <nut-navbar
    :left-show="false"
      title="VIP Details"
      fixed=true
      class="mb0 nav-icon-right nut-navbar--fixed"
    >
    <template #left>
      <i class="nutui-iconfont nut-icon nut-icon-left" @click="backClick"></i>
    </template>
    </nut-navbar>

    <div class="pt50 vip-page">

      <div :class="'vip-page-top vip-icon-' + vipLevel">

        <div class="bd">
          <table>
            <tr>
              <th>Withdrawal Amount Limit Per Time</th>
              <th>Withdrawal Times Limit Per Day</th>
              <th>Total Withdrawal</th>
              <th>Witdraw Fee</th>
              <th>Sign In Extra Reward</th>
            </tr>
            <tr>
              <td>{{ myVipRules.withdrawSingleLimitAmount }}</td>
              <td>{{ myVipRules.withdrawDailyLimitCount }}</td>
              <td v-if="vipLevel === 0">0</td>
              <td v-else  style="font-size: 12px;">No Limit</td>
              <td>{{ (myVipRules.withdrawFeeRatio * 0.035).toFixed(1) }}%</td>
              <td>{{ myVipRules.extraDailySignRewardRatio }}%</td>
            </tr>
          </table>
        </div>
      </div>

      <div class="progress-lv">
        <div class="progress-tx"><span class="l">{{ rechargeAmount }}</span><span class="r">Lv{{vipLevel + 1}} ({{ requireRechargeAmount }})</span></div>
        <div class="progress-c">
          <nut-progress :percentage="progress" size="large" :stroke-color="bgc" />
        </div>
      </div>

      <table class="table-b">
        <tr>
          <th>Level</th>
          <th>Deposit</th>
          <th>Withdrawal Amount Limit Per Time</th>
          <th>Withdrawal Times Limit Per Day</th>
          <th>Total Withdrawal</th>
          <th>Witdraw Fee</th>
          <th>Sign In Extra Reward</th>
        </tr>
        <tr v-for="(item, index) in vipRules" :key="index" :class="index === vipLevel ? 'my-tr': ''">
          <td>{{ vipRules[index].level }}</td>
          <td>{{ vipRules[index].requireRechargeAmount }}</td>
          <td>{{ vipRules[index].withdrawSingleLimitAmount }}</td>
          <td>{{ vipRules[index].withdrawDailyLimitCount }}</td>
          <td v-if="index === 0 ">0</td>
          <td v-else>No Limit</td>
          <td>{{ (vipRules[index].withdrawFeeRatio * 0.035).toFixed(1)}}%</td>
          <td>{{ vipRules[index].extraDailySignRewardRatio }}%</td>
        </tr>
      </table>

    </div>

  </div>
</template>

<script>
import { reactive, onMounted, toRefs } from "vue";
import { useRouter } from "vue-router";
import { Toast } from "@nutui/nutui";
import axios from "axios";
export default {
  data() {
    return {
      myImgUrl: '',
    };
  },
  methods: {
    textToast(msg) {
      Toast.loading(msg, {
        id: "loading",
        duration: 0,
        bgColor: "rgba(0, 0, 0, 0.9)",
        cover: true,
      });
    },
  },
  created() {

  },
  setup() {
    const state = reactive({
      bgc: "#6a316c",
      progress: 0,
      rechargeAmount: 0,
      requireRechargeAmount: 0,
      vipLevel: null,
      vipRules: [],
      myVipRules: {
        withdrawSingleLimitAmount: 0,
        withdrawDailyLimitCount: 0,
        withdrawFeeRatio: 0,
        extraDailySignRewardRatio: 0,
      },
    });
    const router = useRouter();
    const backClick = () => {
      router.go(-1);
    };
    onMounted(() => {
      Toast.loading('Loading...', {
        id: "loading",
        duration: 0,
        bgColor: "rgba(0, 0, 0, 0.9)",
        cover: true,
      });
      axios
        .get("/api/user/vip-detail", {})
        .then(function (response) {
          state.rechargeAmount = response.data.data.rechargeAmount;
          state.vipLevel = response.data.data.vipLevel;
          state.vipRules = response.data.data.vipRules;
          state.requireRechargeAmount = state.vipRules[state.vipLevel + 1].requireRechargeAmount
          state.progress = (state.rechargeAmount / state.requireRechargeAmount) * 100
          state.myVipRules.withdrawSingleLimitAmount = state.vipRules[state.vipLevel].withdrawSingleLimitAmount
          state.myVipRules.withdrawDailyLimitCount = state.vipRules[state.vipLevel].withdrawDailyLimitCount
          state.myVipRules.withdrawFeeRatio = state.vipRules[state.vipLevel].withdrawFeeRatio
          state.myVipRules.extraDailySignRewardRatio = state.vipRules[state.vipLevel].extraDailySignRewardRatio
          
          Toast.hide("loading");
        })
        .catch(function (error) {
          console.log(error);
          Toast.hide("loading");
        })

    });
    return {
      ...toRefs(state),
      backClick,
    };
  }
};
</script>

<style scoped>
.progress-lv{ margin: 5px 10px;}


</style>
<style>
.progress-lv{ padding-bottom: 10px;}
.progress-tx .l{ color: #f2ff00;}
.progress-tx .r{ float: right; font-weight: 600; color: #66ff00;}
.progress-lv .nut-progress .nut-progress-text {color: #fff !important; line-height: 17px; font-weight: 600;}
.progress-lv .nut-progress .nut-progress-outer{ background: #444444 !important;}
</style>
