<template>

<nut-navbar @on-click-back="back" @on-click-title="title" title="Recharge" fixed=true class="mb0">
      <template #left>
        <div>返回</div>
      </template>
</nut-navbar>

<div class="login">
    <div class="login-bn">
       &nbsp;
    </div>
    <div class="login-bd">
        <h3>Recharge Complain</h3>
        <div class="login-inp">
            <p class="fs10 fc-999">Reason for complaint</p>
            <nut-textarea placeholder="Reason for complaint" type="text" />
        </div>
        <div class="login-inp">
            <p class="fs10 fc-999">Payment Screenshot</p>
            <nut-uploader url="http://服务地址" v-model:file-list="defaultFileList" maximum="3" multiple>
            </nut-uploader >
        </div>
        
        <div class="login-btn">
            <nut-button block type="info">Confirm</nut-button>
        </div>
    </div>
</div>
</template>

<script>
    import { reactive } from 'vue';
    export default {
        setup() {
            const state = reactive({
                val0: '初始数据',
                val1: '',
                defaultFileList: [
                    {
                    name: '文件1.png',
                    url: 'https://m.360buyimg.com/babel/jfs/t1/164410/22/25162/93384/616eac6cE6c711350/0cac53c1b82e1b05.gif',
                    status: 'success',
                    message: '上传成功',
                    type: 'image'
                    },
                    {
                    name: '文件2.png',
                    url: 'https://m.360buyimg.com/babel/jfs/t1/164410/22/25162/93384/616eac6cE6c711350/0cac53c1b82e1b05.gif',
                    status: 'uploading',
                    message: '上传中...',
                    type: 'image'
                    }
                ]

            });
            const methods = {
                back() {
                    alert('header');
                },
                title() {
                    alert('title');
                },
                right() {
                    alert('right');
                }
                
            };
            return {
                state,
                methods,
            };
        }
    }
</script>
