import axios from 'axios'
import {Dialog, Toast} from "@nutui/nutui";
import router from "@/router";
import {isNotBlank} from "@/assets/js/commonUtil";
import {isBlank} from "@/filters";
import {eventTrackingPay, eventTrackingWithdraw} from "@/assets/js/eventTracking";

//配置默认IP地址
//axios.defaults.baseURL='https://api.stesb.info/'

// 添加请求拦截器
axios.interceptors.request.use(config => {
    // 判断是否存在token,如果存在将每个页面header添加token
    const token = localStorage.getItem("token");
    if (isNotBlank(token)) {
        config.headers.token = token;
    }
    //设备id
    config.headers.di = localStorage.getItem("di");
    config.headers.common['Accept-Language'] = localStorage.getItem("ACCEPT_LANGUAGE")

    //游客token
    const vtoken = localStorage.getItem("vtoken");
    if (isNotBlank(vtoken)) {
        config.headers.vtoken = vtoken;
    }
    return config;
});

axios.interceptors.response.use(
    (response) => {
        //api message
        const apiMessage = response.data.apiMessage;
        if (isNotBlank(apiMessage)) {
            //站内信
            if (isNotBlank(apiMessage.systemLetter)) {
                apiMessage.systemLetter.forEach(letter => {
                    Dialog({
                        title: letter.title,
                        content: letter.content,
                        okText: 'OK',
                        noCancelBtn: true
                    })
                });
            }
            //充值成功
            if (isNotBlank(apiMessage.rechargeSuccess)) {
                apiMessage.rechargeSuccess.forEach(recharge => {
                    eventTrackingPay(recharge.inviteCode, recharge.inviterId, recharge.rechargeAmount, recharge.firstTime);
                    if(recharge.firstTime === true){
                        let Download_APP_TEXT = 'Download APP'
                        let INFO_TEXT = 'For a better game experience, get rewards faster and get valuable information'
                        let Download_BTN_TEXT = 'Download'
                        let Cancel_BTN_TEXT = 'Cancel'
                        Dialog({
                            title: Download_APP_TEXT,
                            content: INFO_TEXT,
                            okText: Download_BTN_TEXT,
                            cancelText: Cancel_BTN_TEXT,
                            onOk: () => {
                                let c = JSON.parse(window.localStorage.getItem("commonInfo"));
                                let u = c.appDownloadUrl;
                                window.open(u);
                            }
                        });
                    }
                });
            }
            //提现成功
            if (isNotBlank(apiMessage.withdrawSuccess)) {
                apiMessage.withdrawSuccess.forEach(withdraw => {
                    eventTrackingWithdraw(withdraw.inviteCode, withdraw.inviterId, withdraw.withdrawAmount);
                });
            }
        }

        const apiCode = response.data.code;
        if (apiCode === 0) {
            return response;
        }
        switch (apiCode) {
            case 1001:
                router.push('/userRegister');
                break;
            case 1002:
            case 1003:
                //BAD_REQUEST
                //UNKNOWN_ENUM
                // Toast.fail(this.$t('global.REQUEST_FAILED_PLEASE_TRY_LATER_OR_CONTACT_US'));
                Toast.fail('Occur an error.');
                break;
            case 1004:
                //Toast.fail(this.$t('global.INVALID_INPUT_VALUE'));
                break;
            case 2000:
                if (isBlank(response.data.bizCode)) {
                    Dialog({
                        content: response.data.msg,
                        okText: 'OK',
                        noCancelBtn: true
                    });
                } else if (response.data.bizCode === 'FUND_ACCOUNT_AMOUNT_NOT_ENOUGH') {
                    let recharge_txt = 'Deposit'
                    Dialog({
                        content: response.data.msg,
                        okText: recharge_txt,
                        cancelText: 'OK',
                        onOk: () => {
                            router.push({name: "rechargeBalance"})
                        }
                    });
                }
                //bizCode不为空，则需要前端特殊处理。即不需要弹错误提示
                break;
            case 9999:
                // Toast.fail(this.$t('global.SERVER_ERROR'));
                Toast.fail('Server error. Please try later.');
                break;
        }
        return response;
    },
    (error) => {
        if (error.message.includes('timeout')) {
            Toast.fail('Network timeout, please check your network.');
        } else {
            Toast.fail('Occur an error, please try later.');
        }
        return Promise.reject(error);
    });
//export default http
