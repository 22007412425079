<template>
  <div>
    <p class="gg-info">{{ $t('global.slogan1') }}</p>
    <nut-navbar
      :left-show="false"
      @on-click-icon="iconClick"
      :title="$t('receiveBonus.h1')"
      titIcon="home"
      fixed=true
      class="mb0 nav-icon-right nut-navbar--fixed"
    >
    </nut-navbar>
    <div class="login hjbg pt50">
      <div class="tl">
        
        <p>{{$t('global.Received')}} {{ totalAmount - remainingAmount }} / {{$t('global.Total')}} {{ totalAmount }}</p>
        <h3>{{$t('global.Lucky_Lifafa')}}</h3>
        <h4>{{$t('global.Easy_share_Earn_Rupees')}}</h4>
        <h5>{{$t('global.Remaining_today')}} <strong>{{ remainingCount }}</strong> {{$t('global.pieces')}}</h5>
      </div>

      <div class="lwbg"></div>
    <div class="bonus-amount">
        <div class="bd">
          <nut-noticebar 
              direction='vertical' 
              :list="receiveList" 
              :speed='10' 
              :standTime='2000' 
              :closeMode="true" 
              :color="`#ff7e00`">
          </nut-noticebar>
          <p v-if="!sharerMobile">
            <span>{{ errMsg }}</span>
          </p>
          <h3>
            <strong v-if="amount != '?'">{{ $filters.currencySymbol(amount) }}</strong>
            <p v-else>{{$t('receiveBonus.errText')}}</p>
          </h3>
          <nut-button
              v-if="showToLobby"
              size="large"
              type="primary"
              class="regbtn"
              @click="toLobby"
          >{{$t('global.Play_now')}}</nut-button>

          <nut-button
            v-if="!btnDisabled && amount !== '?'"
            size="large"
            type="primary"
            class="regbtn"
            @click="receiveClick"
            :disabled="btnDisabled || amount === '?'"
            >{{$t('receiveBonus.btn')}}</nut-button>

          <!-- <nut-button
            v-else
            size="large"
            type="primary"
            class="regbtn"
            @click="telegramClick"
            >{{$t('receiveBonus.btn2')}}</nut-button>
          <div class="lk"  @click="popRule = true">Rule</div>  -->

        </div>
    </div>

    </div>
    
    <nut-popup pop-class="pop-demo" v-model:visible="vipDialog">
        <div class="hd"><img style="width: 40px;" src="../assets/images/global_icon/202405-34.png"></div>
        <div class="bd">
          {{ vipDialogText }}<br/>
        </div>
        <div class="btn" style="margin: 0;">
          <nut-button style="width: 48%;margin: 0 1%;" type="primary" @click="rechargeBtnClick">{{ $t('home.recharge') }}</nut-button>
          <nut-button style="width: 48%;margin: 0 1%;" type="primary" @click="iconClick">{{ $t('withdrawal.btntx') }}</nut-button>
        </div>
      </nut-popup>

  </div>

      <!--**************************************** 提醒 ****************************************-->
      <!-- <nut-popup pop-class="pop-bonus-rule" v-model:visible="popRule">
        <div class="bd"></div>
        <div class="btn">
          <nut-button type="primary" @click="popRule = false">{{ $t('game.gotitBtn') }}</nut-button>
        </div>
      </nut-popup> -->

</template>

<script>
import { reactive } from "vue";
import { useRouter } from "vue-router";
import { Toast } from "@nutui/nutui";
import axios from "axios";
import { isNotBlank } from "@/assets/js/commonUtil";
export default {
  data() {
    return {
      commonInfo:'',
      country:'',
      s:'',
      param: [],
      shareCode: "",
      shareBonusReceiveCode: "",
      amount: "?",
      totalAmount: 0,
      totalCount: 0,
      remainingCount: 0,
      remainingAmount: 0,
      sharerMobile: "",
      receiveList: [],
      receiveListData: [],
      errMsg: "",
      btnDisabled: false,
      showToLobby: false,
      vipDialog: false,
      vipDialogText: '',
      popRule: false,
    };
  },

  methods: {
    telegramClick(){
      window.open(this.commonInfo.telegramChannelUrl,"_self");
    },
    toLobby(){
      this.$router.push({path: "lobby"});
    },
    rechargeBtnClick() {
        if(this.commonInfo.firstRechargeAvailable){
          this.$router.push({path: '/firstRechargeBonus'});
        }else{
          this.$router.push({path: '/rechargeBalance'});
        }


    },
    receiveClick() {
      axios
        .post("/api/user/share-bonus/receive", {
          shareBonusCode: this.shareCode,
        })
        .then((res) => {
          if (res.data.code === 0) {
            Toast.success(this.$t('receiveBonus.success'));
            this.shareBonusReceiveCode = res.data.data.shareBonusReceiveCode;
            this.amount = res.data.data.shareBonusAmount
            localStorage.setItem("shareBonusReceiveCode",this.shareBonusReceiveCode);
            localStorage.setItem("shareBonusAmount", this.amount);
            setTimeout(() => {
              this.$router.push("/shareBonus?s="+this.shareBonusReceiveCode);
            }, 1000);
          }else if(res.data.bizCode === 'USER_SHARE_BONUS_NEED_VIP'){
            this.vipDialogText = res.data.msg
            this.vipDialog = true
          }
        })
        .catch(function (error) {
          console.log(error);
          Toast.fail(this.$t('global.networkError'));
        });
    },
  },
  mounted() {
    this.commonInfo = JSON.parse(window.localStorage.getItem("commonInfo"));
    this.s = document.querySelector('body').getAttribute('s');
    // this.popRule = true
    if (window.location.href.split("?")[1]) {
      let url = window.location.href.split("?")[1];
      let arr = url.split("&");
      for (var i = 0; i < arr.length; i++) {
        this.param[arr[i].split("=")[0]] = arr[i].split("=")[1];
      }
      if (this.param.c) {
        localStorage.setItem("shareBonusCode", this.param.c);
      }
    }
    if (isNotBlank(localStorage.getItem("shareBonusCode"))) {
      this.shareCode = localStorage.getItem("shareBonusCode");
      axios({
        url: "/api/user/share-bonus/check",
        method: "get",
        params: { shareCode: this.shareCode },
      }).then((res) => {
        if (res.data.code === 0) {
          this.amount = res.data.data.amount;
          this.totalCount = res.data.data.totalCount * 2
          this.totalAmount = this.totalCount * this.amount
          this.remainingCount = this.totalCount - res.data.data.totalCount - res.data.data.receivedCount
          this.remainingAmount = this.remainingCount * this.amount
          localStorage.setItem("shareBonusAmount", this.amount)
          localStorage.setItem("needVip", res.data.data.needVip)
          
          this.sharerMobile = res.data.data.sharerMobile
            ? res.data.data.sharerMobile
            : this.$t('receiveBonus.name');
          for (var inx in res.data.data.receiveList) {
            let text = this.$t('receiveBonus.user') + ' ' + res.data.data.receiveList[inx].mobile + ' ' + this.$t('receiveBonus.receives')
            this.receiveListData.push(text.toString())
          }
          this.receiveList = this.receiveListData
          if (isNotBlank(res.data.data.shareBonusReceiveCode)) {
            this.shareBonusReceiveCode = res.data.data.shareBonusReceiveCode
            
            this.$router.push("/shareBonus?s="+this.shareBonusReceiveCode);
          }
        } else if (isNotBlank(res.data.bizCode)) {
          //特殊的业务code，已领完，已过期或红包code非法等
          this.errMsg = res.data.msg;
          this.btnDisabled = true;
          //清除本地存储
          localStorage.removeItem("shareBonusCode");
          //展示去大厅按钮
          this.showToLobby = true;
        } else {
          this.errMsg = res.data.msg;
          this.btnDisabled = true;
          //展示去大厅按钮
          this.showToLobby = true;
        }
      });
    }
  },
  setup() {
    const state = reactive({});
    const router = useRouter();
    const methods = {
      iconClick() {
        router.push({ path: '/lobby'});
      }
    };

    return {
      router,
      state,
      ...methods,
    };
  },
};
</script>

<style scoped>
.bonus-amount {
  position: absolute;
  bottom: 10px;
  left: 0;
  right: 0;
  width: 100%;
  height: 280px;
  overflow: hidden;
  background: url(../assets/images/global/bg57.png) center center no-repeat;
  background-size: auto 300px;
  padding-top: 20px;
}
.lwbg{
  position: absolute;
  bottom: 288px;
  left: 0;
  right: 0;
  width: 100%;
  height: 100px;
  overflow: hidden;
  background: url(../assets/images/global/bg57e.png) center 0 no-repeat;
  background-size: auto 90px;
}
.bonus-amount .bd {
  margin-left: auto;
  margin-right: auto;
  width: 380px;
  height: 320px;
}
.bonus-amount p {
  padding: 0;
  margin: 25px 0 15px 0;
  height: 30px;
  line-height: 30px;
  text-align: center;
  font-size: 20px;
  color: #fff;
  font-weight: 300;
}
.bonus-amount .lk{ line-height: 30px; color: #fff;text-decoration:underline;    text-align: center;}
.bonus-amount h3 {
  padding: 0;
  margin: 0 0 15px 0;
  height: 100px;
  text-align: center;
  font-size: 80px;
  color: #ffe30c;
  font-weight: 500;
}
.bonus-amount h3 p{
  margin: 15px 40px;
} 

.bonus-amount .inp {
  background: #fcf5d7;
  width: 305px;
  height: 40px;
  font-size: 20px;
  border: 0;
  margin: 8px 25px 12px 25px;
  border-radius: 5px !important;
  padding: 0 10px;
}
.regbtn {
  background: #00EDA6 !important;
  color: #000;
  font-size: 20px;
  width: 310px;
  border-radius: 25px !important;
  margin: 0 35px 10px 35px;
  border-bottom: #00b67f 5px solid;
}

.login-bd {
  padding: 20px;
  background: #fff;
  margin: 210px 20px 20px 20px;
  box-shadow: 0px 0px 5px #d9d2f4;
  border-radius: 10px;
}
.otpbtn {
  float: right;
}
.hjbg .tl p{ background: #fff url(../assets/images/global/bg27.png) 5px center no-repeat;background-size: auto 30px;; border-radius:24px; height: 36px; line-height: 36px; color: #fe493f; text-align: center;
  margin: 0px 10px 10px 10px; border: 3px solid #440b5d;}
.hjbg .tl h3{ text-align: center; color: #ffe30c; font-size: 34px; margin: 20px 0 10px 0; line-height: 30px;}
.hjbg .tl h4{ text-align: center; color: #fff; font-size: 18px;margin: 0 0 20px 0; line-height: 30px; opacity: .8;}
.hjbg .tl h5{ text-align: center; color: #fff; font-size: 16px;margin: 0; line-height: 40px; height: 40px; font-weight: 300;
  background: url(../assets/images/global/bg57d.png) center center no-repeat;background-size: auto 100%;}
.hjbg .tl h5 strong{ color: #ffe30c;}

.login.hjbg {min-height:600px; position: relative; background:url(../assets/images/global/bg54.jpg) 0 bottom no-repeat !important; background-size: 100% auto !important;}


</style>

<style>
.nut-noticebar-vertical {
  height: 40px !important;
  margin: 0 30px;
  background: none !important;
  opacity: 0.8;
}
.nut-noticebar-vertical .horseLamp_list .horseLamp_list_item {
  height: 30px !important; overflow: hidden;
  color: #000;
}
.bonus-amount .nut-noticebar-vertical .horseLamp_list .horseLamp_list_item{ color: #fff !important;}
.bonus-amount .horseLamp_list li{ color: #fff !important;}

.pop-bonus-rule{ width: 285px; height: 435px; border-radius:10px; padding: 10px 20px 0 20px;
background: url(../assets/images/global/bg57f.jpg) center center no-repeat; background-size: auto 100%;}
.pop-bonus-rule .bd{ height: 365px;}
.pop-bonus-rule .btn {text-align: center;padding: 20px 20px 0 20px;margin: 0 20px;}
.pop-bonus-rule .btn .nut-button--round{ width: 100%; margin-bottom: 10px;}
.pop-bonus-rule .btn .link{ color: #3d2505;}
</style>
