<template>
  <div class="play-rule">
    <h4><nut-icon name="tips" style="vertical-align: middle;margin-right: 5px;margin-bottom: 4px;"></nut-icon>{{$t('WHEEL.RULE_H1')}}</h4>
    <dl>
      <dt>{{$t('WHEEL.RULE_T1')}}</dt>
      <dd>{{$t('WHEEL.RULE_T2')}}</dd>

      <dt>{{$t('WHEEL.RULE_T3')}}</dt>
      <dd>{{$t('WHEEL.RULE_T4')}}</dd>
      <dd>{{$t('WHEEL.RULE_T5')}}</dd>
    </dl>

  </div> 
</template>

<script>
import { getCountry } from "@/assets/js/environment";

export default {
  name: 'GameRulesWheel',
  props: {},
  data() {
    return {
      country: '',
    };
  },
  methods: {
  },
  created() {
    this.country = getCountry()
  },
  setup() {}
};
</script>

<style scoped>

.play-rule {
  padding: 10px;
}

.play-rule h4 {
  margin: 5px 0;
  font-size: 17px;
}
.nut-popup h4.h4 {
  margin: 20px 10px;
}
.play-rule dl {
  position: absolute;
  overflow-y: scroll;
  top: 50px;
  bottom: 20px; right: 10px; left: 10px;
}

.play-rule dt {
  margin: 20px 0; padding-left: 10px;  line-height: 22px;
  font-size: 14px; font-weight: 400; border-left:4px solid #3d2505 ;
}

.play-rule dd {
  margin:0px;
  line-height: 20px;
  padding-left: 20px;
}
.play-rule dd.img {
  margin:0px;
}
.play-rule dd img{
  width: 100%;
}
.play-rule dd strong{ color: #3d2505;}
.play-rule .nut-button{ margin-right: 20px; width: auto !important;}
</style>
