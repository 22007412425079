<template>
  <div class="play-rule">
    <h4><nut-icon name="tips" style="vertical-align: middle;margin-right: 5px;margin-bottom: 4px;"></nut-icon>{{$t('gameRulesAndar.h1')}}</h4>
    <dl>
      <dt>{{$t('gameRulesAndar.dt1')}}</dt>
      <dd>{{$t('gameRulesAndar.dd1')}}</dd>

      <dt>{{$t('gameRulesAndar.dt2')}}</dt>
      <dd>{{$t('gameRulesAndar.dd21')}}</dd>
      <dd>{{$t('gameRulesAndar.dd22')}}</dd>
      <dd>{{$t('gameRulesAndar.dd23')}}</dd>
      <dd>{{$t('gameRulesAndar.dd24')}}</dd>
      <dd>{{$t('gameRulesAndar.dd25')}}</dd>

      <dt>{{$t('gameRulesAndar.dt3')}}</dt>
      <dd>{{$t('gameRulesAndar.dd31')}}</dd>
      <dd>&nbsp;</dd>
      <dd>{{$t('gameRulesAndar.dd33')}}</dd>
      <dd>{{$t('gameRulesAndar.dd34')}}</dd>
      <dd>&nbsp;</dd>
      <dd>{{$t('gameRulesAndar.dd35')}}</dd>
      <dd>&nbsp;</dd>
      <dd>{{$t('gameRulesAndar.dd36')}}</dd>
      <dd>{{$t('gameRulesAndar.dd37')}}</dd>
    </dl>

  </div> 
</template>

<script>
import { getCountry } from "@/assets/js/environment";

export default {
  name: 'GameRulesMine',
  props: {},
  data() {
    return {
      imgUrl: '',
      country: '',
    };
  },
  methods: {
  },
  created() {
    this.imgUrl = localStorage.getItem('countryImgUrl')
    this.country = getCountry()
  },
  setup() {}
};
</script>


