<template>
  <div class="first-recharge" >
    <div class="back-btn" @click="backClick"></div>
    <div class="FR-top">
      <h2>{{$t('global.DISCOUNT_COUPON')}}</h2>
      <h4>{{$t('global.VALID')}} {{commonInfo.firstRechargeDuration / 60}}H</h4>
    </div>
    <div class="FR-body">
      <div class="q-ls">
        <div class="q" >
          <div class="l"><i>{{ $filters.currencySymbol(' ') }}</i>{{ commonInfo.firstRechargeGift }}</div>
          <div class="r">
            <strong>{{ per }}%</strong><span>{{$t('global.COUPON')}}</span>
            <div v-if="commonInfo.firstRechargeAvailable" class="btn" @click="showBottom = true">{{$t('global.USAR')}}</div>
            <div v-else class="btn btn-used" >{{$t('global.EXPIRED')}}</div>
          </div>
        </div>
        <div class="t">
          <nut-countdown :endTime="firstRechargeDeadline" millisecond format="HH:mm:ss"/>
        </div>
      </div>


    </div>

  </div>
  <nut-popup position="bottom"  closeable round :style="{ height: '420px' }" v-model:visible="showBottom" pop-class="fr-popup">
    <div class="fm">
      <div class="label">
        <span>{{ $t('global.RECIBO') }}:</span>
        <strong style="font-size:20px">{{ $filters.currencySymbol(commonInfo.firstRechargeAmount + commonInfo.firstRechargeGift) }}</strong>
      </div>
      <div class="label">
        <span>{{$t('global.COUPON')}}:</span>
        <strong class="q" style="font-size:20px">{{ $filters.currencySymbol(commonInfo.firstRechargeGift) }}</strong><i>-{{ per }}%</i>
      </div>
      <div class="label">
        <span>{{ $t('global.PAY') }}:</span>
        <strong style="color: #53a100;">{{ $filters.currencySymbol(commonInfo.firstRechargeAmount) }}</strong>
      </div>

      <div class="label">
        <span>{{ $t('recharge.channel') }}:</span>
          <nut-radiogroup direction="horizontal" v-model="radio" class="radio-list">
            <nut-radio
              :id= "index === 0 ? 'radioaActive' : ''"
              :label="item.id"
              v-for="(item, index) in channelList.channelRoutes"
              :key="index"
              v-bind:c-type="item.type"
              @click="channelType(item.id)"
              :checked="{ checked: index === 1 }"
            >
              {{ item.typeName }}
            </nut-radio>
          </nut-radiogroup>
      </div>

      <div class="login-btn m10">
        <nut-button block type="info" @click="rechargeApply" :disabled="rechargeing">
          <nut-icon v-if="rechargeing" name="loading" size="25" style="margin-top: 15px;"></nut-icon>
          <span v-else>{{$t('global.recharge')}}</span>
        </nut-button>
      </div>
    </div>
  </nut-popup>

  <nut-popup pop-class="popclass pop-h200" closeable round close-on-click-overlay='false' :style="{ padding: '20px 40px',width:'70%' }" 
    v-model:visible="showPopup" :z-index="100" @close="popClose">
    <p>{{$t('recharge.pop')}}</p>
    <div>
      <span>Secure</span>
      <span>Fast</span>
    </div>
  </nut-popup>

  <tabbar :activeIndex="tabbarActiveIndex"></tabbar>
</template>

<script>
import TabbarMain from "../components/TabbarMain.vue";
import axios from "axios";
import { getCountry, getLastTimeGameName } from "@/assets/js/environment";
import { useRouter } from "vue-router";
import { Toast } from '@nutui/nutui';
export default {
  name: "firstRechargeBonus",
  components: {
    tabbar: TabbarMain,
  },
  data() {
    return {
      country: '',
      commonInfo: '',
      imgUrl: '',
      per: 0,
      firstRechargeDeadline: null,
      showBottom: false,
      channelList: [],
      radio: 0,
      channelRouteId: 0,
      rechargeing: false,
      purpose: null,
      showPopup: false,
      tabbarActiveIndex: -1,
    };
  },
  beforeCreate(){
    // if(!localStorage.getItem('reloadCoupon') && location.href.indexOf('#R') == -1){
    //   localStorage.setItem("reloadCoupon", true);
    //   location.href = location.href + '#R'
    //   location.reload()
    // }
  },
  created() {
    this.country = getCountry()
    this.commonInfo = JSON.parse(localStorage.getItem("commonInfo"))
    this.imgUrl = localStorage.getItem('countryImgUrl')
    this.purpose = getLastTimeGameName()
    /* 首充 */
    const registerTime = new Date(this.commonInfo.registerTime).getTime()
    this.firstRechargeDeadline = Date.now() + ( (registerTime + (3600 * 1000 * (this.commonInfo.firstRechargeDuration / 60))) - new Date().getTime());
    this.per = parseInt(this.commonInfo.firstRechargeGift / (this.commonInfo.firstRechargeGift + this.commonInfo.firstRechargeAmount) * 100)
  },
  mounted() {
    axios({
      url: "/api/fund/recharge/prepare",
      method: "get",
      params: { rechargeAmount: this.commonInfo.firstRechargeAmount },
    }).then((res) => {
      this.channelList = res.data.data;
      console.log(this.channelList)
    });
  },
  methods: {
    channelType(id){
      this.channelRouteId = id
    },
    rechargeApply() {
      if (this.channelRouteId === 0) {
        Toast.fail(this.$t('recharge.failSelect'));
        return;
      }
      this.rechargeing = true;
      axios
      .post("/api/fund/recharge/apply", {
        applyAmount: this.commonInfo.firstRechargeAmount,
        channelRouteId: this.channelRouteId,
        purpose: this.purpose,
      })
      .then((response) => {
        if (response.data.code === 0) {
          if(response.data.data.type === "redirect"){
            this.showPopup = true
            window.open(response.data.data.redirectUrl,"_self");
          }
          this.rechargeing = false;
        } else {
          if (response.data.msg != undefined) {
            Toast.fail(response.data.msg);
          } else {
            Toast.fail("error!");
          }
        }
      })
      .catch(function (error) {
        this.rechargeing = false;
        console.log(error);
        //Toast.fail(_data.data.i18n.networkError);
      });
    },
  },
  setup() {
    const router = useRouter();
    const methods = {
      toGameHome(n){
        let gameName = n + 'Demo'
        router.push({name: gameName});
      },
      backClick() {
        router.push({name: 'gameLobby'});
      },
    };
    return {
      ...methods,
    };
  },
};
</script>

<style scoped>
.first-recharge .back-btn{ width: 35px; height: 35px;  position: absolute; top:10px; left: 10px;
 background: url(../assets/images/global/bg311.png) center center no-repeat; background-size:35px auto;;}
.first-recharge{ max-width: 600px;min-width: 360px; min-height: 600px; margin: 0 auto; position: relative; 
  background:#ffc454 url(../assets/images/global/bg304.jpg) center 0px no-repeat; background-size:100% auto;}
.first-recharge .FR-top{ text-align: center; max-width: 340px; margin: 0px auto; padding-top: 30px; color: #fff; height: 180px;}
.first-recharge .FR-top h2{ font-size: 55px; line-height: 52px; font-weight: 600; color: #fff; margin: 20px 0 0 0;letter-spacing:2px;
  text-shadow: 0 3px #ec7800, 3px 0  #ec7800, -3px 0  #ec7800, 0 -3px #ec7800;}
.first-recharge .FR-top h4{font-size: 18px;line-height: 40px; margin: 0; height: 40px; color: #a13d01;
  background: url(../assets/images/global/bg309.png) center center no-repeat; background-size:auto 40px; opacity: .8; }
.first-recharge .FR-body{ padding: 30px 10px 90px 10px;}
.first-recharge .FR-body .q-ls{ height: 185px; padding-top: 190px;
    background: url(../assets/images/global/bg305.png) center top no-repeat; background-size:auto 100%;}
.first-recharge .FR-body .q-ls .q{  background: #fff url(../assets/images/global/bg310.png) 130px center no-repeat; background-size:auto 105px;
   height: 105px; margin: 0 40px; border-radius:10px; overflow: hidden;margin-bottom: 35px;
   box-shadow: 1px 3px 6px #280339  !important;}
.first-recharge .FR-body .q-ls .q .l{ float: left;
    line-height: 90px; font-size: 52px; color: #a95800; font-weight: 600; width: 130px; text-align: center;}
.first-recharge .FR-body .q-ls .q .l i{ font-size: 22px; vertical-align: super;}
.first-recharge .FR-body .q-ls .q .r{ float: right; width: 120px; color: #000; padding-top: 10px; line-height: 30px;}
.first-recharge .FR-body .q-ls .q .r strong{ font-size:18px; margin-right: 5px; }
.first-recharge .FR-body .q-ls .q .r .btn{ width: 100px; background: #f9ecb9; text-align: center; padding: 5px 0;line-height: 30px; height: 30px;}
.first-recharge .FR-body .q-ls .q .r .btn-used{background: #eee; color: #ccc;}
.first-recharge .FR-body .q-ls .t{ color: #fff; text-align: center; line-height: 30px; font-size: 30px;}
.first-recharge .FR-body .q-ls .t .nut-countdown{color: #fff; text-align: center; line-height: 30px; font-size: 30px; width: 100%; display: block;}
.first-recharge .FR-body .q-ls .t .nut-countdown .nut-cd-block{ width: 100%;}
.first-recharge .link-3x{ display: flex; align-items: center; flex-wrap: wrap; margin: 0px;}
.first-recharge .link-3x .link-i{ text-align: center;width: 46%; margin: 5px 2%; border-radius: 10px;
    background:#da9a21;}
.first-recharge .link-3x .link-i img{ width: 70%;}
.first-recharge .FR-body h4{ text-align: center; margin: 30px 0 0 0; font-size: 20px; color: #965600;}
.fr-popup .fm{ padding: 30px 10px 0 25px;}
.fr-popup .fm .radio-list{ height: 30px; line-height: 30px; padding-top: 10px;}
.fr-popup .fm .login-btn {position: absolute;bottom: 60px;width: 80%;}
.fr-popup .label{  font-size: 16px; vertical-align: middle; min-height: 50px;line-height: 50px;}
.fr-popup .label span{vertical-align: text-bottom; float: left; width: 100px;height: 50px; line-height: 50px;}
.fr-popup .label del{font-size: 24px; color: #999;margin-left: 20px;}
.fr-popup .label strong{ font-size: 30px;}
.fr-popup .label strong.q{ background: #eee url(../assets/images/global/yes.png)  10px center no-repeat; background-size:30px auto; 
  padding: 0 50px; border: 2px dashed #afafaf; color: #c43118; border-radius:12px 0 0 0;}
.fr-popup .label i{ color: #c43118; margin-left: 20px;}
.fr-popup .label .nut-radiogroup { display: block; width: 200px; margin-left: 100px;}
</style>
<style>

</style>
