<template>
  <div class="pt40">
    <!-- <nut-navbar
        @on-click-icon="askClick"
        fixed=true
        :left-show="false"
        titIcon="ask"
        class="mb0 nav-icon-right nut-navbar--fixed"
        :title="$t('game.h1')"
    >
    </nut-navbar> -->


    <div class="CH-hd">
      <div class="c">
        <span class="title">{{ $t('game.h1') }}</span>
        <span class="l" v-if="player.availableAmount !== null" >{{ $filters.currencySymbol(player.availableAmount) }}</span>
        <span class="r" @click="toMyPlayList()"><i> </i></span>
        <span class="r2" @click="toGameLobby()"><i> </i></span>
      </div>
    </div>



    <nut-overlay v-model:visible="overlayShow" :close-on-click-overlay="false" :z-index="2000" :overlay-class="overlayClass">
    </nut-overlay>

    <div class="games-main">

      <div class="g-vm">
        
        <div class="rule-btn" @click="askClick"></div>

        <!-- 历史 -->
        <div class="top">
          <span v-for="(history,index) in game.gameHistoryList" :class="getGameHistoryClass(history,index)" :key="index"
                @click="gameHistoryClick">
            {{ history.r }}X
          </span>
          <nut-icon name="more-s" size="20" color="#6b4c80" @click="gameHistoryClick"></nut-icon>
        </div>


        <div class="canvas-main">
          <!-- 画板 -->
          <canvas id="canvas" width="350" height="350" style="height:350px !important; overflow: hidden;"></canvas>
          <img :src="draw.planeImg" id="canvas-img1" ref='plane' width="0"/>
          <img :src="require('../assets/images/game_crash/fjbg.jpg')" id="canvas-img2" ref='background' width="0" style="top:-3680px"/>
          <img :src="require('../assets/images/game_crash/fjbg2.jpg')" id="canvas-img5" ref='background2' width="0"/>
          <!-- 倍率 -->
          <div class="rocket-data" v-show="game.on">
            <strong>{{ game.currentRatio }}</strong>
            <span style="font-size:30px;">X</span>
            <br>
            <span v-if="this.player.betAmount2 !== null" class="c-y c-yy" >{{
                $filters.currencySymbol(player.currentWinAmount)
              }}</span>
          </div>
        </div>
        <!-- 本局id -->

        <div class="bottom-center" v-show="game.on === true || game.stage === stageEnum.end">
          <span  v-if="this.player.myWinAmount !== null && this.player.myWinAmount !== 0" class="c-y">
            {{$t('global.myWin')}}: {{ $filters.currencySymbol(player.myWinAmount) }}
            <span style="margin-left: 5px; padding-left: 5px;">{{ player.escapeRatio }}X</span>
          </span>
        </div>

        <div class="bottom-left">
          <span v-show="game.escapedPlayerCount > 0">{{ $t('game.stopPlayer') }}: {{ game.escapedPlayerCount }}</span>
        </div>

        <div class="top-right">
          <span v-if="parseInt(game.latency) <= 150" class="c-g">&nbsp;</span>
          <span v-if="parseInt(game.latency) > 150 && parseInt(game.latency) <= 300 " class="c-o">&nbsp;</span>
          <span v-if="parseInt(game.latency) > 300" class="c-r">&nbsp;</span>
        </div>



        <!-- 暂停状态 -->
        <div class="state1-preparing typebg0" v-show="game.pause">
          <h3>{{ $t('game.gamePaused') }}</h3>
          <p></p>
        </div>

        <!-- 开始倒计时状态 -->
        <div class="state1-preparing typebg1" v-show="game.stage===stageEnum.bet">
          <h3>{{ $t('game.playTime') }}</h3>
          <p>{{ $t('game.gameWillStartingIn') }}
            <br/>
            <nut-countdown :endTime="game.betDeadline" millisecond format="ss:SS" style="font-size:30px;"/>
          </p>
        </div>

        <!-- 结算状态 -->
        <div class="state1-preparing" :class="player.playStatus === playerStatusEnum.escape ? 'typebg2-my' : 'typebg2'"  v-show="game.stage===stageEnum.end">

          <div class="top-user-new">
            <h5>TOP 5</h5>
            <ul>
              <li v-for="(player,i) in game.lastGameTopList" :key="i" v-show="i < 5">
                <i class="img">
                  <img :src="require('../assets/images/avatar/' + player.a.slice(player.a.length-6))"/>
                </i>
                <strong>
                  <span class="g">{{ $filters.currencySymbol(player.ba) }}</span>
                  <span class="x"> X{{ player.er }}</span>
                  <span class="r">Win: {{ $filters.currencySymbol(player.wa) }}</span>
                </strong>
              </li>
            </ul>

            <div v-show="game.lastGameTopList.length === 0" style="height:60px"></div>

            <p v-if="player.playStatus === playerStatusEnum.escape" style="margin-top:8px; font-size: 14px;">{{ $t('game.youWin') }} :
              <strong>{{ $filters.currencySymbol(player.escapeAmount) }}</strong>
            </p>
            <p v-else style="margin-top:8px;font-size: 14px;" >{{ game.escapedPlayerCount }} Players {{ $t('game.receiveBonus') }}:
              <strong>{{ $filters.currencySymbol(game.lastGameWinAmount) }}</strong>
            </p>


          </div>


        </div>

        <!-- 遮罩 -->
        <div class="state1-preparing typebg9"></div>

      </div>
      <!-- WIN玩家飘屏 -->
      <nut-noticebar
          direction="vertical"
          :list="game.lastGameBigWinners"
          :speed="20"
          :standTime="1000"
          :height="30"
          :closeMode="true"
          :background="`rgba(251, 248, 220, 1)`"
          :color="`#D9500B`"
          right-icon="circle-close"
      ></nut-noticebar>

      <!-- 下注面板 -->
      <div class="g-operate">
        <div class="g-amount">
          <div class="inpbox">
            <input type="text" v-model="player.betAmount" @change="betAmountMax(player.betAmount)"/>
            <button class="btn-bet btn-sub" @click="btnSubtract">-</button>
            <button class="btn-bet btn-plus" @click="btnAddition">+</button>
          </div>
          <div class="btnbox">
            <nut-button v-for="recommendBetAmount in game.recommendBetAmounts" :key="recommendBetAmount"
                  size="mini" type="primary" @click="player.betAmount=recommendBetAmount"
                  :disabled="(recommendBetAmount >= game.betAmountRange.min && recommendBetAmount <= game.betAmountRange.max) ? false : true">
              {{ recommendBetAmount }}
            </nut-button>
            <nut-button size="mini" type="primary" @click="betAmountMax(player.availableAmount)"> {{ $t('game.all') }}
            </nut-button>
          </div>
        </div>
        <div class="g-amount-r">
          <h4>
            <span v-if="player.availableAmount !== null"
                  class="tx"><span>{{ player.availableAmount }}</span></span>
          </h4>
          <nut-button v-if="game.stage===stageEnum.bet && player.playStatus===playerStatusEnum.notBet"
            @click="bet" block type="info" class="btn-bg-g" :disabled="betDisabled">
            <span v-if="!betDisabled">{{ $t('game.start') }}</span><nut-icon v-else name="loading" size="30" ></nut-icon>
          </nut-button>

          <nut-button v-else-if="game.stage===stageEnum.flying && player.playStatus===playerStatusEnum.bet"
            @click="escape(false)" block type="danger" class="btn-bg-o" :disabled="escapeDisabled">
            <span v-if="!escapeDisabled">{{ $t('game.stop') }}</span><nut-icon v-else name="loading" size="30" ></nut-icon>
          </nut-button>

          <nut-button v-else block type="danger" class="btn-bg-d" @click="nextRound">{{ $t('game.start') }}<span>{{$t('game.nextGame')}}</span>
          </nut-button>
        </div>

        <h4 class="mt20">
          <span class="mr20">{{ $t('game.autoStop') }}</span>
          <nut-inputnumber
              v-model="player.autoEscapeRatio"
              step="0.10"
              decimal-places="1"
              min="1.1"
              max="100.00"
              @change="updateAutoEscape"
              :disabled="!player.autoEscape"

          />
          <span class="r"><nut-switch @change="updateAutoEscape" v-model="player.autoEscape"/></span>

          <span class="r r2" v-if="player.betAmount2 > 0">{{ $t('game.myBet') }} : <strong> {{
              $filters.currencySymbol(player.betAmount2)
            }}</strong></span>
        </h4>

      </div>

      <!-- 玩家列表 -->
      <div class="g-players">
        <h4>
          <strong :class="tabActive ? 'active' : ''" @click="playersList">
            <nut-icon name="my"></nut-icon>
            {{ $t('game.players') }}:
            <span>{{ this.game.totalPlayerCount }}</span>
          </strong>
          <strong :class="tabActive ? '' : 'active'" @click="myHistoryList">
            <nut-icon name="date"></nut-icon>
            {{ $t('game.myHistory') }}
          </strong>
        </h4>

        <ul v-show="tabActive">
          <li v-if="this.game.totalPlayerCount > 0">
            <span class="l" style="width:90px;color: #C1B3DB;">{{ $t('game.players') }}</span>
            <span class="l" style="width:80px;color: #C1B3DB;">{{ $t('game.betAmount') }}</span>
            <span class="l" style="width:60px;color: #C1B3DB;">{{ $t('game.winRatio') }}</span>
            <span class="r" style="color: #C1B3DB;">{{ $t('game.winAmount') }}</span>
          </li>

          <li v-for="(playerls) in game.players" :key="playerls.id" :class="playerls.id === player.id ? 'myBet' : ''">

            <span class="l" style="width:90px"><img :src="playerls.avatar"/>{{ playerls.mobile }}</span>

            <span class="l" style="width:80px">{{ $filters.currencySymbol(playerls.betAmount) }}</span>

            <span v-show="playerls.escapeAmount!==null" class="l" style="width:60px;">{{ playerls.escapeRatio }}x</span>

            <span v-show="playerls.escapeRatio!==null" class="r"
                  style="color: #bbaf78;">{{ $filters.currencySymbol(playerls.escapeAmount) }}</span>

          </li>
          <li v-if="this.game.playersMore" style="text-align: center;opacity:0.5;"><span>{{ $t('game.last20') }}</span>
          </li>
        </ul>

        <ul v-show="!tabActive" class="mylist">
          <li>
            <span class="l" style="width:90px;color: #C1B3DB;">{{ $t('game.feeAmount') }}</span>
            <span class="l" style="width:60px;color: #C1B3DB;">{{ $t('game.betAmount') }}</span>
            <span class="l" style="width:60px;color: #C1B3DB;">{{ $t('game.ratio') }}</span>
            <span class="r" style="color: #C1B3DB;">{{ $t('game.winAmount') }}</span>
          </li>

          <li v-for="(item, index) in myHistoryData" :key="index">

            <span class="l" style="width:90px;">{{ item.betTime }}</span>

            <span class="l" style="width:60px">{{ $filters.currencySymbol(item.betAmount) }}</span>

            <span v-if="item.status === 'escape'" class="l" style="width:60px;">{{ item.escapeRatio }}x</span>
            <span v-else class="l" style="width:60px;">--</span>
            <span v-if="item.status === 'escape'" class="r"
                  style="color:#bbaf78;">+{{ $filters.currencySymbol(item.originWinAmount) }}</span>
            <span v-else class="r" style="color:#bbaf78;">--</span>
          </li>
          <li></li>
          <li style="text-align: center;" @click="toMyPlayList">{{ $t('game.more') }} >></li>

        </ul>
      </div>

      <!--**************************************** 规则 ****************************************-->
      <nut-popup position="top" closeable round :style="{ height: '70%', background:'#30284C' }" v-model:visible="rulePopup">
        <GameRules></GameRules>
      </nut-popup>


      <!--**************************************** 历史结果 ****************************************-->
      <nut-popup position="bottom" closeable round :style="{ height: '70%', background:'#30284C' }" v-model:visible="historyPopup">
        <h4 class="h4" style="margin-left: 10px; color: #fff;">
          <nut-icon name="date" style="vertical-align: middle;margin-right: 5px;margin-bottom: 4px;"></nut-icon>
          {{ $t('game.historData') }}
        </h4>
        <GameHistory2 :ref="gameHistory2" v-if="historyPopup"></GameHistory2>
      </nut-popup>

      <!--**************************************** Big win ****************************************-->
      <nut-popup pop-class="pop-top-bigwin" v-model:visible="bigwinData.show" position="left" :closeable="true" :overlay="false" style="top: 7px !important">
        <div class="bd">
          <!--div class="t1"><img class="avatar" :src="bigwinData.data.avatar" -->
          <div class="t1"><img class="avatar" :src="require('../assets/images/global/bigwin.png')" >
            <span>{{ bigwinData.data.mobile }}</span>
          </div>
          <div v-if="bigwinData.data.appTypeCode === 'crash'" class="t2 crash"><span>{{ bigwinData.data.crashEscapeRatio }}x</span></div>
          <div v-else-if="bigwinData.data.appTypeCode === 'parity'" class="t2 parity"><span>{{ bigwinData.data.parityRoomName }}</span></div>
          <div v-else-if="bigwinData.data.appTypeCode === 'wheel'" class="t2 wheel"><span> </span></div>
          <div v-else-if="bigwinData.data.appTypeCode === 'mine'" class="t2 mine"><span>{{ bigwinData.data.mineRowCount }} X {{ bigwinData.data.mineRowCount }}</span></div>
          <div v-else-if="bigwinData.data.appTypeCode === 'andar'" class="t2 andar"><span></span></div>
          <div v-else-if="bigwinData.data.appTypeCode === 'dice'" class="t2 dice"><span></span></div>
          <div class="t3"><span>WIN</span><strong>{{ bigwinData.data.winAmount }}</strong></div>
        </div>
      </nut-popup>

      <!--**************************************** Broadcast Msg ****************************************-->
      <nut-popup pop-class="pop-top-broadcast-msg" v-model:visible="broadcastMsgData.show" position="left" :closeable="true" :overlay="false" style="top: 5px !important;">
        <div class="bd">
          <div class="t4" v-if="broadcastMsgData.type === 'parity_prediction'">&nbsp;</div>
          <div class="t5"><span>{{ broadcastMsgData.message}}</span></div>
        </div>
      </nut-popup>

    </div>

  </div>
</template>

<script>
import {fabric} from "fabric"
import GameHistory2 from "../components/GameHistory2.vue";
import GameRules from "../components/GameRules.vue";
import {Dialog, Toast} from '@nutui/nutui';
import {getValue, isBlank, isNotBlank, isTrue} from "@/assets/js/commonUtil";
import router from "@/router";
import axios from "axios";
import {getDeviceId} from "@/assets/js/visitor";
import { setLastTimeGameName } from "@/assets/js/environment";
import {commonEvent, createSocket, disconnectSocket, gameType} from "@/assets/js/game";
import { getCommonInfoAttr } from "@/assets/js/commonInfo";

export default {

  components: {
    GameHistory2: GameHistory2,
    GameRules: GameRules
  },
  data() {
    return {
      commonInfo: JSON.parse(window.localStorage.getItem("commonInfo")),
      imgUrl : localStorage.getItem('countryImgUrl'),
      overlayClass: 'overlay-div',
      showViponly: false,
      gotitBtn: false,
      betDisabled: false,
      escapeDisabled: false,
      showDialogFill: true,
      showDialogRecharge: true,
      cancelText: 'OK',
      okText: 'OK',
      rechargeBtnText: this.$t('global.rechargeBtnText'),
      withdrawalBtnText: this.$t('global.withdrawalBtnText'),
      inviteBtnText: this.$t('global.inviteBtnText'),
      overlayShow: true,
      rulePopup: false,
      historyPopup: false,
      alreadyAlertMaxLimit: false,
      tabActive: true,
      listNoData: true,
      myHistoryData: '',
      draw: {
        canvas: null,
        plane: null,
        planeImg: require('../assets/images/game_crash/fj3.png'),
        planeOpacity: 1,
        planeBeginTop: 280,
        planeCenterTop: 80,
        planeUserTop: 0,
        planeBeginLeft: -90,
        planeCenterLeft: 280,
        planeUserLeft: 0,
        planeBeginAngle: 0,
        planeCenterAngle: -75,
        planeUnit: 4,
        planeCenterRatio: null,
        planeBeginScale: 0.3,
        flame: 0,
        background: null,
        backgroundBeginTop: -3780,
        background2: null,
        background2BeginTop: 0,
        background2CenterTop: 400,
        background2BeginLeft: 0,
        background2CenterLeft: -460,
        flightPath: null
      },
      socket: null,
      game: {
        on: false,
        pause: false,
        serialNum: null,
        betDeadline: null,
        endDeadline: null,
        stage: null,
        expect: null,
        currentRatio: 1.00,
        startTimestamp: null,
        gameHistoryList: [],
        betAmountRange:{
          max: null,
          min: null,
        },
        recommendBetAmounts: [],
        fillUpAlertThreshold: null, //补满金币阈值
        fillUpAmount: null, //补满金币值
        totalPlayerCount: 0,
        players: [],
        escapedPlayerCount: 0,
        playersMore: false,
        lastGameBigWinners: [],
        delay: -1,
        lastGameTopList: '',
        lastGameWinAmount: '',
        latency: null,
        statistics: true,
      },
      player: {
        id: null,
        vipLevel: 0,
        vipMaxRatio: 100,
        avatar: null,
        availableAmount: null,
        rechargeAmount: null,
        withdrawAmount: null,
        playStatus: null,
        betAmount: 20,
        betAmount2: null,
        currentWinAmount: null,
        myWinAmount: null,
        escapeRatio: null,
        escapeAmount: null,
        autoEscape: false,
        autoEscapeRatio: 3.00,
        autoEscaping: false,
      },
      //event
      eventEnum: {
        initRoom: "s_cr_init_room", //初始化房间
        startBet: "s_cr_start_bet", //开始下注阶段
        startFlying: "s_cr_start_flying", //开始飞行阶段
        startEnd: "s_cr_start_end", //开始结束阶段
        playerInfo: "s_cr_player", //当前用户的玩家信息
        pause: "s_cr_pause", //游戏暂停
        betNotify: "s_cr_bet", //任意玩家的下注通知
        escapeNotify: "s_cr_escape",//任意玩家的逃跑通知
        bet: "c_cr_bet",//下注
        escape: "c_cr_escape",//逃跑
      },
      //游戏阶段
      stageEnum: {
        bet: "bet",
        flying: "flying",
        end: "end",
        pause: "pause"
      },
      //玩家状态
      playerStatusEnum: {
        notBet: "not_bet",//未下注
        bet: "bet",//已下注
        escape: "escape",//已逃跑
        crash: "crash",//已爆炸
        cancel: "cancel",//游戏取消
      },
      bigwinData: {
        show: false,
        start: false,
        list: [],
        data: {}
      },
      broadcastMsgData: {
        show: false,
        type: '',
        message: '',
      },
      appTaskStatus: '',
      appTaskReward: '',
      messageList: [],
      img:{
        fj3: require('../assets/images/game_crash/fj3.png'),
        fj4: require('../assets/images/game_crash/fj4.png'),
        fjc: require('../assets/images/game_crash/fjc.png'),
        fjbz: require('../assets/images/game_crash/fjbz.png')
      }
    }
  },
  methods: {
    //跳转到游戏大厅
    toGameLobby(){
      router.push({path: "lobby"});
    },
    //跳转到我的下注历史
    toMyPlayList() {
      router.push({
        name: "myPlayList2",
      });
    },
    //下注加减
    btnAddition() {
      if (isBlank(this.player.betAmount)) {
        this.player.betAmount =  this.game.betAmountRange.min
        return
      }
      if (this.player.betAmount <= 99) {
        this.player.betAmount = this.$math.add(this.player.betAmount, 10)
        if(this.player.betAmount < 0){this.player.betAmount = 0}
      } else if (this.player.betAmount <= 999) {
        this.player.betAmount = this.$math.add(this.player.betAmount, 100)
      } else if (this.player.betAmount <= 9999) {
        this.player.betAmount = this.$math.add(this.player.betAmount, 1000)
      } else if (this.player.betAmount <= 99999) {
        this.player.betAmount = this.$math.add(this.player.betAmount, 10000)
      } else if (this.player.betAmount <= 999999) {
        this.player.betAmount = this.$math.add(this.player.betAmount, 100000)
      } else if (this.player.betAmount <= 9999999999) {
        this.player.betAmount = this.$math.add(this.player.betAmount, 1000000)
      }
      if(this.player.betAmount >=  this.game.betAmountRange.max){
        this.player.betAmount =  this.game.betAmountRange.max
        Toast.text("Maximum of " + this.game.betAmountRange.max);
      }
      this.player.betAmount = Math.floor(this.player.betAmount)
    },
    btnSubtract() {
      if (isBlank(this.player.betAmount) || this.player.betAmount <= this.game.betAmountRange.min) {
        Toast.text("Minimum of " + this.game.betAmountRange.min);
        return
      }
      if (this.player.betAmount <= 100) {
        this.player.betAmount = this.$math.subtract(this.player.betAmount, 10)
        if(this.player.betAmount < 0){this.player.betAmount = 0}
      } else if (this.player.betAmount <= 1000) {
        this.player.betAmount = this.$math.subtract(this.player.betAmount, 100)
      } else if (this.player.betAmount <= 10000) {
        this.player.betAmount = this.$math.subtract(this.player.betAmount, 1000)
      } else if (this.player.betAmount <= 100000) {
        this.player.betAmount = this.$math.subtract(this.player.betAmount, 10000)
      } else if (this.player.betAmount <= 1000000) {
        this.player.betAmount = this.$math.subtract(this.player.betAmount, 100000)
      } else if (this.player.betAmount <= 10000000) {
        this.player.betAmount = this.$math.subtract(this.player.betAmount, 1000000)
      }
      this.player.betAmount = Math.floor(this.player.betAmount)
    },
    //禁止拖拽
    noDragging(obj) {
      obj.hasBorders = false
      obj.hasControls = false
      obj.hasRotatingPoint = false
      obj.evented = false
    },
    //创建画布
    createCanvas() {
      this.draw.planeCenterRatio = this.$math
          .chain(this.draw.planeBeginTop)
          .subtract(this.draw.planeCenterTop)
          .divide(4)
          .multiply(0.01)
          .add(1)
          .done();
      this.draw.canvas = new fabric.Canvas("canvas", {width: 350, height: 350, backgroundColor: '#182342'});
    },
    //重置画布
    resetCanvas() {
      this.draw.canvas.remove(this.draw.plane)
      this.draw.canvas.clear();
      //this.draw.planeOpacity = 1;
      this.draw.plane = new fabric.Image(this.$refs.plane, {
        left: this.draw.planeBeginLeft,
        top: this.draw.planeBeginTop,
        angle: this.draw.planeBeginAngle
      });
      this.draw.plane.scale(this.draw.planeBeginScale);
      this.draw.background = new fabric.Image(this.$refs.background, {
        left: 0,
        top: this.draw.backgroundBeginTop,
      });
      this.draw.background2 = new fabric.Image(this.$refs.background2, {
        left: 0,
        top: this.draw.background2BeginTop,
      });
      this.noDragging(this.draw.plane)
      this.noDragging(this.draw.background)
      this.noDragging(this.draw.background2)
      /*
            this.draw.flightPath = new fabric.Path('C 10 90 160 130 308 25',{
              left: 0,
              top: 0,
              stroke: 'red',
              strokeWidth: 1,
              scaleY:3,
              fill:false,
              hasControls: false
            });
            //this.draw.canvas.add(this.draw.background, this.draw.background2, this.draw.plane, this.draw.leftSmoke, this.draw.rightSmoke, this.draw.flightPath);
      */
      this.draw.canvas.add(this.draw.background, this.draw.background2, this.draw.plane);
    },
    getUnitCount() {
      return this.$math.chain(this.game.currentRatio).subtract(1).multiply(100).done();
    },
    //炸弹
    planeBomb() {
      this.draw.plane.animate("opacity", "0", {
        duration: 300,
      })
      let imgUrl = this.img.fjc
      fabric.Image.fromURL(imgUrl, (tImg) => {
        tImg.left = this.draw.planeUserLeft + 5
        tImg.top = this.draw.planeUserTop - 25
        tImg.scaleToWidth(30);
        tImg.scaleToHeight(60);
        this.draw.canvas.add(tImg)
        tImg.animate("top", "700", {
          duration: 2000,
        });
      })
      let imgUrl2 = this.img.fjbz
      fabric.Image.fromURL(imgUrl2, (bImg) => {
        bImg.left = this.draw.planeUserLeft - 20
        bImg.top = this.draw.planeUserTop - 50
        bImg.scaleToWidth(100);
        bImg.scaleToHeight(100);
        //bImg.scale(1)
        this.draw.canvas.add(bImg)
        bImg.animate("opacity", "0", {
          duration: 1000,
        })
      })
      setTimeout(() => {
        this.resetCanvas()
        this.draw.canvas.remove(this.draw.plane)
        this.draw.canvas.renderAll()
      }, 2000);
      

    },

    //逃跑
    getAway(img) {
      let a = require('../assets/images/avatar/' + img.slice(img.length-6))
      fabric.Image.fromURL(a, (oImg) => {
        oImg.left = this.draw.planeUserLeft + 3
        oImg.top = this.draw.planeUserTop
        oImg.scaleToWidth(24);
        oImg.scaleToHeight(24);
        this.draw.canvas.add(oImg)
        oImg.animate("top", "381", {
          duration: 2300,
        });
        oImg.animate("left", "0", {
          duration: 2600,
        });
      })
    },

    //飞机动画
    renderPlane() {
      let unitCount = this.getUnitCount();

      let positionOffset = this.$math.chain(this.draw.planeUnit).multiply(unitCount).done();
      let positionOffset2 = this.$math.chain(this.draw.planeUnit - 3).multiply(unitCount).done();
      let positionOffset5 = this.$math.chain(this.draw.planeUnit * 5).multiply(unitCount).done();
      let scaleOffset = this.$math.chain(0.002).multiply(unitCount).done();

      let currentTop = this.$math.chain(this.draw.planeBeginTop).subtract(positionOffset).done();
      let currentLeft = this.$math.chain(this.draw.planeBeginLeft).add(positionOffset5).done();
      let currentAngle = this.$math.chain(this.draw.planeBeginAngle).subtract(positionOffset2).done();
      let currentScale = this.$math.chain(this.draw.planeBeginScale).add(scaleOffset).done();

      if (currentTop < this.draw.planeCenterTop) {
        currentTop = this.draw.planeCenterTop;
      }
      this.draw.plane.set({top: currentTop})
      this.draw.planeUserTop = currentTop;
      if (currentLeft < 30) {
        currentLeft = 30
      }
      if (currentLeft > this.draw.planeCenterLeft) {
        currentLeft = this.draw.planeCenterLeft;
        if (currentTop === this.draw.planeCenterTop) {
          let n = Math.round(Math.random() * -3 + 3);
          currentLeft = currentLeft + n
        }
      }
      this.draw.plane.set({left: currentLeft});
      this.draw.planeUserLeft = currentLeft;

      if (currentAngle < this.draw.planeCenterAngle) {
        currentAngle = this.draw.planeCenterAngle;
      }
      this.draw.plane.set({angle: currentAngle});

      if (currentScale > 0.2) {
        currentScale = 0.2;
      }


      if (this.draw.flame === 5) {
        this.draw.planeImg = this.img.fj3;
        this.draw.flame++
      } else if (this.draw.flame === 10) {
        this.draw.planeImg = this.img.fj4;
        this.draw.flame = 0
      } else {
        this.draw.flame++
      }

      this.draw.plane.scale(currentScale);

    },
    //背景动画
    renderBackground() {
      //if (this.game.currentRatio < this.draw.planeCenterRatio) {
      //return;
      //}
      //计算偏移倍率
      //let unitCount = this.$math.chain(this.game.currentRatio).subtract(this.draw.planeCenterRatio).multiply(100).done();
      let unitCount = this.$math.chain(this.currentTimestamp() - this.game.startTimestamp).divide(50).done();

      let positionOffset3 = this.$math.chain(3).multiply(unitCount - 50).done();
      let positionOffset5 = this.$math.chain(5).multiply(unitCount).done();
      let positionOffset20 = this.$math.chain(20).multiply(unitCount).done();

      let currentLeft2 = this.$math.chain(this.draw.background2BeginLeft).subtract(positionOffset20).done();
      let currentTop2 = this.$math.chain(this.draw.background2BeginTop).add(positionOffset5).done();

      if (currentLeft2 < this.draw.background2CenterLeft) {
        currentLeft2 = this.draw.background2CenterLeft;
      }
      this.draw.background2.set({left: currentLeft2});

      if (currentTop2 > this.draw.background2CenterTop) {
        currentTop2 = this.draw.background2CenterTop;
      }
      this.draw.background2.set({top: currentTop2});

      if (currentTop2 > this.draw.background2CenterTop - 200) {
        let currentTop = this.$math.chain(this.draw.backgroundBeginTop).add(positionOffset3).done();
        if (currentTop > 0) {
          currentTop = 0;
        }
        this.draw.background.set({top: currentTop});
      }

    },
    render() {
      this.renderPlane();
      this.renderBackground();
      this.draw.canvas.renderAll();
    },
    showEscapeAnimate() {
      let imgUrl3 = require('../assets/images/global/bg194.png')
      fabric.Image.fromURL(imgUrl3, (j1Img) => {
        j1Img.left = this.draw.planeUserLeft - 20
        j1Img.top = this.draw.planeUserTop - 70
        j1Img.scaleToWidth(25);
        j1Img.scaleToHeight(25);
        this.draw.canvas.add(j1Img)
        j1Img.animate("top", 350, {duration: 1000,});
        j1Img.animate("left", 320, {duration: 1000,});
      })
      fabric.Image.fromURL(imgUrl3, (j2Img) => {
        j2Img.left = this.draw.planeUserLeft + 20
        j2Img.top = this.draw.planeUserTop - 20
        j2Img.scaleToWidth(25);
        j2Img.scaleToHeight(25);
        this.draw.canvas.add(j2Img)
        j2Img.animate("top", 350, {duration: 500,});
        j2Img.animate("left", 320, {duration: 500,});
      })
      fabric.Image.fromURL(imgUrl3, (j3Img) => {
        j3Img.left = this.draw.planeUserLeft - 10
        j3Img.top = this.draw.planeUserTop + 20
        j3Img.scaleToWidth(25);
        j3Img.scaleToHeight(25);
        this.draw.canvas.add(j3Img)
        j3Img.animate("top", 350, {duration: 800,});
        j3Img.animate("left", 320, {duration: 800,});
      })
      fabric.Image.fromURL(imgUrl3, (j4Img) => {
        j4Img.left = 130
        j4Img.top = 190
        j4Img.scaleToWidth(25);
        j4Img.scaleToHeight(25);
        this.draw.canvas.add(j4Img)
        j4Img.animate("top", 350, {duration: 800,});
        j4Img.animate("left", 320, {duration: 800,});
      })
      fabric.Image.fromURL(imgUrl3, (j5Img) => {
        j5Img.left = 50
        j5Img.top = 100
        j5Img.scaleToWidth(25);
        j5Img.scaleToHeight(25);
        this.draw.canvas.add(j5Img)
        j5Img.animate("top", 350, {duration: 800,});
        j5Img.animate("left", 320, {duration: 800,});
      })
      fabric.Image.fromURL(imgUrl3, (j6Img) => {
        j6Img.left = 50
        j6Img.top = 100
        j6Img.scaleToWidth(25);
        j6Img.scaleToHeight(25);
        this.draw.canvas.add(j6Img)
        j6Img.animate("top", 350, {duration: 800,});
        j6Img.animate("left", 320, {duration: 800,});
      })
      fabric.Image.fromURL(imgUrl3, (j7Img) => {
        j7Img.left = 100
        j7Img.top = 50
        j7Img.scaleToWidth(25);
        j7Img.scaleToHeight(25);
        this.draw.canvas.add(j7Img)
        j7Img.animate("top", 350, {duration: 800,});
        j7Img.animate("left", 320, {duration: 800,});
      })
    },
    betAmountMax(a) {
      let aa = Number(a)
      if (aa < this.game.betAmountRange.min) {
        Toast.text("Minimum of " + this.game.betAmountRange.min);
        this.player.betAmount = this.game.betAmountRange.min
      }else if(aa >= this.game.betAmountRange.max){
        Toast.text("Maximum of " + this.game.betAmountRange.max);
        this.player.betAmount = this.game.betAmountRange.max
      }else{
        this.player.betAmount = aa
      }
    },
    //恢复用户设置
    recoveryUserSettings() {
      let localAutoEscape = localStorage.getItem('autoEscape');
      if (isBlank(localAutoEscape)) {
        this.player.autoEscape = false;
      } else {
        this.player.autoEscape = localStorage.getItem('autoEscape') === 'true'
      }
      let localAutoEscapeRatio = localStorage.getItem('autoEscapeRatio');
      if (isBlank(localAutoEscapeRatio)) {
        this.player.autoEscapeRatio = 1.1;
      } else {
        this.player.autoEscapeRatio = parseFloat(localAutoEscapeRatio);
      }
    },

    //socket
    socketInit() {
      if (this.socket != null) {
        return;
      }
      this.socket = createSocket('crash2', this.bigwinData, this.broadcastMsgData);

      /**
       * 就绪
       */
      this.socket.on(commonEvent.ready, () => {
        this.socket.emit(commonEvent.join);
        this.resetCanvas();
        this.overlayShow = false

      });

      this.socket.on('disconnect', () => {
        this.game.on = false;
      });

      this.socket.on('pong', (latency) => {
        this.game.latency = latency + 'ms';
      });

      //初始化房间
      this.socket.on(this.eventEnum.initRoom, (room) => {
        if(isNotBlank(room) && isNotBlank(room.sn)){
          this.pushMessageList("ir "+room.sn.slice(-4)+" "+room.s);
        }
        //游戏阶段
        let stage = room.s;
        if (stage === this.stageEnum.flying) {
          this.game.on = true;
        } else {
          this.game.on = false;
        }
        if (stage === this.stageEnum.pause) {
          this.game.pause = true;
        }
        //下注阶段剩余时间
        let betRemainMillis = room.brm;
        if (isNotBlank(betRemainMillis)) {
          this.game.betDeadline = Date.now() + betRemainMillis;
        }
        //结束阶段剩余时间
        let endRemainMillis = room.erm;
        if (isNotBlank(endRemainMillis)) {
          this.game.endDeadline = Date.now() + endRemainMillis;
        }
        this.game.serialNum = room.sn;
        this.game.currentRatio = room.r;
        this.game.stage = stage;
        this.game.startTimestamp = this.currentTimestamp() - room.sm;

        this.game.fillUpAlertThreshold = room.fuat;
        this.game.fillUpAmount = room.fua;
        //设置下注框默认值
        this.player.betAmount = localStorage.getItem('lastBetAmount') ? localStorage.getItem('lastBetAmount') : this.game.betAmountRange.min
        //最近几局倍率
        this.game.gameHistoryList = room.hl;
        //上局游戏赢家
        room.lgbw.forEach(bigWinner => {
          this.game.lastGameBigWinners.push(bigWinner.m + ' won ' + bigWinner.wa);
        });
        this.game.lastGameTopList = room.lgbw
        this.game.lastGameWinAmount = room.lgpwa
        //总玩家人数
        this.game.totalPlayerCount = room.tpc;
        //玩家列表
        this.game.players.length = 0;
        this.game.playersMore = false;
        room.p.forEach(player => {
          this.game.players.unshift({
            id: player.id,
            //avatar: player.a,
            avatar: require('../assets/images/avatar/' + player.a.slice(player.a.length-6)),
            mobile: player.m,
            betAmount: player.ba,
            escapeRatio: getValue(player.er),
            escapeAmount: getValue(player.ea),
          });
          if (this.game.players.length >= 20) {
            this.game.playersMore = true
          }
          if (isNotBlank(player.er)) {
            //已经逃跑
            this.game.escapedPlayerCount++;
          }
        });


      });

      //当前用户玩家信息
      this.socket.on(this.eventEnum.playerInfo, (playerInfo) => {
        if (isNotBlank(playerInfo)) {
          this.pushMessageList("pi "+playerInfo.ps+" "+playerInfo.ba);
        }
        //可用余额
        this.player.id = playerInfo.id
        this.player.vipLevel = playerInfo.vl;
        this.player.vipMaxRatio = playerInfo.vmr;
        this.player.avatar = playerInfo.a
        this.player.availableAmount = playerInfo.aa
        this.player.rechargeAmount = playerInfo.ra
        this.player.withdrawAmount = playerInfo.wa
        this.player.playStatus = playerInfo.ps;
        if (isNotBlank(getValue(playerInfo.ba))) {
          this.player.betAmount = getValue(playerInfo.ba);
        }
        this.player.escapeRatio = getValue(playerInfo.er);
        this.player.escapeAmount = getValue(playerInfo.ea);
        if (isTrue(playerInfo.nw)) {
          this.showNeedWithdrawDialog(this.$t('game.pleaseWithdraw'));
        }
      });

      //开始下注阶段
      this.socket.on(this.eventEnum.startBet, (info) => {
        if (isNotBlank(info) && isNotBlank(info.sn)) {
          this.pushMessageList("sb1 "+info.sn.slice(-4));
        }
        this.game.stage = this.stageEnum.bet;
        this.game.pause = false;
        this.game.on = false;

        //刷新玩家列表
        this.game.playersMore = false;
        this.game.totalPlayerCount = 0;
        this.game.players.length = 0;
        this.game.escapedPlayerCount = 0;

        this.game.serialNum = info.sn;
        this.game.betDeadline = Date.now() + info.btd * 1000;

        if (isNotBlank(info) && isNotBlank(info.sn)) {
          this.pushMessageList("sb2 "+info.sn.slice(-4));
        }

        //下注状态重置图像位置
        this.resetCanvas();

        if (isNotBlank(info) && isNotBlank(info.sn)) {
          this.pushMessageList("sb3 "+info.sn.slice(-4));
        }

        //重置玩家状态
        this.player.playStatus = this.playerStatusEnum.notBet;
        this.player.myWinAmount = null
        this.player.betAmount2 = null

        if (isNotBlank(info) && isNotBlank(info.sn)) {
          this.pushMessageList("sb4 " + info.sn.slice(-4) + " " + this.player.playStatus);
        }
      });

      //开始飞行阶段
      this.socket.on(this.eventEnum.startFlying, (game) => {
        if (isNotBlank(game) && isNotBlank(game.sn)) {
          this.pushMessageList("sf "+game.sn.slice(-4));
        }
        this.game.stage = this.stageEnum.flying;
        this.game.pause = false;
        this.game.on = true;
        //this.showViponly = false
        //统一使用前端的时间戳
        this.game.startTimestamp = this.currentTimestamp();
        // this.game.expect = aesDecrypt(info.t);
      });

      // 开始结束阶段
      this.socket.on(this.eventEnum.startEnd, (info) => {
        if (isNotBlank(info) && isNotBlank(info.sn)) {
          this.pushMessageList("se " + info.sn.slice(-4));
        }
        if (this.game.stage === this.stageEnum.end) {
          return;
        }
        this.game.stage = this.stageEnum.end;
        this.game.pause = false;
        this.game.on = false;
        //this.game.playersMore = false;

        //炸弹
        this.planeBomb();
        this.game.endDeadline = Date.now() + info.edd * 1000;
        //刷新最近几局倍率
        this.game.gameHistoryList = info.hl;
        //刷新上局游戏赢家
        this.game.lastGameBigWinners.length = 0;
        info.lgbw.forEach(bigWinner => {
          this.game.lastGameBigWinners.push(bigWinner.m + ' won ' + bigWinner.wa);
        });
        this.game.lastGameTopList = info.lgbw
        this.game.lastGameWinAmount = info.lgpwa

      });

      //任意用户下注通知
      this.socket.on(this.eventEnum.betNotify, (notify) => {
        this.game.totalPlayerCount = notify.tpc;
        if (this.game.players.length >= 20) {
          this.game.players.pop()
          this.game.playersMore = true
        }
        if (this.game.players.length < 20) {
          this.game.players.unshift({
            id: notify.id,
            //avatar: notify.a,
            avatar: require('../assets/images/avatar/' + notify.a.slice(notify.a.length-6)),
            mobile: notify.m,
            betAmount: notify.ba,
            escapeRatio: null,
            escapeAmount: null,
          });
        }
      });

      //任意用户逃离通知
      this.socket.on(this.eventEnum.escapeNotify, (notify) => {
        //逃离效果
        this.getAway(notify.a);

        //列表更新
        this.game.players.forEach(player => {
          if (player.id === notify.id) {
            player.escapeRatio = notify.er;
            player.escapeAmount = notify.ea;
          }
        });
        //逃离用户数+1
        this.game.escapedPlayerCount++;
      });
    },
    nextRound() {
      Toast.text(this.$t('game.waitForTheNextRound'));
    },
    pushMessageList(msg) {
      this.messageList.push(msg);
      if (this.messageList.length > 10) {
        this.messageList.shift();
      }
     // console.log(this.messageList);
    },
    serializeMessageList() {
      let str = "";
      this.messageList.forEach((e) => {
        str += (e + ",");
      })
      return str;
    },
    bet() {
      if (isBlank(this.player.betAmount) && this.betDisabled) {
        return;
      }

      if (this.player.betAmount > this.game.betAmountRange.min && this.player.vipLevel < 1) {
        //Vip0 input amount can only be 10
        Toast.text(this.$t('game.info3') + this.game.betAmountRange.min);
        this.player.betAmount = this.game.betAmountRange.min
        return;
      }
      this.betDisabled = true
      if (isNotBlank(this.game) && isNotBlank(this.game.serialNum)) {
        this.pushMessageList("betbefore "+this.game.serialNum.slice(-4));
      }
      let start = new Date().getTime();
      axios.post("/api/crash2/bet",
          {
            betAmount: this.player.betAmount
          },
          { timeout: 8000 }
      ).then((res) => {
        this.player.myWinAmount = 0
        let result = res.data;
        if (result.code === 0) {
          let end = new Date().getTime();
          Toast.success(this.$t('global.successfully') + ' ' + this.player.betAmount);
          localStorage.setItem("lastBetAmount", this.player.betAmount);
          this.player.availableAmount = result.data.availableAmount;
          /*
                    let setIntervalFn = setInterval(
                      function(){
                        if(this.player.availableAmount > ack.aa){
                          this.player.availableAmount -= 10
                        }else{
                          this.player.availableAmount = ack.aa;
                          clearInterval(setIntervalFn)
                        }
                      }
                    ,50)
          */
          this.player.playStatus = this.playerStatusEnum.bet;
          this.player.betAmount2 = this.player.betAmount;
          if (isNotBlank(result) && isNotBlank(result.data) && isNotBlank(result.data.serialNum)) {
            this.pushMessageList("betok "+result.data.serialNum.slice(-4) + " "+ (end-start) +"ms");
          }else{
            this.pushMessageList("betokbutnull");
          }
        } else if (result.bizCode === 'CRASH_WAIT_NEXT_ROUND') {
          //已经是飞行阶段了
          Toast.text(this.$t('game.waitForTheNextRound'));
        } else if (result.bizCode === 'GAME_BET_NEED_WITHDRAW') {
          //需要提现
          this.showNeedWithdrawDialog(this.$t('game.pleaseWithdraw'))
        }
        this.betDisabled = false
      }).catch(() => {
        this.betDisabled = false
      });
    },
    escape(autoEscape) {
      if(this.escapeDisabled){
        return
      }
      this.escapeDisabled = true
      axios({
        url: "/api/crash2/escape",
        method: "post",
        timeout: 8000,
        params: {
          serialNum: this.game.serialNum,
          stage: this.game.stage,
          latency: this.game.latency,
          playStatus: this.player.playStatus,
          betAmount2: this.player.betAmount2,
          messageList: this.serializeMessageList(),
        }
      }).then((res) => {
        let result = res.data;
        let data = result.data;
        if (result.code === 0) {

          this.showEscapeAnimate();

          // Toast.success('Win ' + ack.ea + ' (' + ack.er + "X)");
          this.player.availableAmount = data.availableAmount;
          this.player.playStatus = this.playerStatusEnum.escape;
          this.player.escapeRatio = data.escapeRatio;
          this.player.escapeAmount = data.escapeAmount;
          this.player.myWinAmount = data.escapeAmount;
          if (isTrue(data.ratioLimited)) {
            //被限制了最高倍率
            this.showMaxRatioAlert();
          }
          if (isTrue(data.needWithdraw)) {
            //需要提现
            this.showNeedWithdrawDialog();
          }
        } else if (result.bizCode === 'CRASH_ALREADY_CRASH') {
          //已经爆炸了
          Toast.fail('already crashed!');
        } else {
          Toast.fail(result.msg);
        }
        if (autoEscape) {
          this.player.autoEscaping = false;
        }
        this.escapeDisabled = false
      }).catch(() => {
        this.escapeDisabled = false
      });
    },
    loadHistory() {
      axios({
        url: "/api/crash2/stats",
        method: "get"
      }).then((res) => {
        let response = res.data;
        let data = response.data;

        //刷新最近几局倍率
        this.game.gameHistoryList = data.hl;
        //刷新上局游戏赢家
        this.game.lastGameBigWinners.length = 0;
        data.lgbw.forEach(bigWinner => {
          this.game.lastGameBigWinners.push(bigWinner.m + ' won ' + bigWinner.wa);
        });
        this.game.lastGameTopList = data.lgbw
        this.game.lastGameWinAmount = data.lgpwa
        this.game.statistics = false
      });
    },

    showMaxRatioAlert() {
      if (this.alreadyAlertMaxLimit) {
        return;
      }
      Dialog({
        //Congratulations for big win!
        title: this.$t('game.DialogTitle'),
        //'For vip x , your max stop ratio is 100x, you can level-up vip to unlock the limit.',
        content: this.$t('game.DialogContent1') + this.player.vipLevel + this.$t('game.DialogContent2') + this.player.vipMaxRatio + this.$t('game.DialogContent3'),
        okText: this.$t('game.gotitBtn'),
        noCancelBtn: true
      });
      this.alreadyAlertMaxLimit = true;
    },

    withdrawClick() {
      router.push('/withdrawalBalance?amountType=coin');
    },
    inviteClick() {
      router.push('/agentPromotion');
    },
    rechargeBtnClick(){
      if(this.commonInfo.firstRechargeAvailable){
        router.push('/firstRechargeBonus');
      }else{
        router.push('/rechargeBalance');
      }
    },
    currentTimestamp() {
      return new Date().getTime();
    },
    updateAutoEscape() {
      if (this.player.autoEscapeRatio === null || this.player.autoEscapeRatio === 'NaN' || this.player.autoEscapeRatio < 1.1) {
        this.player.autoEscapeRatio = 1.1
      }
      localStorage.setItem('autoEscape', this.player.autoEscape)
      localStorage.setItem('autoEscapeRatio', this.player.autoEscapeRatio)
    },
    tryAutoEscape() {
      if (!this.player.autoEscape) {
        return;
      }
      if (this.player.playStatus !== this.playerStatusEnum.bet) {
        return;
      }
      if (this.player.autoEscaping) {
        //正在逃跑中
        return;
      }
      if (this.game.currentRatio >= this.player.autoEscapeRatio) {
        //自动逃离
        this.player.autoEscaping = true;
        this.escape(true);
      }
    },
    getGameHistoryClass(history, index) {
      let clas = '';
      if (index === 0) {
        clas = 'bg0'
      }
      if (history.l === 1) {
        return 'bg1' + ' ' + clas;
      } else if (history.l === 2) {
        return 'bg2' + ' ' + clas;
      } else if (history.l === 3) {
        return 'bg3' + ' ' + clas;
      } else if (history.l === 4) {
        return 'bg4' + ' ' + clas;
      } else {
        return 'bg5' + ' ' + clas;
      }
    },

    gameHistoryClick() {
      this.historyPopup = true
    },
    askClick() {
      this.rulePopup = true
    },
    async initPage() {
      const deviceId = await getDeviceId();
      localStorage.setItem("di", deviceId);
      return axios.get("/api/crash2/visit", {})
    },
    getMyHistoryList() {
      axios({
        url: "/api/crash2/order/paginate",
        method: "get",
        params: {current: 1}
      }).then((res) => {
        this.myHistoryData = res.data.data.records
        if (this.myHistoryData.length > 0) {
          this.listNoData = false;
        }
      });
    },
    playersList() {
      this.tabActive = true
    },
    myHistoryList() {
      this.tabActive = false
      this.getMyHistoryList();
    },
    getCurrentMillis() {
      return this.currentTimestamp() - this.game.startTimestamp;
    },
    calculateRatio(startRatio, millis, subtract, coefficient) {
      return this.$math.format(this.$math.add(this.$math.bignumber(startRatio), this.$math.multiply(this.$math.bignumber(millis - subtract), this.$math.bignumber(coefficient))), (value) => value.toFixed(2));
    },
    doOnMounted() {
      this.socketInit();
      this.createCanvas();
      this.recoveryUserSettings();
      setInterval(() => {
        if (this.game.on) {
          const millis = this.getCurrentMillis();
          if (millis >= 0 && millis < 10000) {
            this.game.currentRatio = this.calculateRatio(1.00, millis, 0, 0.0002);
          } else if (millis >= 10000 && millis < 20000) {
            this.game.currentRatio = this.calculateRatio(3.00, millis, 10000, 0.0004);
          } else if (millis >= 20000 && millis < 30000) {
            this.game.currentRatio = this.calculateRatio(7.00, millis, 20000, 0.0008);
          } else if (millis >= 30000 && millis < 40000) {
            this.game.currentRatio = this.calculateRatio(15.00, millis, 30000, 0.0016);
          } else if (millis >= 40000 && millis < 50000) {
            this.game.currentRatio = this.calculateRatio(31.00, millis, 40000, 0.0032);
          } else {
            this.game.currentRatio = this.calculateRatio(63.00, millis, 50000, 0.0064);
          }
          // this.tryForceCrash();
          //if (this.player.playStatus === this.playerStatusEnum.bet) {
            this.player.currentWinAmount = this.$math.format(this.$math.multiply(this.$math.bignumber(this.player.betAmount), this.$math.bignumber(this.game.currentRatio)), (value) => value.toFixed(2));
          //}
          this.render();
          this.tryAutoEscape();
          this.game.delay = 0
        } else if (this.game.delay < 25 && this.game.delay != -1) {
          this.renderPlane();
          this.draw.canvas.renderAll();
          this.game.delay++
        }
      }, 50);
    },

    downloadApp(){
      window.open(getCommonInfoAttr('appDownloadUrl'))
    }
  },
  mounted() {
    this.commonInfo = JSON.parse(window.localStorage.getItem("commonInfo")),
    this.game.betAmountRange.min = Math.floor(this.commonInfo.betAmountRange.min);
    this.game.betAmountRange.max = Math.floor(this.commonInfo.betAmountRange.max);
    this.game.recommendBetAmounts = this.commonInfo.recommendBetAmountList;


    this.initPage().then((response) => {
      if(isNotBlank(response.data.data.appTaskStatus)){
        this.appTaskReward = response.data.data.appTaskReward
        this.appTaskStatus = response.data.data.appTaskStatus
      }
      if (isNotBlank(response.data.data.vtoken)) {
        localStorage.setItem("vtoken", response.data.data.vtoken);
      }
      this.doOnMounted();
    });
    setLastTimeGameName('crash2','lobby')
  },
  beforeRouteLeave() {
    //断开游戏socket
    disconnectSocket(gameType.CRASH, this.socket);
    Toast.hide()
  },
  //watch: {
  //  'game.gameHistoryList' : function(){}
  //}
};
</script>

<style scoped src="../assets/css/game_crash.css"></style>

<style>
  .games-main .g-operate h4 .nut-inputnumber input {height: 34px;line-height: 34px; background: #170801;
    color: #fffa9e; font-size: 20px; width: 50px; display: inline !important;}
  .games-main .g-operate h4 .nut-inputnumber .nut-inputnumber__icon{color: #fffa9e; font-size: 30px; vertical-align: sub;}
  .games-main .g-operate h4 .nut-inputnumber .nut-inputnumber__icon--disabled{color:#6f687d;}
</style>