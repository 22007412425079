<template>
  <nut-tabbar v-model:visible="active"  :bottom="true" :safeAreaInsetBottom="true" :unactiveColor="unactiveColor" :activeColor="activeColor" size="24px">
    <nut-tabbar-item :tab-title="$t('global.home')"  :img="tabbarImg1" :activeImg="tabbarActiveImg1" @click="lobbyBtnClick"></nut-tabbar-item>
    <nut-tabbar-item :tab-title="$t('global.recharge')" to="./rechargeBalance" :img="tabbarImg2" :activeImg="tabbarActiveImg2"></nut-tabbar-item>
    <nut-tabbar-item :tab-title="$t('global.invite')"   to="./agentPromotion" :img="tabbarImg3" :activeImg="tabbarActiveImg3"></nut-tabbar-item>
    <nut-tabbar-item :tab-title="$t('global.reward')"   to="./taskReward" :img="tabbarImg4" :activeImg="tabbarActiveImg4"></nut-tabbar-item>
    <nut-tabbar-item :tab-title="$t('global.my')"       to="./myHome"     :img="tabbarImg5" :activeImg="tabbarActiveImg5"></nut-tabbar-item>
  </nut-tabbar>
</template>
<script>
  import { reactive, toRefs } from "vue";
  import {useRouter} from "vue-router";
  export default{
    props:[
      'activeIndex'
    ],
    data() {
      return {};
    },
    created() {},
    methods: {},
    setup(props) {
      const data = reactive({
        unactiveColor:"#c1b3db",
        activeColor:"#00eda6",
        tabbarImg1: require('../assets/images/global_icon/202405-1.png'),
        tabbarActiveImg1: require('../assets/images/global_icon/202405-1a.png'),
        tabbarImg2: require('../assets/images/global_icon/202405-3.png'),
        tabbarActiveImg2: require('../assets/images/global_icon/202405-3a.png'),
        tabbarImg3: require('../assets/images/global_icon/202405-2.png'),
        tabbarActiveImg3: require('../assets/images/global_icon/202405-2a.png'),
        tabbarImg4: require('../assets/images/global_icon/202405-4.png'),
        tabbarActiveImg4: require('../assets/images/global_icon/202405-4a.png'),
        tabbarImg5: require('../assets/images/global_icon/202405-5.png'),
        tabbarActiveImg5: require('../assets/images/global_icon/202405-5a.png'),
        active: props.activeIndex,
      });
      const router = useRouter();
      const methods = {
        lobbyBtnClick(){
          router.push({name: 'gameLobby'});
        },
      }
      return {
        ...toRefs(data),
        ...methods
      };
    },
  }
  
</script>

<style scoped>
  .nut-tabbar{border-top:2px solid #000; border-bottom:0; background:#30284cf1; height: 50px !important;}
</style>
<style>
</style>
