<template>
  <div class="transactionsHistoryList">
    <nut-navbar
    :left-show="false"
      :title="$t('transactions.h1')"
      fixed=true
      class="mb0 nav-icon-right nut-navbar--fixed"
    >
    <template #left>
      <i class="nutui-iconfont nut-icon nut-icon-left" @click="backClick"></i>
    </template>
    </nut-navbar>
    
    <div class="pt50 scroll-body">

      <div class="pt50">

          <div class="type-title" style="top: 35px;">
            <span :class="listType === 'coin' ? 'a' : ''" @click="typeClick('coin')">{{$t('withdrawal.gameAccount')}}</span>
            <span :class="listType === 'cash' ? 'a' : ''" @click="typeClick('cash')">{{$t('withdrawal.agentAmount')}}</span>
          </div>

          <div class="tabs-nav">
            <ul>
              <li v-for="(item) in queryLimitEnum" :key="item.code" :class="item.active ? 'active' : ''" @click="tabsQueryLimit(item)">{{ item.description }}</li>
            </ul>
          </div>

          <ListEmpty v-show="listNoData"></ListEmpty>
          
          <div class="csls" id="scroll" style="top: 140px;">
            <nut-infiniteloading
                containerId = 'scroll'
                :use-window='false'
                :has-more="hasNext"
                :load-txt='$t("global.loading")'
                :load-more-txt='$t("global.noMore")'
                :load-icon= 'null'
                @load-more="loadMore"
            >
              <div
                class="csls-item"
                v-for="(item, index) in list"
                :key="index"
              >
                <h4>
                  <span :class="[item.income ? 'fc-green' : 'fc-red']"
                    >{{ item.income ? "+" : "-" }} {{ $filters.currencySymbol(item.changeAmount) }}</span
                  ><span :class="[item.income ? 'tx-right-g' : 'tx-right-r']"
                    >{{ item.preAmount }} >> {{ item.postAmount }}</span
                  >
                </h4>
                <div class="bd">{{ item.name }}</div>
                <p>{{ item.createTime }}</p>
              </div>
            </nut-infiniteloading>
          </div>

      </div>
    </div>
  </div>

</template>

<script>
import { reactive, toRefs} from "vue";
import { useRouter } from "vue-router";
import axios from "axios";
import { Toast } from '@nutui/nutui';
import ListEmpty from "../components/ListEmpty.vue"

export default {
  components: {
    ListEmpty: ListEmpty,
  },
  data() {
    return {
      queryLimit:'today',
      queryLimitEnum:[
        {"code":"today","description": this.$t('global.Today'),"active":true},
        {"code":"one_week","description": this.$t('global.Week'),"active":false},
        {"code":"one_month","description": this.$t('global.Month1'),"active":false},
        {"code":"three_month","description": this.$t('global.Month3'),"active":false}],
      list: [],
      listType: 'coin',
      listNoData: true,
      current: 1,
      hasNext:'',
      systemNotification: false
    };
  },
  methods: {
    loadMore(done){
      if(this.hasNext){
        this.getCoinList(this.current + 1)
        setTimeout(() => {
          done()
        }, 500);
      }
    },
    tabsQueryLimit(li) {
      for(let k in this.queryLimitEnum){
        this.queryLimitEnum[k].active = false
      }
      li.active = true
      this.queryLimit = li.code
      this.list = []
      this.getCoinList(1);
    },
    typeClick(t){
      this.listType = t
      this.list = []
      this.getCoinList(1);
    },
    getCoinList(c) {
      this.textToast(this.$t('global.loading'))
      axios({
        url: "/api/fund/ledger/paginate",
        method: "get",
        params: { current: c, type: this.listType, queryLimit: this.queryLimit},
      }).then((res) => {
        for (let i = 0; i < res.data.data.records.length; i++) {
            this.list.push(res.data.data.records[i]);
        }
        this.current = res.data.data.current;
        this.hasNext = res.data.data.hasNext;
        if (this.list.length > 0) {
          this.listNoData = false;
        }else{
          this.listNoData = true;
        }
        Toast.hide("loading")
      });
    },
    textToast(msg) {
      Toast.loading(msg, {
        id:"loading",
        duration: 0,
        bgColor: "rgba(0, 0, 0, 0.9)",
        cover:true,
      });
    },
  },
  created() {
    if(this.$route.query.listType){
      this.listType = this.$route.query.listType;
    }
    this.getCoinList(1);
  },

  setup() {
    const state = reactive({
    });
    const router = useRouter();
    const methods = {
      backClick() {
        router.go(-1);
      },
    };
    return {
      ...toRefs(state),
      ...methods,
    };
  },
};
</script>

<style scoped>

.nut-pagination {margin-top: 10px;justify-content: center;}
.csls-item .right-btn {float: right;}
</style>