<template>
<div class="bp">
  <nut-navbar
    :left-show="false"
    title="Recruiting business partners"
    fixed=true
    class="mb0 navbar-red"
  >
    <template #left>
      <i class="nutui-iconfont nut-icon nut-icon-left" @click="backClick"></i>
    </template>
    <template #right>
      <i class="nutui-iconfont nut-icon nut-icon-horizontal" style="top: 0 !important;" @click="iconClick"></i>
    </template>
  </nut-navbar>
  

  <div class="agentList">
    <img class="img-w100" src="../assets/images/bn11b.jpg" />

    <div class="tx" v-if="alreadyOpened">
      <span>You already opened a question, please solve it first >></span>
    </div>

    <div class="formbox">
        <h3></h3>
        <nut-form>

          <nut-form-item label="How many registered users can you bring?">
            <nut-input v-model="formData.i1" placeholder="please input" type="text" />
          </nut-form-item>

          <nut-form-item label="Your advertising/promotion channels or platforms?">
            <nut-input v-model="formData.i2" placeholder="please input" type="text" />
            <img style="height: 40px;" src="../assets/images/global/bg401.jpg">
          </nut-form-item>

          <nut-form-item label="Your Telegram or Whatsapp contact information">
            <nut-input v-model="formData.i3" placeholder="please input" type="text" />
          </nut-form-item>

          <nut-form-item label="Please describe your advertising/promotion plan, method and platform in detail">
            <nut-input v-model="formData.i4" placeholder="please input" type="textarea"  show-word-limit  rows="3" max-length="200"/>
            <p style="margin:3px 0 0 0; font-size: 12px; line-height: 16px; ">Note:Please note that we will make a judgment based on the content you fill in. If the content you fill in is not valuable, you will not get a response.</p>
          </nut-form-item>

          <nut-form-item label="Please provide screenshots of the advertising/promotion data you have participated in" >
            <div class="file-main">
                <div class="file-box" @click="callFile('#file1')">
                  <img :src="uploadImgBase64_1" v-if="uploadImgBase64_1" >
                </div>
                <input type="file" id="file1" accept="image/*" @change="getPicture($event, 0)" style="display: none;" />

                <div class="file-box" @click="callFile('#file2')" v-if="uploadImgBase64_1">
                  <img :src="uploadImgBase64_2" v-if="uploadImgBase64_2" >
                </div>
                <input type="file" id="file2" accept="image/*" @change="getPicture($event, 1)" style="display: none;" />

                <div class="file-box" @click="callFile('#file3')" v-if="uploadImgBase64_2">
                  <img :src="uploadImgBase64_3" v-if="uploadImgBase64_3" >
                </div>
                <input type="file" id="file3" accept="image/*" @change="getPicture($event, 2)" style="display: none;" />

                <div class="lz"  v-if="formType === 'recharge1'" >
                    <img src="../assets/images/global/bg500.jpg" @click="showZoom = !showZoom" />
                </div>
            </div>
          </nut-form-item>
          
        </nut-form>
        <nut-button block type="info" :disabled="btnDisabled || alreadyOpened" @click="submitClick" style="margin: 0 0% 10px 0%; border-radius: 5px;">
          <span v-if="alreadyOpened">You already opened a question</span>
          <span v-else>Submit</span>
        </nut-button>

      </div>
      




  </div>

</div>
</template>

<script>
import { reactive,toRefs,onMounted } from 'vue';
import { useRouter } from "vue-router";
import axios from "axios";
import { Toast } from "@nutui/nutui";
import {isNotBlank, isBlank} from "@/assets/js/commonUtil";

export default {
  data(){
    return{
    }
  },
  created() {
  },
  setup() {
    const state = reactive({
      alreadyOpened: false,
      formType: 'business1', //feedback1
      formData:{
        i1:'',
        i2:'',
        i3:'',
        i4:'',
      },
      imgs: {
        img1: null,
        img2: null,
        img3: null,
      },
      content: '',
      btnDisabled: false,
      uploadComplete: false,
      showPreview: false,  //图片预览
      uploadImgUrl_1: '',
      uploadImgUrl_2: '',
      uploadImgUrl_3: '',
      uploadImgBase64_1: '',
      uploadImgBase64_2: '',
      uploadImgBase64_3: '',
    });
    const router = useRouter();
    const methods = {
      iconClick() {
        router.push({
          name: "complaintsSuggestions2",
        });
      },
      backClick() {
        router.go(-1);
      },
      textToast(msg) {
        Toast.loading(msg, {
          id: "loading",
          duration: 0,
          bgColor: "rgba(0, 0, 0, 0.9)",
          cover: true,
        });
      },
      
      //点击选择图片
      callFile(f){
          let fileDom = document.querySelector(f)
          fileDom.click()
      },
      //上传图片
      getPicture(e, n){
        var reader = new FileReader();
        switch (n) {
            case 0:
              reader.onload = (e) => {
                state.uploadImgBase64_1 = e.target.result
              }
              state.imgs.img1 = e.target.files[0]
              reader.readAsDataURL(e.target.files[0])
              break;
            case 1:
              reader.onload = (e) => {
                state.uploadImgBase64_2 = e.target.result
              }
              state.imgs.img2 = e.target.files[0]
              reader.readAsDataURL(e.target.files[0])
              break;
            case 2:
              reader.onload = (e) => {
                state.uploadImgBase64_3 = e.target.result
              }
              state.imgs.img3 = e.target.files[0]
              reader.readAsDataURL(e.target.files[0])
              break;
          }
      },
      //提交表单
      submitClick(){
        state.btnDisabled = true
        state.content = ''
        if(isBlank(state.formData.i1) || isBlank(state.formData.i2) || isBlank(state.formData.i3) || isBlank(state.formData.i4)){
          Toast.fail('Please fill in the form!');
          state.btnDisabled = false
          return
        }
        if(isBlank(state.imgs.img1) && isBlank(state.imgs.img2) && isBlank(state.imgs.img3)){
          Toast.fail('Upload at least one picture!');
          state.btnDisabled = false
          return
        }

        for (const key in state.formData) {
          let title = ''
          if(isNotBlank(state.formData[key])){
            switch (key) {
              case 'i1':
                title = '【Number of users:】'
                break;
              case 'i2':
                title = '【Promotion Platform:】'
                break;
              case 'i3':
                title = '【Contact information:】'
                break;
              case 'i4':
                title = '【Plan:】'
                break;
              default:
                title = key
                break;
            }
            state.content += title + ' : ' + state.formData[key] + '; '
          }
        }
        let _formData = new FormData();
        let _uploadImgForm = {
          content: state.content,
          type: state.formType,
        }
        for( var key in _uploadImgForm){
          _formData.append(key, _uploadImgForm[key])
        }
        if(isNotBlank(state.imgs.img1)){
          _formData.append('imgs',state.imgs.img1)
        }
        if(isNotBlank(state.imgs.img2)){
          _formData.append('imgs',state.imgs.img2)
        }
        if(isNotBlank(state.imgs.img3)){
          _formData.append('imgs',state.imgs.img3)
        }
        methods.textToast("Loading");
        axios({
          method: "post",
          url: "/api/system/ticket2/create",
          headers:{
            "Content-Type": "multipart/form-data"
          },
          withCredentials:true,
          data: _formData
        }).then((res) => {
          if (res.data.code === 0) {
              Toast.hide("loading");
              Toast.success("success!");
              setTimeout(() => {
                router.push("/complaintsSuggestions2");
              }, 1000);
            } else if (res.data.code === 1004) {
              Toast.hide("loading");
              let _res = res.data.data
              for(var i in _res){	
                switch (_res[i].fieldName) {
                  case "content":
                  Toast.fail(_res[i].fieldName + ' ' + _res[i].description);
                  break;
                }
              }
            }
          state.btnDisabled = false
          Toast.hide("loading");
        }).catch(function (error) {
          console.log(error);
          state.btnDisabled = false
          Toast.hide("loading");
        });
      },
    };
    onMounted(() => {
      methods.textToast("Loading");
      axios({
        url: "/api/system/ticket2/paginate",
          method: "get",
          params: { current: 1,  status: state.formType},
        }).then((res) => {
          if(res.data.data.records.length > 0){
            state.alreadyOpened = res.data.data.records[0].status === '1' || res.data.data.records[0].status === '2' || res.data.data.records[0].status === '3' 
          }
          Toast.hide("loading")
        });
    });

    return {
      ...toRefs(state),
      ...methods,
    };
  },
};
</script>

<style scoped>
.agentList-pro .item-t{ text-align: center; color: #1d1d1d;  height: 45px !important; line-height: 45px !important; border-radius: 0 !important;}
.agentList-item.agentList-pro .item-t h3{padding: 0px; margin: 0; line-height: 45px; padding-top: 0px;text-align: left; font-weight: 500;  padding-left: 34px; background: url(../assets/images/global_icon/202405-24.png) 5px center no-repeat; background-size: 25px auto; }
.agentList-item.agentList-pro .item-t h3 strong{  font-size: 20px; margin-left: 5px; color: #dc8000;float: right; margin-right: 20px;}
.agentList-item.agentList-pro .item-b{ display: flex; padding: 5px !important; margin: 0;}
.agentList-pro .item-b div{ width: 33%; text-align: center; border-right:1px solid #eee ;}
.agentList-pro .item-b div:last-child{ border-right:0px}
.agentList-pro .item-b div h4{ font-weight: 300; height: 40px; line-height: 16px;}
.agentList-pro .item-b div p strong{  font-size: 20px;color: #d1840a; font-weight: 300;}
.agentList {padding: 40px 10px; padding-bottom: 20px; background: #000000;min-height: 700px; margin-top: 6px;}
.agentList h2{ margin: 10px; line-height: 30px; color: #d8ae4d; text-align: center;}
.p-info{ padding: 0; line-height: 18px; color: #fff0b5; margin-top:0px; margin-bottom: 20px; text-align: center; }
.p-info strong{ font-size: 20px; }
.p-info span{ display: block;font-size:12px;background: #4a3910; border: 1px solid #7f621d; margin-top:10px; border-radius: 3px; color: #fff; line-height: 18px; padding: 3px 0;}
.img-w100{ width:100% ;}
.navbar-red, .navbar-red .nut-navbar{ background:linear-gradient(315deg, #110123 0%, #111a37 100%) !important;  box-shadow: 0 1px 7px #110123 !important;}
.agentList-item{ margin-bottom: 0px;}
.agentList-item .item-t{ height: 60px; border: 0px solid #b59e7e; background: #dce6e6; border-radius:10px; position: relative;box-shadow: 0px 3px 3px #ccc }
.agentList-item .item-t.lv1{background:#fff9e0 url(../assets/images/global/bg17-3.png) 5px center no-repeat; background-size:45px; }
.agentList-item .item-t h3{margin:0px 0 0 60px; font-weight: 300;padding-right: 67px; line-height: 26px; padding-top: 19px;}
.agentList-item .item-t span.r{ position: absolute; right: 10px; top:0; line-height: 60px;color: #e17800; font-size: 22px; font-weight: 600;}
.agentList-item .item-t span.l{ position: absolute; left: 13px; top:0; line-height: 68px; color: #ffee00; font-size: 16px;width: 30px;text-align: center;}
.agentList-item .item-b{ background: #fff;border-radius:0;  margin:-10px 5px 0 5px;box-shadow: 0 1px 7px #753c00; padding: 20px 10px 10px 10px; }
.agentList-item .item-b h4{ padding: 5px 0px; margin: 5px 0; line-height: 20px;}
.agentList-item .item-b h4 .r{ float:right; font-weight: 400; }
.agentList-item .item-b p{padding: 0; margin: 5px 0; line-height: 20px;}
.nut-popup.popup-top{background:#ffe26f !important}
.popup-top { overflow: hidden;}
.pop-top {
  background: url(../assets/images/global_icon/202405-60.png) center 10px no-repeat !important;
  background-size: 80px auto !important; height: 165px;}
.pop-top h4 {
  padding-left: 130px;
  padding-top: 25px;
  margin-bottom: 0;
  font-size: 18px;
}
.pop-top p {
  padding-left: 130px;
  padding-top: 0;
  margin: 0;
}
.pop-top p strong {
  font-size: 36px;
  color: #940e05;
  font-weight: 500;
}

.file-main{ display: flex;}
.file-box{ margin-right: 10px;}
.lz img{ width: 52px; height: auto;}
.lz-zoom{ position: absolute; left: 10px; top: 50px; right: 10px; z-index: 400;}
.lz-zoom img{ width: 100%; height: auto;}
.tx {padding:8px 5px 8px 30px; color: #ff4800; background: #fff2d2 url(../assets/images/global_icon/202405-25.png) 5px center no-repeat; background-size: 22px auto;  margin-bottom: -10px; border-radius: 3px; line-height: 16px;}

</style>

<style>
  .bp .nut-navbar__right{ top: 0 !important;}
</style>
