<template>
  <div class="play-rule">
    <h4><nut-icon name="tips" style="vertical-align: middle;margin-right: 5px;margin-bottom: 4px;"></nut-icon>{{$t('gameRules.h1')}}</h4>
    <dl>
      <dt>{{$t('gameRules.dt1')}}</dt>
      <dd>{{$t('gameRules.dd1_1')}}</dd>
      <dd>{{$t('gameRules.dd1_2')}}</dd>
      <dd>{{$t('gameRules.dd1_3')}}</dd>


      <dt>{{$t('gameRules.dt2')}}</dt>
      <dd>{{$t('gameRules.dd2_1')}}</dd>
      <dd><strong>{{$t('gameRules.dd2_2')}}</strong></dd>

      <dt>{{$t('gameRules.dt3')}}</dt>
      <dd>{{$t('gameRules.dd3')}}</dd>

      
      <dt>{{$t('gameRules.dt4')}}</dt>
      <dd>{{$t('gameRules.dd4')}}</dd>

      <!-- nut-button block type="info" @click="rulePopup = false">{{$t('gameRules.btn')}}</!-->
    </dl>

    

  </div> 
</template>

<script>

export default {
  name: 'GameRules',
  props: {},
  data() {
    return {
      imgUrl:''
    };
  },
  methods: {
  },
  created() {
    this.imgUrl = localStorage.getItem('countryImgUrl')
  },
  setup() {}
};
</script>