<template>
  <div class="transactionsHistoryList">
    <nut-navbar
    :left-show="false"
      title="Refer And Earn Money"
      fixed=true
      class="mb0 nav-icon-right nut-navbar--fixed"
    >
    <template #left>
      <i class="nutui-iconfont nut-icon nut-icon-left" @click="backClick"></i>
    </template>
    </nut-navbar>
    <div class="pt50 scroll-body activity150">
      <div class="p20" style="padding:10px 5px !important">

        <ListEmpty v-show="listNoData"></ListEmpty>

          <div class="csls" id="scroll">
            <nut-infiniteloading
                containerId = 'scroll'
                :use-window='false'
                :has-more="hasNext"
                :load-txt='$t("global.loading")'
                :load-more-txt='$t("global.noMore")'
                :load-icon= 'null'
                @load-more="loadMore"
            >
              <div class="csls-item csls-item-150" v-for="(item, index) in list" :key="index"  v-show="item.type !== 'second_repay'">
                <h4>
                  <strong>{{ $filters.currencySymbol(item.amount) }}</strong>
                  <span v-if="item.type === 'first_first_recharge'" class="rd1" >Frist deposit rebate</span>
                  <!-- <span v-else-if="item.type === 'second_repay'" class="rd2">2nd</span> -->
                  <span v-else-if="item.type === 'third_total_recharge'" class="rd3">Maga deposit rebate</span>
                  <p>Reward received</p>
                </h4>
                <p>{{ item.createTime }}</p>
              </div>


            </nut-infiniteloading>


          </div>
          <!-- type

              amount -->

              

      </div>
    </div>

  </div>




</template>

<script>
import { reactive, toRefs} from "vue";
import { useRouter } from "vue-router";
import axios from "axios";
import { Toast } from '@nutui/nutui';
import ListEmpty from "../components/ListEmpty.vue"

export default {
  components: {
    ListEmpty: ListEmpty,
  },
  data() {
    return {
      list: [],
      listNoData: true,
      current: 1,
      hasNext:'',
    };
  },
  methods: {
    loadMore(done){
      if(this.hasNext){
        this.getCoinList(this.current + 1)
        setTimeout(() => {
          done()
        }, 500);
      }
    },
    getCoinList(c) {
      this.textToast(this.$t('global.loading'))
      axios({
        url: "/api/activity/150/paginate",
        method: "get",
        params: { current: c },
      }).then((res) => {
        for (let i = 0; i < res.data.data.records.length; i++) {
            this.list.push(res.data.data.records[i]);
        }
        this.current = res.data.data.current;
        this.hasNext = res.data.data.hasNext;
        if (this.list.length > 0) {
          this.listNoData = false;
        }else{
          this.listNoData = true;
        }
        Toast.hide("loading")
      });
    },
    textToast(msg) {
      Toast.loading(msg, {
        id:"loading",
        duration: 0,
        bgColor: "rgba(0, 0, 0, 0.9)",
        cover:true,
      });
    },

  },
  created() {
    this.getCoinList(1);
  },

  setup() {
    const state = reactive({
    });
    const router = useRouter();
    const methods = {
      backClick() {
        router.go(-1);
      },
    };
    return {
      ...toRefs(state),
      ...methods,
    };
  },
};
</script>

<style scoped>
.scroll-body{
  position: fixed;
  top:0;
  left: 0;
  right:0;
  bottom: 0;
  overflow: auto
}
.nut-pagination {
  margin-top: 10px;
  justify-content: center;
}
.csls{
  overflow-y: auto;
  overflow-x: hidden;
  position: absolute;
  left: 10px;right: 10px;top:70px; bottom: 10px;
}
.csls-item {
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #eee;
}
.csls-item h4 {
  margin: 5px 0;
  line-height: 24px;
}

.csls-item p {
  line-height: 24px;
  padding: 0;
  margin: 0;
  color: #999;
}
.csls-item .tx-right-r {
  float: right;
  line-height: 22px;
  background: #fff3f3;
  color: #cf0505;
  border-radius: 15px;
  font-size: 10px;
  padding: 0px 10px;
}
.csls-item .tx-right-g {
  float: right;
  line-height: 22px;
  background: #f1ffe8;
  color: #5c9834;
  border-radius: 15px;
  font-size: 10px;
  padding: 0px 10px;
}
.csls-item h4 .tx-right-r,
.csls-item h4 .tx-right-g {
  font-weight: 300;
}
.csls-answer {
  background: #f1ffe8;
  padding: 10px;
  color: #5c9834;
  margin-top: 10px;
  border-radius: 0 15px 15px 15px;
  border: 1px solid #d2e8c3;
}
.csls-item .right-btn {
  float: right;
}
.type-title{ padding: 0 0 10px 0; text-align: center;}
.type-title span{ margin: 0 10px; line-height: 30px; height: 30px;  border: 1px solid #0c3901; color: #0c3901; border-radius:5px; padding:0 10px; display: inline-block;}
.type-title span.a{color: #fff; background: #0c3901;}

.csls-item-150{ border: 1px solid #dce4d6; background: #fcfffa; padding: 10px 20px; border-radius: 5px;}
.csls-item-150 h4{ line-height: 26px; font-weight: 300;}
.csls-item-150 strong{ font-size: 18px; color: #5c9834; margin-right: 10px;}
.csls-item-150 span{ background: #5c9834; color: #fff; padding: 0px; display: inline-block; text-align: center;
   width: 180px; height: 26px; border-radius: 30px; margin-right: 10px;font-weight: 600;}
   .csls-item-150 span.rd1{ background: #5f3498;}
   .csls-item-150 span.rd2{ background: #30926e;}
   .csls-item-150 span.rd3{ background: #30926e;}
.csls-item-150 h4 p{ padding: 0;margin: 0; line-height: 24px; color: #433c5f;}
</style>

<style>
.nav-icon-right .nut-navbar__title .nut-icon {
  position: absolute !important;
  right: 10px !important;
}
.activity150 .empty-text{ display:none !important; color: #fff !important;}
</style>