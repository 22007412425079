//国际化
import { createI18n } from 'vue-i18n'
import { getDefaultLanguage } from "@/assets/js/environment";
const language = getDefaultLanguage()
const i18n = createI18n({
  locale: language,		//默认
    messages: {
      in_EN:require('./en/in_EN.js'),
    }
  })
  export default i18n
