import {isBlank} from "@/assets/js/commonUtil";

export function getCommonInfo() {
    let commonInfoStr = window.localStorage.getItem("commonInfo");
    if (isBlank(commonInfoStr)) {
        return null;
    }
    return JSON.parse(commonInfoStr);
}

export function getCommonInfoAttr(key) {
    let commonInfoStr = window.localStorage.getItem("commonInfo");
    if (isBlank(commonInfoStr)) {
        return null;
    }
    let commonInfo = JSON.parse(commonInfoStr);
    return commonInfo[key];
}
