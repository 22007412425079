<template>
  <div>
    <nut-navbar
    :left-show="false"
      :title="$t('invite.historyBonus')"
      fixed=true
      class="mb0 nav-icon-right nut-navbar--fixed"
    >
    <template #left>
      <i class="nutui-iconfont nut-icon nut-icon-left" @click="backClick"></i>
    </template>
    </nut-navbar>

      <div class="bonusList-bd">
          <div class="bonusList-top" id="chartLine"  ref="box" style=" width: 100%;" ></div>


          <div class="bonusList-top" id="chartLine"  ref="box2" style=" width: 100%; margin-top: -40px; border-top: 2px solid #000;" ></div>


          <div class="bonusList-top" id="chartLine"  ref="box3" style=" width: 100%;  margin-top: -40px; border-top: 2px solid #000;" ></div>

          <div class="bonusList" >
            <table>
              <tr>
                <th>Date</th>
                <th>Income</th>
                <th>Invite Count</th>
                <th>Effective Count</th>
              </tr>
              <tr v-for="(item, index) in list" :key="index" >
                <td class="ta-c">{{ dateListBack[index] }}</td>
                <td class="fc-y">{{ $filters.currencySymbol(amountListBack[index]) }}</td>
                <td>{{ inviteCountListBack[index] }}</td>
                <td>{{ effectiveCountListBack[index] }}</td>
                
              </tr>
            </table>
          </div>

      </div>


  </div>
</template>

<script>
import { reactive, toRefs} from "vue";
import { useRouter } from "vue-router";
import axios from "axios";
import { Toast } from '@nutui/nutui';
import * as echarts from 'echarts';
require('echarts/theme/shine');

export default {
  data() {
    return {
      list: null,
      dateList: [],
      amountList: [],
      inviteCountList: [],
      effectiveCountList: [],

      dateListBack: [],
      amountListBack: [],
      inviteCountListBack: [],
      effectiveCountListBack: [],

      chartLine: null,
      chartLine2: null,
      chartLine3: null
    };
  },
  created() {
    this.getList();
    this.textToast(this.$t('global.loading'))
  },
  methods: {
    getList() {
      this.textToast(this.$t('global.loading'))
      axios({
        url: "/api/invite/passed_days_stats",
        method: "get",
        params: { 
          QLKaubYI7S: null,
        }
      }).then((res) => {
        this.list = res.data.data.statsList

        for (const key in res.data.data.statsList) {
          this.dateList.push((res.data.data.statsList[key].date).substring((res.data.data.statsList[key].date).length -2,(res.data.data.statsList[key].date).length))
          this.amountList.push(res.data.data.statsList[key].cashIncomeAmount)
          this.inviteCountList.push(res.data.data.statsList[key].inviteCount)
          this.effectiveCountList.push(res.data.data.statsList[key].effectiveCount)

          this.dateListBack.unshift((res.data.data.statsList[key].date).substring((res.data.data.statsList[key].date).length -2,(res.data.data.statsList[key].date).length) + '/' + (res.data.data.statsList[key].date).substring((res.data.data.statsList[key].date).length -5,(res.data.data.statsList[key].date).length -3))
          this.amountListBack.unshift(res.data.data.statsList[key].cashIncomeAmount)
          this.inviteCountListBack.unshift(res.data.data.statsList[key].inviteCount)
          this.effectiveCountListBack.unshift(res.data.data.statsList[key].effectiveCount)
        } 
        this.drawLineChart();
        Toast.hide("loading")
      }).catch(function (error) {
          console.log(error);
          Toast.hide("loading");
      })
    },
    textToast(msg) {
      Toast.loading(msg, {
        id:"loading",
        duration: 0,
        bgColor: "rgba(0, 0, 0, 0.9)",
        cover:true,
      });
    },
    drawLineChart() {
        this.chartLine = echarts.init(this.$refs.box,'shine',{width: 'auto'});
        let option = {
            tooltip : {
                trigger: 'axis'
            },
            legend: {
                data:[{
                  name:'Income',
                  textStyle:{
                    color:'#6d8ae4'
                  }
              }]
            },
            calculable : true,
            xAxis : [
                {
                    type : 'category',
                    boundaryGap : false,
                    axisTick: {
                        show: false
                    },
                    data : this.dateList
                }
            ],
            yAxis : [
                {
                    type : 'value',
                    axisTick: {
                        show: false
                    },
                    name: '(INR)'
                }
            ],
            series : [
                {
                    name:'Income',
                    type:'line',
                    stack: ' ',
                    data: this.amountList
                }
            ]
        };
        this.chartLine.setOption(option);



        this.chartLine2 = echarts.init(this.$refs.box2,'shine',{width: 'auto'});
        let option2 = {
            tooltip : {
                trigger: 'axis'
            },
            legend: {
                data:[{
                  name:'invite Count',
                  textStyle:{
                    color:'#a1e181'
                  }
                }]
            },
            calculable : true,
            xAxis : [
                {
                    type : 'category',
                    boundaryGap : false,
                    axisTick: {
                        show: false
                    },
                    data : this.dateList
                }
            ],
            yAxis : [
                {
                    type : 'value',
                    axisTick: {
                        show: false
                    },
                    name: ''
                }
            ],
            series : [
                {
                    name:'invite Count',
                    type:'line',
                    stack: ' ',
                    lineStyle:{
                      normal:{
                        color:'#a1e181'
                      }
                    },
                    data: this.inviteCountList
                }
            ]
        };
        this.chartLine2.setOption(option2);



        this.chartLine3 = echarts.init(this.$refs.box3,'shine',{width: 'auto'});
        let option3 = {
            tooltip : {
                trigger: 'axis'
            },
            legend: {
                data:[{
                  name:'Effective Count',
                  textStyle:{
                    color:'#ff6a00'
                  }
                }]
            },
            calculable : true,
            xAxis : [
                {
                    type : 'category',
                    boundaryGap : false,
                    axisTick: {
                        show: false
                    },
                    data : this.dateList
                }
            ],
            yAxis : [
                {
                    type : 'value',
                    axisTick: {
                        show: false
                    },
                    name: ''
                }
            ],
            series : [
                {
                    name:'Effective Count',
                    type:'line',
                    stack: ' ',
                    lineStyle:{
                      normal:{
                        color:'#ff6a00'
                      }
                    },
                    data: this.effectiveCountList
                }
            ]
        };
        this.chartLine3.setOption(option3);


    }
  },
  setup() {
    const state = reactive({
    });
    const router = useRouter();
    const methods = {
      backClick() {
        router.go(-1);
      },
    };
    return {
      ...toRefs(state),
      ...methods,
    };
  },
};
</script>

<style scoped>
.csls-item-parity{margin-top: 10px;border: 1px solid #ccc;border-radius:5px;overflow: hidden;background: #fff; }
.csls-item-parity .hd{display: flex; line-height: 30px;justify-content:space-between; background: #f5f5f5; padding: 0 10px; color: #504f3c;font-size: 10px;}
.csls-item-parity .bd{ text-align: center; display: flex;line-height: 20px;justify-content:space-between;padding: 10px;}
.csls-item-parity .bd ul{ width: 50% calc(100% - 90px); text-align: left;font-size: 12px; line-height: 25px;}
.csls-item-parity .bd ul span{ width: 20px; height: 20px; line-height: 20px; background: #eee; color: #666; 
  margin-right: 5px; border-radius:10px; display: inline-block; text-align: center;}
  .csls-item-parity .bd ul span.a{background: #40713b; color: #fff; }
  .csls-item-parity .bd ul strong{ color: #40713b; font-size: 16px;}
.csls-item-parity .bd ol{ width: 110px; display: flex;flex-wrap: wrap; justify-content:center;}
.csls-item-parity .bd ol li{ height: 20px; width: 20px; padding: 0; margin: 1px; background: #eee; overflow: hidden; position: relative;}
.csls-item-parity .bd ol li div.c{ display: block;background: #c5b982; width: 100%; height: 100%;}
.csls-item-parity .bd ol li div.b{background: url(../assets/images/game_mine/bg130.png) 0 0 no-repeat; background-size:100% auto; width: 100%; height: 100%;}
.csls-item-parity .bd ol.x2 li{height: 50px; width: 50px; }
.csls-item-parity .bd ol.x4 li{height: 24px; width: 24px; }
.csls-item-parity .bd ol.x6 li{height: 16px; width: 16px; }
.csls-item-parity .bd span{ font-size: 12px;}
.csls-item-parity .fd-w{ line-height: 30px;border: 1px solid #c9e5c7;}
.csls-item-parity .fd-l{line-height: 30px; border: 1px solid #eee;}
.csls-item-parity .fd-l strong, .csls-item-parity .fd-w strong{ width: 100%; text-align: center; background: #eee; color: #666; 
    line-height: 30px; display: inline-block; padding: 0 10px;}
.csls-item-parity .fd-w strong{background: #c9e5c7; color: #40713b;}
</style>
