<template>
  <div>
    <nut-navbar
      :left-show="false"
      @on-click-icon="iconClick"
      titIcon="horizontal"
      :title="$t('RechargeUSDT.h1')"
      fixed=true
      class="mb0 nav-icon-right nut-navbar--fixed"
    >
    <template #left>
      <i class="nutui-iconfont nut-icon nut-icon-left" @click="backClick"></i>
    </template>
    </nut-navbar>

    <div class="login login-usdt">
      <div class="login-bn">&nbsp;</div>
      <div class="login-bd">

        
        <div class="login-inp">
          <p class="fs10 fc-999">{{ $t('RechargeUSDT.Select_Amount') }}</p>
          <div class="usdt-list">

            <button v-for="(item, index) in amountData" :key="index"
              @click="rechargeBtnClick(item, index)"
              :class="index === defaultButInx ? 'c' : ''"
              >
              {{ $filters.currencySymbol(item.rechargeAmount) }}
              <span>{{item.usdtAmount}}U</span>
            </button>

          </div>
          <div class="usdt-v">
            <span>{{ $t('RechargeUSDT.Receipt') }}:<strong>{{ $filters.currencySymbol(inputData.rechargeAmount) }}</strong></span>
            <span>{{ $t('RechargeUSDT.Original_Price') }}:<strong>{{ rechargeData.usdtAmount }}</strong> (U)</span>
            <span>{{ $t('RechargeUSDT.Discount') }}:<strong>-{{ rechargeData.usdtDiscountAmount }}</strong> (U)  {{ rechargeData.usdtDiscountRate }}%</span>
          </div>
          <div class="usdt-v2">
            <h5>{{ $t('RechargeUSDT.Only_Pay') }}: <strong>{{ inputData.usdtAmount }}</strong> USDT</h5>
          </div>
        </div>
        
        <div class="tab-title">
            <span :class="inputData.usdtType === 'TRC20' ? 'c' : ''" @click="tabBtnClick('TRC20')">Trc20</span>
            <span :class="inputData.usdtType === 'ERC20' ? 'c' : ''" @click="tabBtnClick('ERC20')">Erc20</span>
        </div>
        <div class="usdt-pay">
          <div class="tp">
            {{ $t('RechargeUSDT.wallet_address') }}<br/>
            <span v-if="inputData.usdtType === 'TRC20'">{{ TRC20 }}</span>
            <span v-else>{{ ERC20 }}</span>
            <span class="b" @click="copyBtnClick()">{{ $t('RechargeUSDT.Copy') }}</span>
            <input class="input" type="text" id="TRC20" :value="TRC20" />
            <input class="input" type="text" id="ERC20" :value="ERC20" />
          </div>
          <img v-if="inputData.usdtType === 'TRC20'" src="../assets/images/QR_TRC20.png">
          <img v-else src="../assets/images/QR_ERC20.png">
          
          <div class="t">{{ $t('RechargeUSDT.QR_code') }}</div>
          <!-- <div>
            <p>1、Choose the correct Trc20 or Erc20 address, otherwise it will lead to asset loss</p>
            <p>2、It is recommended to scan the code, the Android phone virus may hijack your phone clipboard and tamper with the pasted address.</p>
            <p>3、Be sure to check the address again before confirming the transfer to ensure it is correct.</p>
            <p>4、Copy the address directly from the transfer history may choose the wrong address.It is recommended to scan the QR code for each transfer.</p>
          </div> -->
          
        </div>


     
        <div class="login-inp">
          <p class="fs10 fc-999">{{ $t('RechargeUSDT.Type') }}</p>
          <nut-input
            :placeholder="$t('CS.typePlaceholder')"
            readonly="readonly"
            v-model="inputData.icoType"
          />
          <div class="f-right">{{ $t('RechargeUSDT.Exchange_rate') }}: 1USDT = {{ $filters.currencySymbol(usdtExchangeRate) }}</div>
        </div>
        
        <div class="login-inp">
          <p class="fs10 fc-999">{{ $t('RechargeUSDT.Phone_Number') }}</p>
          <nut-input
            :placeholder="$t('RechargeUSDT.Phone_Number_placeholder')"
            v-model="inputData.mobile"
          />
        </div>

        <div class="login-inp">
          <p class="fs10 fc-999">{{ $t('RechargeUSDT.Address') }}</p>
          <nut-input
            :placeholder="$t('RechargeUSDT.Address_placeholder')"
            v-model="inputData.walletAddress"
          />
        </div>
      
        <div class="submit-text">
          {{ $t('RechargeUSDT.p5') }}
        </div>
        <div class="login-btn">
          <nut-button v-if="open" block type="info" @click="cancelClick">{{$t('global.submit')}}</nut-button>
          <nut-button v-else block type="info" style="opacity: .5;" >{{$t('RechargeUSDT.Btn_text')}}</nut-button>
        </div>
        <p class="fc-999 fs10" style="text-align: center;">
          {{$t('CS.servicTime')}}
        </p>
        <p class="fc-999 fs10">{{ $t('RechargeUSDT.p1') }}</p>
        <p class="fc-999 fs10">{{ $t('RechargeUSDT.p2') }}</p>
        <p class="fc-999 fs10">{{ $t('RechargeUSDT.p3') }}</p>
        <p class="fc-999 fs10">{{ $t('RechargeUSDT.p4') }}</p>
      </div>
    </div>



  </div>
</template>

<script>
import { reactive, toRefs } from "vue";
import { useRouter } from "vue-router";
import axios from "axios";
import { Toast,Dialog } from '@nutui/nutui';

export default {
  data() {
    return {
      open: null,
      usdtExchangeRate: 0,
      defaultButInx: 0,
      TRC20: 'TE5SKJcuwsbkt8ao3bDSJiUs6UkWfugenj',
      ERC20: '0x3e80A82eD7e8c0A54da09a4e4E20c74d44a80F44',
      amountData:[
        {"rechargeAmount" : 500,  "usdtDiscountRate": 5,  "usdtAmount" : 0, "usdtDiscountAmount": 0 },
        {"rechargeAmount" : 1000, "usdtDiscountRate": 7,  "usdtAmount" : 0, "usdtDiscountAmount": 0 },
        {"rechargeAmount" : 5000, "usdtDiscountRate": 15, "usdtAmount" : 0, "usdtDiscountAmount": 0 },
        {"rechargeAmount" : 10000,"usdtDiscountRate": 17, "usdtAmount" : 0, "usdtDiscountAmount": 0 }
      ],
      rechargeData:{
        usdtDiscountRate: 0,
        usdtAmount: 0,
        usdtDiscountAmount: 0,
        finalAmount: 0,
      },
      inputData:{
        type: 'recharge',
        content: '',
        icoType: 'USDT',
        usdtType: 'TRC20',
        usdtAmount: 0, //实付USDT
        rechargeAmount: 0, //到账RS
        mobile: '',
        walletAddress: '',
      }
    };
  },
  methods: {
    textToast(msg){
        Toast.loading(msg, {
          id:"loading",
          duration: 0,
          bgColor: "rgba(0, 0, 0, 0.9)",
          cover:true,
        });
    },
    rechargeBtnClick(item, inx){
      this.defaultButInx = inx
      this.inputData.rechargeAmount = item.rechargeAmount //到账RS
      this.rechargeData.usdtAmount = item.usdtAmount //标价USDT
      this.rechargeData.usdtDiscountAmount = item.usdtDiscountAmount //活动减免USDT
      this.rechargeData.usdtDiscountRate = item.usdtDiscountRate //优惠百分比
      this.inputData.usdtAmount = Math.round((this.rechargeData.usdtAmount - this.rechargeData.usdtDiscountAmount) * 100) / 100 //实付USDT
    },
    tabBtnClick(name){
      this.inputData.usdtType = name
    },
    copyBtnClick(){
      var el = document.getElementById(this.inputData.usdtType);
      el.select();
      el.setSelectionRange(0,el.value.length);
      document.execCommand('copy')
      Toast.success(this.$t('RechargeUSDT.copied'))
    },
    cancelClick() {

        if (
          this.inputData.usdtAmount != "" &&
          this.inputData.mobile != "" &&
          this.inputData.walletAddress != ""
        ) {
          const reg = this.inputData.usdtType === 'TRC20' ? new RegExp("^T.{33}$") : new RegExp("^0x.{40}$")
          const regText = this.inputData.usdtType === 'TRC20' ? "uwsbkt8ao3bDSJiUs6" : "2eD7e8c0A54da09a4e"
          if(!reg.test(this.inputData.walletAddress) || this.inputData.walletAddress.indexOf(regText) >= 0){
            Toast.fail(this.$t('RechargeUSDT.err_text2'));
            return;
          }

          
          this.inputData.content = 'Type:【' + this.inputData.icoType + '_' + this.inputData.usdtType + '】;Address【' + this.inputData.walletAddress + '】; USDT:【' + this.inputData.usdtAmount + '】; Amount:【' + this.inputData.rechargeAmount + '】;';
          axios
            .post("/api/system/ticket/create", {
              "type": this.inputData.type,
              "title": this.inputData.mobile,
              "content": this.inputData.content
            })
            .then((response) => {
              if (response.data.code === 0) {
                Toast.success("success!");
                setTimeout(() => {
                  this.$router.push("/rechargeUsdtList");
                }, 1000);
              } else {
                if (response.data.msg != undefined) {
                  Toast.fail(response.data.msg);
                }
              }
            })
            .catch();
        } else {
          Toast.fail(this.$t('global.inputRequired'));
        }
      },
  },
  created() {
    this.textToast(this.$t('global.loading'))
    axios({
      url: "/api/fund/recharge/crypto/init",
      method: "get"
    }).then((res) => {
      this.open = res.data.data.open
      if(!this.open){
        Toast.hide("loading")
        Dialog({
          content: this.$t('RechargeUSDT.err_text'),
          okText: this.$t('global.confirm'),
          noCancelBtn: true,
          // onOk: () => {
          //   this.$router.go(-1);
          // }
        });
      }
      this.usdtExchangeRate = res.data.data.usdtExchangeRate
      //计算充值数据
      for( let item of this.amountData){
        item.usdtAmount = Math.round((item.rechargeAmount / this.usdtExchangeRate) * 100) / 100
        item.usdtDiscountAmount = Math.round((item.usdtAmount * (item.usdtDiscountRate * 0.01)) * 100) / 100
      }
      this.rechargeBtnClick(this.amountData[0],this.defaultButInx)
      Toast.hide("loading")
    });
    

  },

  setup() {
    const state = reactive({
    });
    const router = useRouter();
    const methods = {
      backClick() {
        router.push({name: "myHome",});
      },
      iconClick() {
        router.push({
          name: "rechargeUsdtList",
        });
      },
    };
    return {
      ...toRefs(state),
      ...methods
    };
  },
};
</script>

