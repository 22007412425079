<template>
  <div class="pop-vip">
    <div class="pophd">
      <strong v-if="popTitle">{{popTitle}}</strong>
      <strong v-else>VIP</strong>
    </div>
    <div class="popbd">
      
      <div class="table-tab">
        <span :class="w_privilege ? 'a' : ''" @click="tabClick('w_privilege')">{{$t('VIP_PRIVILEGE.WITHDRAWAL')}}</span>
        <span :class="c_privilege ? 'a' : ''" @click="tabClick('c_privilege')">Crash</span>
        <span :class="p_privilege ? 'a' : ''" @click="tabClick('p_privilege')">Parity</span>
        <span :class="a_privilege ? 'a' : ''" @click="tabClick('a_privilege')">A&B</span>
        <span :class="wh_privilege ? 'a' : ''" @click="tabClick('wh_privilege')">Wheel</span>
        <span :class="m_privilege ? 'a' : ''" @click="tabClick('m_privilege')">Mine</span>
        <span :class="d_privilege ? 'a' : ''" @click="tabClick('d_privilege')">Dice</span>
         
      </div>
      <table class="table-th">
        <tr>
          <th>{{$t('VIP_PRIVILEGE.LEVEL')}}</th>
          <th v-show="w_privilege">{{$t('VIP_PRIVILEGE.TOTAL_RECHARGE')}}</th>
          <th v-show="w_privilege">{{$t('VIP_PRIVILEGE.WITHDRAWAL_QUOTA')}}</th>
          <th v-show="w_privilege">{{$t('VIP_PRIVILEGE.DAILY_BONUS')}}</th>
          <th v-show="c_privilege">&#128640; {{$t('VIP_PRIVILEGE.STOP_LIMIT')}}</th>
          <th v-show="c_privilege">&#128718; {{$t('VIP_PRIVILEGE.INPUT_LIMIT')}}</th>
          <th v-show="p_privilege">{{$t('VIP_PRIVILEGE.DATA_ANALYSIS')}}</th>
          <th v-show="p_privilege">&#127914; {{$t('VIP_PRIVILEGE.ROOM_LIMIT')}}</th>
          <th v-show="a_privilege || wh_privilege || m_privilege || d_privilege">&#128176; {{$t('VIP_PRIVILEGE.INPUT_LIMIT')}}</th>
          <th v-show="a_privilege || d_privilege">&#127374; Gameplay</th>
        </tr>
        <tr v-for="(item, index) in vipRules" :key="index" :class="myLevel === index ? 'mylevel' : ''">
          <td v-show="item.level !== 0">VIP{{item.level}}</td>
          <td v-show="w_privilege && item.level !== 0">
            {{ $filters.currencySymbol(item.requireRechargeAmount) }}
            <!-- <span v-show="(item.level === 4)">(+{{registerReward}} &#10004;)</span> -->
          </td>
          <td v-show="w_privilege && item.level !== 0">
            <span v-if="(item.level === 0)">1</span>
            <span v-else-if="(item.level === 1)">1</span>
            <span v-else-if="(item.level === 2)">1</span>
            <span v-else-if="(item.level === 3)">2</span>
            <span v-else>3</span>
          </td>
          <td v-show="w_privilege && item.level !== 0">&#127873; +{{item.extraDailySignRewardRatio}}%</td>

          <td v-show="c_privilege && item.level !== 0">{{$t('VIP_PRIVILEGE.MAX')}} {{item.crashMaxRatio}}x</td>
          <td v-show="c_privilege && item.level !== 0">{{$t('VIP_PRIVILEGE.MAX')}} &#128176;{{item.maxBetAmount}}</td>
          <td v-show="p_privilege && item.level !== 0">
            <span v-if="index === 0">{{$t('VIP_PRIVILEGE.RECORD')}}</span>
            <span v-else-if="index === 1">{{$t('VIP_PRIVILEGE.CONTINUOUS')}}</span>
            <span v-else-if="index === 2">{{$t('VIP_PRIVILEGE.PROBABILITY')}}</span>
            <span v-else>--</span>
          </td>
          <td v-show="p_privilege && item.level !== 0">
            <span v-if="index === 0">&#9889;Fast</span>
            <span v-else-if="index === 1">&#128161;Prediction</span>
            <span v-else>--</span>
          </td>

          <td v-show="(a_privilege || wh_privilege || m_privilege || d_privilege) && item.level !== 0">
            <span v-if="index === 0">Max 50</span>
            <span v-else>Max 10000</span>
          </td>

          <td v-show="a_privilege && item.level !== 0">
            <span v-if="index === 0">{{ $t('andarBahar.value') }} & Andar Bahar</span>
            <span v-else-if="index === 1">{{ $t('andarBahar.SuitDeal') }}</span>
            <span v-else>{{$t('game.all')}}</span>
          </td>

          <td v-show="d_privilege && item.level !== 0">
            <span v-if="index === 0">Big&Small</span>
            <span v-else-if="index === 1">Big&Small & Numbers</span>
            <span v-else>{{$t('game.all')}}</span>
          </td>

        </tr>
      </table>
      <!-- <p v-show="upgradeVip === 'show'" style="margin: 0 5px; color:#f0f0bf !important;font-size: 12px; font-weight: 400;">{{$t('VIP_PRIVILEGE.TEXT3')}}</p> -->
    </div>
  </div> 
</template>

<script>
import { reactive, toRefs, onMounted } from "vue";
import axios from "axios";
import { getCountry } from "@/assets/js/environment";

export default {
  name: 'VipRules',
  props: {
    myLevel:{
      type: Number,
      default : 0
    },
    popTitle:{
      type: String,
      default : ''
    },
    upgradeVip:{
      type: String,
      default : ''
    },
    tabActive:{
      type: String,
      default : ''
    },
  },
  watch:{
    tabActive: function(newData){
      if(newData === 'parity'){
        this.w_privilege = false
        this.c_privilege = false
        this.p_privilege = true
        this.a_privilege = false
      } else if(newData === 'crash') {
        this.w_privilege = false
        this.c_privilege = true
        this.p_privilege = false
        this.a_privilege = false
      } else if(newData === 'andar') {
        this.w_privilege = false
        this.c_privilege = false
        this.p_privilege = false
        this.a_privilege = true
      }
    }
  }, 
  data() {
    return {
      country: '',
      s:'s1',
      w_privilege: true,
      c_privilege: false,
      p_privilege: false,
      a_privilege: false,
      wh_privilege: false,
      m_privilege: false,
      d_privilege: false
    };
  },
  methods: {
    tabClick(t){
      this.w_privilege = false
      this.c_privilege = false
      this.p_privilege = false
      this.a_privilege = false
      this.wh_privilege = false
      this.m_privilege = false
      this.d_privilege = false
      switch (t) {
        case 'w_privilege':
          this.w_privilege = true
          break;
        case 'c_privilege':
          this.c_privilege = true
          break;
        case 'p_privilege':
          this.p_privilege = true
          break;
        case 'a_privilege':
          this.a_privilege = true
          break;
        case 'wh_privilege':
          this.wh_privilege = true
          break;
        case 'm_privilege':
          this.m_privilege = true
          break;
        case 'd_privilege':
          this.d_privilege = true
          break;
      }
    }
  },  
  created() {
    this.country = getCountry()
    this.s = document.querySelector('body').getAttribute('s')
  },
  setup() {
    const state = reactive({
      vipRules:'',
      registerReward:''
    });
    onMounted(() => {
      if (window.localStorage.getItem("commonInfo")) {
        let commonInfo = JSON.parse(window.localStorage.getItem("commonInfo"));
        state.vipRules = commonInfo.vipRules;
        state.registerReward = commonInfo.registerReward;
      } else {
        axios.get("/api/system/common", {}).then((response) => {
          state.vipRules = response.data.data.vipRules;
          state.registerReward = response.data.data.registerReward;
        });
      }
    });
    return {
      ...toRefs(state)
    };
  }

};
</script>

<style scoped>
</style>
