<template>
  <div>
    <nut-navbar
      :left-show="false"
      :title="$t('taskReward.h1')"
      fixed=true
      class="mb0 nav-icon-right nut-navbar--fixed"
    >

    <!-- <template #left>
      <img src="../assets/images/global_icon/202405-65.png" @click="contactUsClick" style="width: 30px;">
    </template> -->

    </nut-navbar>

    <div class="signin-task" v-if="dailyGrowthList.length > 0">
      <img :src="require('../assets/images/global/bg20.jpg')" />

      

      <div class="bd">
        <div class="dayls">
          <div class="d"  
            :class="{
              'complete': item.day < dailyGrowthCurrent.day,
              'active': item.day === dailyGrowthCurrent.day,
            }"
            v-for="(item, index) in dailyGrowthList" :key="index" :title="item.title" :conditionType="item.conditionType">
            <h5>{{$t('taskReward.day')}} {{item.day}}</h5>
            <p v-show="item.day <= dailyGrowthCurrent.day">{{ $filters.currencySymbol(item.rewardAmount) }}</p>
            <p v-show="item.day > dailyGrowthCurrent.day">?</p>
          </div>
        </div>

        <div class="btn">
          <nut-button v-if="dailyGrowthCurrent.status==='locked'" block type="info" @click="dailyGrowthClick" disabled>{{$t('taskReward.dailyQuestLocked')}}</nut-button>
          <nut-button v-if="dailyGrowthCurrent.status==='processing'" block type="info" @click="dailyGrowthClick">{{$t('taskReward.dailyQuest')}}</nut-button>
          <nut-button v-if="dailyGrowthCurrent.status==='wait_receive'" block type="info" @click="dailyGrowthClick">{{$t('taskReward.dailyQuestCompleted')}}</nut-button>
          <nut-button v-if="dailyGrowthCurrent.status==='received'" block type="warning" @click="dailyGrowthClick" disabled>{{$t('taskReward.dailyQuestFinished')}}</nut-button>
        </div>

        <div class="tg-btn" style="margin:10px 0 5px 0">
            <img src="../assets/images/global/bg208.png" @click="telegramClick"/>
        </div> 

      </div>
    </div>





    <div v-else style="height:80px"></div>

    

    <!-- 新手签到 -->
    <div class="task-list task-list-1x" v-if="dailySignList.length > 0 &&  dailySignCurrent.type === 'rookie'">
      <div class="task-item">
        <div class="task-flex">
          <div class="l type-0">
            <h5>{{$t('taskReward.checkIn7Text')}}({{dailySignCurrent.toSignDay}}/7)</h5>
            <p>{{$t('taskReward.vipCheckInText')}}</p>
          </div>
          <div class="r">
            <strong>+{{ $filters.currencySymbol(baseRewardAmount + extraRewardAmount) }}</strong>
          </div>
        </div>
        <nut-row class="b2">
          <nut-col :span="16" class="l">
            <nut-indicator
              :block="true" 
              align="left"
              :size="7"
              :current="dailySignCurrent.toSignDay"
              :fill-zero="false"
              >step1</nut-indicator
            >
          </nut-col>
          <nut-col :span="8" class="r">
            <nut-button size="small" v-show="dailySignCurrent.canSign" type="primary" @click="taskSignClick(baseRewardAmount, extraRewardAmount)">{{$t('taskReward.checkIn')}}</nut-button>
            <nut-button size="small" v-show="!dailySignCurrent.canSign" type="primary" disabled>{{$t('taskReward.checkInLocked')}}</nut-button>
          </nut-col>
        </nut-row>
      </div>
    </div>

    <!-- 非新手签到 -->
    <div class="task-list" v-else-if="dailySignList.length > 0 && dailySignCurrent.type !== 'rookie'">
      <div class="task-item">
        <div class="task-flex">
          <div class="l type-0">
            <h5>{{$t('taskReward.checkInText')}}</h5>
            <p>{{$t('taskReward.vipCheckInText')}}</p>
          </div>
          <div class="r" v-show="baseRewardAmount > 0">
            <strong>+{{ $filters.currencySymbol(baseRewardAmount + extraRewardAmount) }}</strong>
          </div>
        </div>
        <nut-row class="b2">
          <nut-col :span="8" class="r">
            <nut-button size="small" v-show="dailySignCurrent.canSign" type="primary" @click="taskSignClick(baseRewardAmount, extraRewardAmount)">{{$t('taskReward.complete')}}</nut-button>
            <nut-button size="small" v-show="!dailySignCurrent.canSign" type="primary" disabled>{{$t('taskReward.checkInLocked')}}</nut-button>
          </nut-col>
        </nut-row>
      </div>
    </div>


    <div class="d-app" v-show="appTaskStatus === 'forbid' || appTaskStatus === 'can_receive'">
      <h4>
        {{$t('global.DOWNLOAD_APP')}}
        <strong>+{{ $filters.currencySymbol(appTaskReward) }}</strong>
      </h4>
      <p>{{$t('global.DOWNLOAD_APP_TEXT')}}</p>
      <div class="btnbox">
        <nut-button
          size="small"
          v-show="appTaskStatus === 'forbid'"
          type="info"
          @click="downloadApp"
          plain
          >{{$t('global.DOWNLOAD_APP_BTN')}}</nut-button>
        <nut-button
          size="small"
          v-show="appTaskStatus === 'can_receive'"
          @click="downloadReceiveReward(appTaskReward)"
          type="primary"
          >{{$t('taskReward.receiveReward')}}</nut-button>
      </div>
      <div class="b" v-show="appTaskStatus === 'forbid'">{{$t('global.DOWNLOAD_APP_TEXT4')}}</div>
    </div>

    <h4 class="ls-h4">{{$t('taskReward.missionTarget')}}</h4>

    <div class="task-list pb90">
      
      <div
        class="task-item"
        v-for="(item, index) in regularTaskList"
        :key="index"
        :taskType="item.taskType"
        :conditionValue="item.conditionValue"
      >
        <div class="task-flex">
          <div
            class="l"
            :class="{

              'type-1':
                item.conditionType === 'first_effective_invite',

              'type-2':
                item.conditionType === 'effective_invite_count' ||
                item.conditionType === 'invite_count',

              'type-3':
                item.conditionType === 'first_invite',

              'type-4':
                item.conditionType === 'first_bet',

              'type-5':
                item.conditionType === 'bet_amount' || 
                item.conditionType === 'bet_times',

              'type-6':
                item.conditionType === 'first_recharge',

              'type-7':
                item.conditionType === 'recharge_amount' ||
                item.conditionType === 'recharge_times',
              
              'type-8':
                item.conditionType === 'first_withdraw',

              'type-9':
                item.conditionType === 'first_win',

              'type-10':
                item.conditionType === 'win_times' ||
                item.conditionType === 'win_amount',
            }"
          >
            <strong>+{{ $filters.currencySymbol(item.rewardAmount) }}</strong>
            <h5>{{ item.taskTitle }}</h5>
            <p>{{ item.entryTitle }}</p>
          </div>

          <div class="r">
            <div class="b">
              <nut-progress
                :percentage="item.progress"
                size="small"
                :stroke-color="bgc"
              />
            </div>

            <nut-button
              size="small"
              v-show="item.status === 'processing'"
              type="info"
              @click="goToTaskUrl(item.conditionType)"
              plain
              >{{$t('taskReward.toTask')}}</nut-button
            >
            <nut-button
              size="small"
              v-show="item.status === 'wait_receive'"
              type="primary"
              @click="
                taskReceive(item.serialNum, item.taskId, item.rewardAmount)
              "
              >{{$t('taskReward.receiveReward')}}</nut-button
            >
            <nut-button
              size="small"
              v-show="item.status === 'received'"
              type="primary"
              disabled
              >{{$t('taskReward.received')}}</nut-button
            >
          </div>
        </div>

        
      </div>
    </div>

    <nut-popup
      pop-class="popclass pop-signin"
      v-model:visible="showBasic"
      :z-index="100">
      <div class="pop-t">
        <h4><span>{{$t('taskReward.day')}} {{currentDay}}</span></h4>
        <h5>{{ title }}</h5>
        <!--h4 style="color: #722400;font-size: 14px;">{{ conditionText }}</!--h4 -->
      </div>
      <div class="pop-b">
        <p>
          <strong>{{ $filters.currencySymbol(rewardAmount) }}</strong>
        </p>
        <span style="font-size:12px;display: block;">{{dailyGrowthCurrent.progress}}% {{$t('taskReward.complete')}}</span>
        <nut-button v-if="dailyGrowthCurrent.status === 'processing'" block type="info" @click="goToTaskUrl(conditionType)">{{$t('taskReward.completeTask')}}</nut-button>
        <nut-button v-if="dailyGrowthCurrent.status === 'wait_receive'" block type="info" @click="receiveAward(rewardAmount)">{{$t('taskReward.receiveReward')}}</nut-button>
        <nut-button v-if="dailyGrowthCurrent.status === 'received'" block type="info"  disabled=ture>{{$t('taskReward.received')}}</nut-button>

      </div>
    </nut-popup>


    <nut-popup pop-class="pop-demo" v-model:visible="showContactUs" :style="{height: '200px', width: '250px'}">
        <div class="hd">{{ $t('withdrawal.tx_contact_us') }}</div>
        <div class="bd">
          {{$t('withdrawal.tx4b')}}<strong>
          {{$t('bankCard.email')}}: {{ eMail }}</strong>{{$t('withdrawal.tx5_2')}} 
        </div>
        <div class="btn" style="margin: 20px 0; padding: 0;">
          <nut-button  type="primary" @click="showContactUs = false" 
            style=" width: 48%; margin: 0 1%;border: 1px solid #333; 
            background: #fff; color: #000;">{{ $t('game.gotitBtn') }}</nut-button>
          <!-- <nut-button v-if="commonInfo.supportCenterOn"  type="primary" @click="toHelp"  style=" width:48%; margin: 0 1%;">{{ $t('HELP.H1') }}</nut-button> -->
        </div>
      </nut-popup>

      

    <nut-popup
      position="top"
      :z-index="100"
      :style="{ height: '300px'}"
      @closed="topPopclose" 
      v-model:visible="taskPop"
    >
      <div class="pop-top-userReg">
        <h4>{{$t('taskReward.getBn')}}</h4>
        <p>
          <strong>{{ $filters.currencySymbol(taskAmount) }}</strong>
        </p>
      </div>
    </nut-popup>

    <nut-drag direction="y" :style="{ bottom: '60px', right:'5px', zIndex: '1000 !important'}"  @click="toHelp" >
      <div class="drag-wb-history">
        <img :src="require('../assets/images/global_icon/202405-66.png')"/>
      </div>
    </nut-drag>



    <tabbar :activeIndex="tabbarActiveIndex"></tabbar>
  </div>
</template>

<script>
import { reactive, toRefs } from "vue";
import TabbarMain from "../components/TabbarMain.vue";
import { useRouter } from "vue-router";
import axios from "axios";
import { Toast } from "@nutui/nutui";
import { getCountry } from "@/assets/js/environment";
import { getCommonInfoAttr } from "@/assets/js/commonInfo";

export default {
  data() {
    return {
      country: '',
      imgUrl:'',
      commonInfo:'',
      telegramChannelUrl:'',
      dailyGrowthList: [], //每日成长任务列表
      dailyGrowthCurrent: [], //每日成长任务信息
      dailySignList: {}, //每日签到任务列表
      dailySignCurrent: [], //每日签到任务信息
      regularTaskList: [], //常规任务列表
      appTaskReward: '', //下载app任务 奖励金额
      appTaskStatus: '', //下载app任务 forbid=不可领取 can_receive=可领取  received=已领取
      baseRewardAmount: '',
      extraRewardAmount: '',
      title: '',
      rewardAmount: '',
      conditionType: '',
      conditionText:'',
      currentDay:''
    };
  },
  components: {
    tabbar: TabbarMain,
  },
  methods: {
    textToast(msg) {
      Toast.loading(msg, {
        id: "loading",
        duration: 0,
        bgColor: "rgba(0, 0, 0, 0.9)",
        cover: true,
      });
    },
    telegramClick(){
      Toast.loading("Loading", {
        id: "loading",
        duration: 0,
        bgColor: "rgba(0, 0, 0, 0.9)",
        cover: true,
      });
      window.open(this.telegramChannelUrl, "_self");
    },
    getTaskList() {
      this.textToast(this.$t('global.loading'));
      axios
        .get("/api/task", {})
        .then((response) => {
          this.dailyGrowthList = response.data.data.dailyGrowthList;
          this.dailyGrowthCurrent = response.data.data.dailyGrowthCurrent;
          this.dailySignList = response.data.data.dailySignList;
          this.regularTaskList = response.data.data.regularTaskList;
          if(response.data.data.appTaskReward){
            this.appTaskReward = response.data.data.appTaskReward
            this.appTaskStatus = response.data.data.appTaskStatus
          }
          this.currentDay = this.dailyGrowthCurrent.day

          if(this.dailySignList.length > 0){
            this.dailySignCurrent = response.data.data.dailySignCurrent;
            this.dailySignList = response.data.data.dailySignList;
            let i = this.dailySignCurrent.toSignDay
            this.baseRewardAmount = response.data.data.dailySignList[i].baseRewardAmount
            this.extraRewardAmount = response.data.data.dailySignList[i].extraRewardAmount
            
          }

          let ii = this.dailyGrowthCurrent.day-1
          this.title =  response.data.data.dailyGrowthList[ii].title
          this.rewardAmount =  response.data.data.dailyGrowthList[ii].rewardAmount
          this.conditionType =  response.data.data.dailyGrowthList[ii].conditionType
          //let text = this.conditionType.replace(/_/g," ")
          //this.conditionText = text.substring(0,1).toUpperCase() + text.substring(1) + ' ' + response.data.data.dailyGrowthList[ii].conditionValue
          switch (this.conditionType) {
            case "daily_recharge_times":
              this.conditionText = 'Daily recharge '+ response.data.data.dailyGrowthList[ii].conditionValue +' times'   //当日充值次数达标 x 次
              break;
            case "daily_recharge_amount":
              this.conditionText = 'Daily recharge amount '+ response.data.data.dailyGrowthList[ii].conditionValue  //当日充值金额达标 x 元
              break;
            case "daily_bet_times":
              this.conditionText = 'Daily game '+ response.data.data.dailyGrowthList[ii].conditionValue +' times' //当日下注次数达标 x 次
              break;
            case "daily_bet_amount":
              this.conditionText = 'Daily input amount'+ response.data.data.dailyGrowthList[ii].conditionValue //当日下注金额达标 x 元
              break;
            case "daily_win_times":
              this.conditionText = 'Daily win '+ response.data.data.dailyGrowthList[ii].conditionValue +' times' //当日赢利次数达标 x 次
              break;
            case "daily_win_amount":
              this.conditionText = 'Daily win amount '+ response.data.data.dailyGrowthList[ii].conditionValue //当日赢利金额达标 x 元
              break;
          }

          Toast.hide("loading");
        })
        .catch(function (error) {
          console.log(error);
          Toast.hide("loading");
      })
    }
  },
  created() {
    this.country = getCountry()
    this.imgUrl = localStorage.getItem('countryImgUrl')
    this.getTaskList();
    this.commonInfo = JSON.parse(window.localStorage.getItem('commonInfo'))
    this.telegramChannelUrl = this.commonInfo.telegramChannelUrl
  },
  setup() {
    const state = reactive({
      bgc: "#6a316c",
      showBasic: false,
      taskPop: false,
      taskAmount: 0,
      startFlag: false,
      tabbarActiveIndex: 3,
      showContactUs: false,
    });
    const router = useRouter();
    const methods = {
      toHelp() {
        router.push({ path: '/helpSupport' });
      },

      downloadApp(){
        window.open(getCommonInfoAttr('appDownloadUrl'))
      },
      downloadReceiveReward(amount){
        state.taskAmount = amount
        axios
          .post("/api/task/app/receive-reward", {})
          .then((res) => {
            if (res.data.code === 0) {
              state.taskPop = true;
              setTimeout(() => {
                state.startFlag = false;
                state.taskPop = false;
              }, 1500)
            }
          })
          .catch(function (error) {
            console.log(error);
          })
      },

      receiveAward(amount){
        state.taskAmount = amount
        axios
          .post("/api/task/daily-growth/receive-reward", {})
          .then((res) => {
            if (res.data.code === 0) {
              state.taskPop = true;
              setTimeout(() => {
                state.startFlag = false;
                state.taskPop = false;
              }, 1500)
            }
          })
          .catch(function (error) {
            console.log(error);
          })
      },
      goToTaskUrl(type) {
        switch (type) {
          case "first_recharge":
            router.push({ name: "rechargeBalance" }); //首次充值
            break;
          case "recharge_times":
            router.push({ name: "rechargeBalance" }); //充值次数达标
            break;
          case "recharge_amount":
            router.push({ name: "rechargeBalance" }); //充值金额达标
            break;
          case "first_invite":
            router.push({ name: "agentPromotion" }); //首次邀请
            break;
          case "invite_count":
            router.push({ name: "agentPromotion" }); //邀请人数达标
            break;
          case "first_effective_invite":
            router.push({ name: "agentPromotion" }); 
            break;
          case "effective_invite_count":
            router.push({ name: "agentPromotion" });
            break;
          case "first_withdraw":
            router.push({
              path: 'withdrawalBalance',
              query: {
                amountType: 'coin'
              }
            })
            break;
          case "daily_recharge_times":
            router.push({ name: "rechargeBalance" }); //当日充值次数达标
            break;
          case "daily_recharge_amount":
            router.push({ name: "rechargeBalance" }); //当日充值金额达标
            break;
          default:
            router.push({ path: '/lobby' });
        }
      },
      taskReceive(num, id, amount) {
        axios({
          url: "/api/task/receive-reward",
          method: "post",
          params: { serialNum: num, taskId: id }
        })
          .then((res) => {
            if (res.data.code === 2000) {
              Toast.fail(res.data.msg);
            } else {
              this.taskReceivePop(amount);
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      },
      taskSignClick(a1, a2){
        const amount = a2 > 0 ? a1 + a2 : a1
        axios.post("/api/task/daily-sign/sign", {})
        .then((response)=> {
              if(response.data.code === 0){
                this.taskReceivePop(amount);
              }else{
                Toast.fail("error!");
              }
        })
        .catch(function (error) {
          console.log(error);
          Toast.fail("Network anomaly!");
        });
      },
      taskReceivePop(amount) {
        state.taskAmount = amount;
        state.startFlag = true;
        state.taskPop = true;
        setTimeout(() => {
          state.startFlag = false;
          state.taskPop = false;
        }, 1500);
      },
      topPopclose(){
        router.go(0);
      },
      // 查看任务弹窗
      dailyGrowthClick(){
        state.startFlag = true;
        state.showBasic = true;
      },
      contactUsClick(){
       state.showContactUs = true
      },
    };
    const showBasicClick = () => {
      state.startFlag = true;
      state.showBasic = true;
    };
    const hideBasicClick = () => {
      state.showBasic = false;
      router.push({ path: '/lobby' });
    };
    return {
      ...toRefs(state),
      ...methods,
      showBasicClick,
      hideBasicClick,
    };
  },
};
</script>

<style scoped>

.signin-task .dayls.opacity05{ opacity: .5;}
.nut-popup.popup-top{background:#ffe26f !important}
.popup-top {
  overflow: hidden;
}
.pop-top {
     background:#000 !important;
     background-size: 60% auto !important;
}
.pop-top h4 {
  padding-left: 130px;
  padding-top: 45px;
  margin-bottom: 0;
  font-size: 18px;
  color: #00EDA6;
}
.pop-top p {
  padding-left: 160px;
  padding-top: 10;
  margin: 0;
  background: #000;
  background-size: 30px auto;
}
.pop-top p strong {
  font-size: 36px;
  color: #ffd500;
  font-weight: 500;
}

.signin-task {
  margin-top: 43px;
}
.signin-task img {
  width: 100%;
  height: auto;
}
.signin-task .bd {
  margin-top: -20px;
  padding: 0 0 30px 0;
  position: relative;
}
.signin-task .dayls {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-bottom: 10px;
  padding: 10px; margin: 0 10px 10px 10px; border-radius: 10px;
  background:#30284C;
  background:  linear-gradient(180deg, #0c9a85 1%, #30284C 40%) !important;
}
.signin-task .dayls .d {
  background: #221C36 url(../assets/images/global_icon/202405-24b.png) right bottom no-repeat; background-size: 40px auto;
  align-items: flex-start;
  text-align: center;
  margin: 3px 1px;
  padding-bottom: 3px;
  font-size: 10px;
  width:30%;
  border-radius: 5px;
  overflow: hidden;
  flex-grow: 1;
}
.signin-task .dayls .d:last-child {
  flex-grow: 2;
  width: 48.5%;
  flex-grow: 1;
}



.signin-task .dayls .d h5 {
  margin: 0 0 3px 0;
  padding: 0;
  line-height: 26px;
  font-weight: 300;
  color: #fff;
  font-size: 12px;
  background:#141021; 
}
.signin-task .dayls .d p {
  line-height: 38px;
  margin: 0;
  color: #FFE114;
  display: inline;
  background: url(../assets/images/global_icon/202405-24.png) 0 center no-repeat;background-size: 20px; padding-left: 22px;
  font-size: 20px; padding-top: 2px;
}

.signin-task .dayls .d.active {
  background: #514874  url(../assets/images/global/bg37.png) right bottom no-repeat; background-size: 30px auto;
}
.signin-task .dayls .d.active h5 {
  color: #fff;
  font-weight: 600;
  background: #221C36;
}
.signin-task .dayls .d.active p {
  color: #FFE114;
  font-weight: 600;
}
.signin-task .dayls .complete {
  background: #221C36 url(../assets/images/global/bg37.png) right bottom no-repeat; background-size: 30px auto; opacity: .3;
}
.signin-task .dayls .complete h5 {
  color: #e2dbc5;
}

.signin-task .dayls .d:last-child p {
  background: url(../assets/images/global_icon/202405-23.png) center 0 no-repeat !important;

  background-size: 100px !important;
  padding-left: 0;
  width: 100%;
  display: block;
  text-align: center;
  }

.signin-task .btn {
  margin: 10px;
}
.ls-h4 {
  margin: 0 0 20px 0;
  line-height: 40px;
  padding: 0 0 0 10px;
  font-size: 15px;
  color: #fff;
  background: url(../assets/images/global_icon/202405-39.png) 10px center no-repeat !important; background-size:24px !important; padding-left: 38px;
}

.task-list {
  margin: -20px 10px 10px 10px;
  width: 100%;
  border-radius: 10px;
  display: flex;
  flex-flow: wrap;
}

.task-list .task-item:last-child {
  border-bottom: 0px;
}
.task-list:last-child {
  margin: 0px 10px 10px 10px;
  padding-bottom: 70px;
}
.task-list.pb90 {
  padding-bottom: 50px;
}

.task-list-1x .task-item{ width: calc(100% - 40px);     background: linear-gradient(89deg, #3e00c1 .38%, #fa00ff 107.78%);text-align: left;}
.task-list-1x .task-item .task-flex .l{  padding-top: 1px; padding-left: 50px;}
.task-list-1x .task-item .task-flex .r{ text-align: right;}
.task-list-1x .task-item .task-flex .r strong{color: #FFE114; font-size: 18px;}
.task-flex .l {
  padding-top: 50px;
  position: relative;
  height: 60px;
  background: url(../assets/images/global_icon/202405-37.png) 0 0 no-repeat; background-size:45px;
}
.task-flex .l.type-0{background: url(../assets/images/global_icon/202405-37.png) 0 0 no-repeat; background-size:45px;}
.task-flex .l.type-1{background: url(../assets/images/global_icon/202405-51.png) 0 0 no-repeat; background-size:45px;}
.task-flex .l.type-2{background: url(../assets/images/global_icon/202405-52.png) 0 0 no-repeat; background-size:45px;}
.task-flex .l.type-3{background: url(../assets/images/global_icon/202405-53.png) 0 0 no-repeat; background-size:45px;}
.task-flex .l.type-4{background: url(../assets/images/global_icon/202405-56.png) 0 0 no-repeat; background-size:45px;}
.task-flex .l.type-5{background: url(../assets/images/global_icon/202405-54.png) 0 0 no-repeat; background-size:45px;}
.task-flex .l.type-6{background: url(../assets/images/global_icon/202405-57.png) 0 0 no-repeat; background-size:45px;}
.task-flex .l.type-7{background: url(../assets/images/global_icon/202405-58.png) 0 0 no-repeat; background-size:45px;}
.task-flex .l.type-8{background: url(../assets/images/global_icon/202405-59.png) 0 0 no-repeat; background-size:45px;}
.task-flex .l.type-9{background: url(../assets/images/global_icon/202405-60.png) 0 0 no-repeat; background-size:45px;}
.task-flex .l.type-10{background: url(../assets/images/global_icon/202405-61.png) 0 0 no-repeat; background-size:45px;}

.task-flex .l strong{
  font-size: 24px;
  padding: 5px 0;
  color: #FFE114;
  position: absolute;
   right: 10px; top: 5px;
}
.task-flex .l h5 {
  margin: 10px 0 0 0;
  padding: 0;
   color: #fff; font-size: 14px;
}
.task-flex .l p {
  margin: 0;
  font-size: 10px;
  color: #e0f8ff;
}

.task-flex .r {
  text-align: center;
}
.task-flex .r .nut-button.nut-button--small{ width: 100%;}

.task-item {
  padding: 10px;
  margin-right: 3px;
  margin-bottom: 8px;
  border-radius: 10px;
  width: calc(50% - 30px) ;
  background: #221C36;
}

.task-item .b {
  margin: 10px 0;
}
.task-item .b2 .nut-indicator {
  margin: 10px 0 0 10px;
}
.task-item .b2 .r {
  text-align: right;
  margin-top: 5px;
}
.nut-countup {
  width: auto;
  color: #940e05 !important;
}

.pop-signin .pop-t {
  height: 130px;
  
  text-align: center;
  color: #fff;
  background:linear-gradient(89deg,#3e00c1 .38%,#00EDA6 107.78%);
}
.pop-signin .pop-t h4 {
  margin: 0;
  font-weight: 400;
  line-height: 30px;
  font-size: 20px;
  padding-top:68px;
  background: url(../assets/images/global/bg172.png) center 10px no-repeat; background-size:50px;
}
.pop-signin .pop-t h4 span{
  font-size: 20px;
  color: #fff;
  font-weight: 600;
  padding: 0px 30px; background: #014046; border-radius: 20px;
}
.pop-signin .pop-t h5{ padding: 0; margin: 0; line-height: 30px;}
.pop-signin p {
  margin: 0;
  padding: 0;
}
.pop-signin p strong {
  font-size: 36px;
  color: #ff6f00;
  font-weight: 500;
}
.pop-signin .pop-b {
  background: #fff;
  border-radius: 0 0 12px 12px;
  padding: 0 20px 20px 20px;
  text-align: center;
}
.pop-signin .pop-b span{ margin-bottom:10px;  color: #ff9e0d ;}


</style>

<style>
.task-item .nut-progress .nut-progress-insidetext {
  background: #005200 !important;
}
.nut-progress-outer {
  margin-top: 0px;
}
.nut-progress .nut-progress-outer {
  background-color: #040404 !important;
}
.nut-progress .nut-progress-text {
  color: #fff !important;
  position: absolute;
  width: 100%; text-align: center;
  
}
.nut-progress .nut-progress-outer .nut-progress-inner{background: linear-gradient(89deg, #3e00c1 .38%, #fa00ff 107.78%) !important;}
.nut-progress .nut-progress-outer-part{ width: 100%; }
.nut-progress .nut-progress-outer.nut-progress-small{ height: 13px !important;}

.nut-countup .run-number { font-weight: 500 !important;}
.pop-signin {
  border-radius: 10px;
  width: 320px;
  padding: 0px;
  background:#30284C !important;
}
.task-item .b2 .nut-indicator .nut-indicator--dot {
  width: 8px;
  height: 8px;
  background: #00EDA6;
}
.task-item .b2 .nut-indicator .nut-indicator--number {
  background-color: #00EDA6 !important;
  box-shadow: 0 0 1px 1px #194538 !important;
  border: 1px solid #fff;
  color: #000;
}
.nut-button--small{ height: 28px;line-height: 12px !important;padding: 0 5px; min-width: 100px;}


.br .signin-task .nut-button--disabled{ opacity: 1; border:2px solid #666 !important; color: #dbddcc;
background:linear-gradient(315deg,#696e52 0%, #96ae92 50%,#8a9169 100%) !important}
</style>
