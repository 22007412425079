<template>
<div>
  <nut-navbar
  :left-show="false"
    :title="$t('pesetPassword.h1')"
    fixed=true
    class="mb0 nav-icon-right nut-navbar--fixed"
  >
  <template #left>
      <i class="nutui-iconfont nut-icon nut-icon-left" @click="backClick"></i>
    </template>
  </nut-navbar>

  <div class="login">
    
    <div class="login-bn"></div>

    <div class="login-bd">
      <h3 class="s1">{{$t('pesetPassword.h1')}}</h3>

      <div class="login-inp" v-show="hasPassword">
        <label class="fs12 fc-999">{{$t('pesetPassword.old')}}</label>
        <login-input
          label=""
          :placeholder="this.$t('pesetPassword.oldPlaceholder')"
          type="password"
          rule="^.{6,18}$"
          maxLength="18"
          @inputChange="(res) => (oldPassword = res)"
          
        />
      </div>

      <div class="login-inp">
        <label class="fs12 fc-999">{{$t('pesetPassword.new')}}</label>
        <login-input
          label=""
          :placeholder="this.$t('pesetPassword.newPlaceholder')"
          :type="passwordShow ? 'text' : 'password'"
          rule="^.{6,18}$"
          maxLength="18"
          @inputChange="(res) => (newPassword = res)"
        />
        <div class="inp-r" @click="passwordShow = !passwordShow">
          <nut-icon name="eye" v-if="passwordShow"></nut-icon>
          <nut-icon name="marshalling" v-else></nut-icon>
        </div>
      </div>

      <div class="login-inp">
        <label class="fs12 fc-999">{{$t('pesetPassword.confirmPassword')}}</label>
        <login-input
          label=""
          :placeholder="this.$t('pesetPassword.confirmPasswordPlaceholder')"
          :type="passwordShow ? 'text' : 'password'"
          rule="^.{6,18}$"
          maxLength="18"
          @inputChange="(res) => (confirmPassword = res)"
        />
      </div>

      <div class="login-btn">
        <nut-button block type="info" @click="setPassword">{{$t('global.confirm')}}</nut-button>
      </div>

    </div>

  </div>


</div>
</template>

<script>
import { onMounted } from "vue";
import { useRouter } from "vue-router";
import { Toast } from "@nutui/nutui";
import axios from "axios";
import loginInput from "@/components/loginInput.vue";

export default {
  data() {
    return {
      oldPassword: null,
      newPassword: null,
      confirmPassword: null,
      hasPassword: true,
      passwordShow: false,
    }
  },
  components: {
    loginInput,
  },
  methods: {
    //async registerSubmit() {
    setPassword() {
      if(this.newPassword !== this.confirmPassword){
          Toast.fail(this.$t('global.passwordsError'));
          return
      }
      if(!this.hasPassword){
          this.oldPassword = null
      }
      if (this.newPassword !== "" && this.newPassword !== this.oldPassword) {
        axios
          .post("/api/user/set-password", {
            oldPassword: this.oldPassword,
            newPassword: this.newPassword,
          })
          .then((response) => {
            if (response.data.code === 0) {
              Toast.success(this.$t('pesetPassword.success'));
              localStorage.setItem("hasPassword", true);
              setTimeout(() => {
                this.$router.push("/myHome");
              }, 1000);
            }else if (response.data.code === 1004){
              let res = response.data.data
              for(var i in res){	
                switch (res[i].fieldName) {
                  case "oldPassword":
                    Toast.fail(res[i].description);
                    break;
                  case "newPassword":
                    Toast.fail(res[i].description);
                    break;
                }
              }
            }
          })
          .catch(function (error) {
            console.log(error);
            Toast.fail("Error!");
          });
      } else {
        Toast.fail(this.$t('global.inputRequired'));
      }
    },
  },
  created() {
    /* this.hasPassword = localStorage.getItem("hasPassword"); 当前注册都需要设置密码 */
  },
  setup() {
    const router = useRouter();
    const methods = {
      backClick() {router.go(-1);},};
    onMounted(() => {
    });
    return {
      ...methods,
    };
  },
};
</script>

<style scoped></style>
<style></style>