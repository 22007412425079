<template>
    <nut-navbar
      :left-show="false"
      @on-click-icon="titIconClick"
      title="Refer And Earn Money"
      titIcon="home"
      fixed=true
      class="mb0 nav-icon-right nut-navbar--fixed">
      <template #left>
        <i class="nutui-iconfont nut-icon nut-icon-left" @click="backClick"></i>
      </template>
    </nut-navbar>

  <h2 class="h2">
    <img v-if="show_w3" src='../assets/images/bn7.jpg' />
    <img v-else src='../assets/images/bn6.jpg' />
  </h2>
  <div class="plan-main">
    <div class="p1">
      <h3><strong>Rebate Income</strong></h3>
      <ul>
        <li>
          &#128176; Today<br/>Rebate Income
          <h5>{{ $filters.currencySymbol(activityInfo.todayReward) }}</h5>
        </li>
        <li>
          &#128176; Total<br/>Rebate Income
          <h5>{{ $filters.currencySymbol(activityInfo.totalReward) }}</h5>
        </li>
      </ul>
    </div>
  
    <div class="p3">
      
      <table>
        <tr>
          <th>Type</th>
          <th>Today</th>
          <th>Total</th>
        </tr>
        <tr>
          <td style="width:auto">Frist deposit rebate</td>
          <td style="width:auto">{{ $filters.currencySymbol(activityInfo.todayFirstReward) }}</td>
          <td style="width:auto">{{ $filters.currencySymbol(activityInfo.totalFirstReward) }}</td>
        </tr>
        <!-- <tr>
          <td>2nd reward</td>
          <td>{{ $filters.currencySymbol(activityInfo.todaySecondReward) }}</td>
          <td>{{ $filters.currencySymbol(activityInfo.totalSecondReward) }}</td>
        </tr> -->
        <tr>
          <td>Maga deposit rebate</td>
          <td>{{ $filters.currencySymbol(activityInfo.todayThirdReward) }}</td>
          <td>{{ $filters.currencySymbol(activityInfo.totalThirdReward) }}</td>
        </tr>

        <tr>
          <td style="background: #e7d49b;">Total</td>
          <td style="background: #e7d49b;"><strong>{{ $filters.currencySymbol(activityInfo.todayReward) }}</strong></td>
          <td style="background: #e7d49b;"><strong>{{ $filters.currencySymbol(activityInfo.totalReward) }}</strong></td>
        </tr>
      </table>
    </div>

    <div class="btnbox">
        <nut-button size="large" type="primary" class="regbtn" @click="copyClick">
          Copy & Make Money
        </nut-button>
        
        <nut-button size="large" type="primary" class="regbtn" @click="toHistoryList">
          <img style="width:16px;vertical-align: middle; margin-right: 4px;margin-top: -4px;" src="../assets/images/global/bg124b.png" /> Bonus received history
        </nut-button>

      </div>

    
    <div class="p4">
      <h3 class="title">Rule</h3>
      <li class="b1"><strong>1</strong>User completes any first deposit,you receive &#128176;<span>{{ $filters.currencySymbol(activityInfo.firstCmsAmount) }}</span></li>
      <li class="b2"><strong>2</strong>The user's deposit amount reaches 5000,you receive &#128176;<span>{{ $filters.currencySymbol(activityInfo.thirdCmsAmount) }}</span></li>
    </div>
</div>

</template>

<script>
import { reactive } from "vue";
import { useRouter } from "vue-router";
import axios from "axios";
import { Toast } from "@nutui/nutui";
export default {
  data() {
    return {
      show_w3: false,
      commonInfo: '',
      frontBaseUrl: '',
      inviteCode: '',
      activityInfo:{
        totalReward: 0,
        todayReward: 0,

        totalFirstReward: 0,
        totalSecondReward: 0,
        totalThirdReward: 0,

        todayFirstReward: 0,
        todaySecondReward: 0,
        todayThirdReward: 0,

        firstCmsAmount: 0,
        secondRepayCount: 0,
        secondCmsAmount: 0,
        thirdRechargeAmount: 0,
        thirdCmsAmount: 0,
      }
    }
  },
  methods: {
    copyClick(){
      var cInput = document.createElement("input")
      cInput.value = this.frontBaseUrl
      document.body.appendChild(cInput)
      cInput.select()
      document.execCommand("copy")
      Toast.success(this.$t('global.Your_invitation_link_has_been_copied'))
      document.body.removeChild(cInput)
    },

    toHistoryList(){
        this.$router.push("/activityHistoryList");
    },
    
    textToast(msg) {
      Toast.loading(msg, {
        id:"loading",
        duration: 0,
        bgColor: "rgba(0, 0, 0, 0.9)",
        cover:true,
      });
    },

  },
  mounted() {
    
  },
  created() {
    this.textToast(this.$t('global.loading'))
    this.commonInfo = JSON.parse(localStorage.getItem('commonInfo'))
    this.inviteCode = localStorage.getItem("inviteCode")
    const d = new Date()
    // 1100活动
    this.show_w3 = (d.getDay() === 3)


    this.frontBaseUrl = this.commonInfo.frontBaseUrl + '?invite=' + this.inviteCode
      axios({
        url: "/api/activity/150",
        method: "get"
      }).then((res) => {
        this.activityInfo = res.data.data;
        Toast.hide("loading")
      }).catch(function (error) {
        console.log(error);
        Toast.hide("loading")
      });

  },
  setup() {
    const state = reactive({
    });
    const router = useRouter();
    const methods = {
      backClick() {
        router.go(-1);
      },
      titIconClick(){
        router.push({
        name: "myHome",
      });
      }
    };

    return {
      router,
      state,
      ...methods,
    };
  }
};
</script>

<style scoped>



  .plan-main{ margin: 10px auto 0px auto; background: #000; padding: 0 0 60px 0; z-index: 100;position: relative;
    background: url(../assets/images/global/bg4.png) center bottom no-repeat; background-size: 190px auto; }
  .plan-main p, 
  .plan-main h4{ margin: 10px 0;}
  .plan-main .f12{ font-size: 12px;}
  .plan-main .f18{ font-size: 18px;}
  .h2{ text-align: center; margin: 40px 0 0 0; padding: 0; background: #000; z-index: 90; position: relative; }
  .p1, .p2, .p3, .p4{ background: #f5f5f5; padding: 10px; border-radius:6px;
  box-shadow: 0 0px 5px #ccc !important; }
  .p1{margin: -20px 10px 10px 10px;padding-top: 1px;}
  .p3{margin: 0px 10px 10px 10px;padding: 3px;}
  .p4{margin: 0px 10px 20px 10px;}

  .p1 img, .p3 img, .p4 img , .p5 img, .h2 img { width: 100%; margin: 0;}
  .p1 h3{ text-align: center;font-size: 18px; margin: 10px 0;}
  .p1 h3 strong{  color: #1f3b62; padding-left: 30px; background: url(../assets/images/global_icon/202405-36.png) left center no-repeat; background-size: 24px auto;}
  .p1 ul{ margin-top: 10px; display: flex; text-align: center;}
  .p1 li{ margin-bottom: 5px;font-size: 14px; background: #ffffff; border-radius:5px; padding: 5px; width: 48%; margin: 5px 1%;}
  .p1 li h5{ font-size: 20px; margin: 0; color: #ff6000; line-height: 40px;}

  .p3 table{ border: 1px solid #fff; font-size: 12px;border-radius:5px; width:100%; }
  .p3 table tr{border: 1px solid #fff; text-align: left; padding-left: 20px;}
  .p3 table th{border: 1px solid #fff; font-weight: 300; background: #2c7e82; line-height: 14px; padding: 5px 0 5px 20px; color: #fff; border-radius:5px;}
  .p3 table td{border: 1px solid #fff;line-height: 14px;text-align: left;background: #c3f4f7; border-radius:5px; padding: 5px 0 5px 20px;}
  .p3 .example{ display: flex; margin-bottom: 10px;}
  .p3 .img100{ width: 80px; text-align: center; color: #666;}
  .p3 .img100 img{ width: 80px; border-radius:10px; }
  .p3 p{ margin: 0 0 0 10px;}

  .p4 {background:#f5f5f5;padding-bottom: 20px;}
  .p4 li{ background: #14dbc7; color:#fff; line-height: 14px; padding: 10px 10px 10px 5px; margin-bottom: 5px; border-radius:5px;}
  .p4 li.b1{ background: #10c9c6; }
  .p4 li.b2{ background: #09acb2; }
  .p4 li.b3{ background: #009393; }
  .p4 li strong{ background: #fff; color: #005f93;border-radius:10px; display: inline-block; width: 20px; height: 20px; line-height: 20px; text-align: center; font-size: 16px; margin-right: 8px;}
  .p4 li span{ font-weight: 600; color: #ffea07; font-size: 18px; }
  .p4 h3{ text-align: center; font-size: 20px; color: #b64e09; margin: 10px 0;}
  .p4 p{ padding: 10px; background: #fbfbe2; border-radius:10px; border: 1px solid #eaeac1; color: #ac6e1d;}
  .regbtn{  background:#1278b5 !important; color:#fff; font-size: 20px; 
  border-radius:10px !important; border-bottom: #2d6586 5px solid;}



  h3.title{ margin: 0 0 10px 0; text-align: center; color: #1f3b62;}
  
  .btnbox { display:flex;  width: calc(100% - 20px);justify-content: center; margin: -10px 10px 10px 10px;}
  .btnbox .regbtn{ width: calc(50% - 5px); margin: 10px 5px 0 0; font-size: 14px; font-size:12px; background: #ffe114 !important;border-bottom: #bda507 5px solid; color: #000; }
  .btnbox .regbtn:last-child{  background:#00EDA6 !important;border-bottom: #00aa77 5px solid; margin: 10px 0 0 5px;}

  .p5 p{ color: #fff; opacity: .5; line-height: 14px; text-align: center; padding: 0 10px; font-size: 12px;}
  .plan-main h6{ text-align: center; color: #fff; margin: 10px 0 0px 0; opacity: .3; font-size: 20px; font-weight: 300;}
</style>
