<template>
<div>
    <nut-navbar
      :left-show="false"
      @on-click-icon="iconClick"
      :title="$t('shareBonus.h1')"
      titIcon="home"
      fixed=true
      class="mb0 nav-icon-right nut-navbar--fixed"
    >
    </nut-navbar>
  <div class="login hjbg pt50">

    <div class="btnImg">

      <img @click="playClick" :src="require('../assets/images/global/bg57j.png')" />
    </div>

    <div class="bonus-amount">
        <div class="bd">
          <p>{{$t('shareBonus.h2')}}</p>
          <h3>{{ $filters.currencySymbol(amount) }}</h3>
          <span class="f-12-c">{{$t('shareBonus.h3')}}</span>
          <input type="text" readonly v-model="url" class="inp" />
          <nut-button size="large" type="primary" class="regbtn" @click="copyLink">{{$t('shareBonus.btn')}}</nut-button>
        </div>
    </div>


  </div>

  

</div>
</template>

<script>
import { reactive, onMounted } from "vue";
import { useRouter } from "vue-router";
import { Toast } from "@nutui/nutui";

export default {
  data() {
    return {
      imgUrl:'',
      commonInfo: '',
      param:[],
      shareBonusReceiveCode: '',
      amount: '',
      url: ''
    };
  },
  methods: {
    playClick(){
      this.$router.push({
        path: '/lobby'
      })
    },
    copyLink(){
      var cInput = document.createElement("input")
      cInput.value = this.url
      document.body.appendChild(cInput)
      cInput.select()
      document.execCommand("copy")
      Toast.success(this.$t('shareBonus.copied'))
      document.body.removeChild(cInput)
    },
    getQueryData() {
      this.amount = this.$route.query.amount
    }
  },
  mounted() {
    this.commonInfo = JSON.parse(window.localStorage.getItem("commonInfo"));
    if(window.location.href.split("?")[1]){
      let url = window.location.href.split("?")[1]
      let arr = url.split("&")
      for (var i = 0; i < arr.length; i++) {
        this.param[arr[i].split("=")[0]] = arr[i].split("=")[1];
      }
      if(this.param.s){
        localStorage.setItem("shareBonusReceiveCode", this.param.s)
      }
    }
    if(localStorage.getItem("shareBonusReceiveCode")){
      this.shareBonusReceiveCode = localStorage.getItem("shareBonusReceiveCode")
      this.url =  this.commonInfo.frontBaseUrl + '?c='+this.shareBonusReceiveCode
    }
    if(localStorage.getItem("shareBonusAmount")){
      this.amount = localStorage.getItem("shareBonusAmount")
    }
  },
  watch: {
    '$route': 'getQueryData'
  },
  created() {
    this.imgUrl = localStorage.getItem('countryImgUrl')
    this.getQueryData()
  },
  setup() {
    const state = reactive({
    });
    const router = useRouter();
    const methods = {
      iconClick() {
        router.push({
          path: '/lobby',
        })
      },
    };
    
    onMounted(() => {
    })
    return {
      router,
      state,
      ...methods,
    };
  },
};
</script>

<style scoped>
.btnImg{position: absolute; bottom: 350px; left:0;right:0; width:100%; height: 220px; text-align: center; }
.btnImg img{ height: 220px;}
.f-12-c{ font-size: 13px; text-align: center; color: #fff3c0; display: block; margin: 0 0 15px 0;}
.bonus-amount{
  position: absolute;
  bottom: 10px;
  left: 0;
  right: 0;
  width: 100%;
  height: 280px;
  overflow: hidden;
  background: url(../assets/images/global/bg57.png) center center no-repeat;
  background-size: auto 300px;
  padding-top: 20px;
}
.bonus-amount .bd{margin-left:auto;margin-right:auto; width:380px; height: 320px; }
.bonus-amount p{ padding: 0; margin: 15px 30px 0 30px; height: 30px; line-height: 20px; text-align: center; font-size: 20px; color: #fff; font-weight: 300;}
.bonus-amount h3{ padding: 0; margin: 0 0 5px 0;; height: 70px; text-align: center; font-size: 50px; color: #fadb51; font-weight: 500;}
.bonus-amount .inp{ background: #fde9ff; width: 305px; height: 40px; font-size: 20px; border: 0;margin: 8px 25px 12px 25px; border-radius:5px !important;padding: 0 10px;}
.regbtn{  background: #00EDA6 !important;
  color: #000;
  font-size: 20px;
  width: 310px;
  border-radius: 25px !important;
  margin: 0 35px 10px 35px;
  border-bottom: #00b67f 5px solid;}

.login-bd {
  padding: 20px;
  background: #fff;
  margin: 210px 20px 20px 20px;
  box-shadow: 0px 0px 5px #d9d2f4;
  border-radius: 10px;
}
.otpbtn {
  float: right;
}
.login.hjbg {min-height:600px; position: relative; background:url(../assets/images/global/bg54.jpg) 0 bottom no-repeat !important; background-size: 100% auto !important;}

</style>

<style>
</style>
