<template>
  <div class="play-rule">
    <h4><nut-icon name="tips" style="vertical-align: middle;margin-right: 5px;margin-bottom: 4px;"></nut-icon>{{$t('gameRulesDice.h1')}}</h4>
    <dl>
      <dt style="margin-bottom: 40px; color: #00EDA6;">{{$t('gameRulesDice.title')}}</dt>
      <dt>{{$t('gameRulesDice.dt1')}}</dt>
      <dd>{{$t('gameRulesDice.dd1')}}</dd>

      <dt>{{$t('gameRulesDice.dt2')}}</dt>
      <dd>{{$t('gameRulesDice.dd2')}}</dd>
      <dd>{{$t('gameRulesDice.dd3')}}</dd>
    </dl>

  </div> 
</template>

<script>
import { getCountry } from "@/assets/js/environment";

export default {
  name: 'GameRulesDice',
  props: {},
  data() {
    return {
      country: '',
    };
  },
  methods: {
  },
  created() {
    this.country = getCountry()
  },
  setup() {}
};
</script>
