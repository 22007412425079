<template>
  <div>
    <nut-navbar
      :left-show="false"
      :title="$t('RechargeUSDT.h1')"
      fixed=true
      class="mb0 nav-icon-right nut-navbar--fixed"
    >
    <template #left>
      <i class="nutui-iconfont nut-icon nut-icon-left" @click="backClick"></i>
    </template>
    </nut-navbar>

    <div class="pt50">
      <nut-tabs type="smile"  v-model="tabValue">
        <nut-tabpane title="Wait Reply" id="scroll">

          <div class="app-empty" v-show="completedData">
            <div class="app-empty-image">
              <img :src="require('../assets/images/global/bg07.png')"/>
            </div>
            <div>
              <p>{{$t('RechargeUSDT.noData')}}</p>
            </div>
          </div>

          <div class="csls">
            <nut-infiniteloading
                containerId = 'scroll'
                :use-window='false'
                :has-more="hasNext"
                :load-txt='$t("global.loading")'
                :load-more-txt='$t("global.noMore")'
                :load-icon= 'null'
                @load-more="loadMore"
            >
              <div class="csls-item"  v-for="(item, index) in waitReplyList" :key="index">
                <h4>{{$t('RechargeUSDT.Phone_Number_placeholder')}}: {{item.title}}</h4>
                <p><span class="tx-right-r">{{$t('CS.waitReply')}}</span>{{item.createTime}}</p>
                <div class="bd">
                  <ul>
                    <li>{{item.type}}</li>
                    <li>{{item.address}}</li>
                    <li>{{item.usdt}}</li>
                    <li>{{item.amount}}</li>
                  </ul>
                </div>
              </div>


            </nut-infiniteloading>
          </div>
        </nut-tabpane>
        <nut-tabpane title="Replied" id="scroll2">
          <div class="app-empty" v-show="waitData">
            <div class="app-empty-image">
              <img :src="require('../assets/images/global/bg07.png')"/>
            </div>
            <div>
              <p>{{$t('RechargeUSDT.noData')}}</p>
            </div>
          </div>
          <div class="csls" >
            <nut-infiniteloading
                containerId = 'scroll2'
                :use-window='false'
                :has-more="hasNext2"
                :load-txt='$t("global.loading")'
                :load-more-txt='$t("global.noMore")'
                :load-icon= 'null'
                @load-more="loadMore2"
            >
              <div class="csls-item"  v-for="(item, index) in repliedList" :key="index">
                <h4>{{$t('RechargeUSDT.Phone_Number_placeholder')}}: {{item.title}}</h4>
                <p><span class="tx-right-g">{{$t('CS.replied')}}</span>{{item.createTime}}</p>
                <div class="bd">
                  <ul>
                    <li>{{item.type}}</li>
                    <li>{{item.address}}</li>
                    <li>{{item.usdt}}</li>
                    <li>{{item.amount}}</li>
                  </ul>
                </div>
                <div class="csls-answer">
                  {{item.replyContent}}
                </div>
              </div>
            </nut-infiniteloading>
          </div>
        </nut-tabpane>
      </nut-tabs>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs } from "vue";
import { useRouter } from "vue-router";
import axios from "axios";
import { Toast } from '@nutui/nutui';

export default {
  data() {
    return {
      imgUrl: '',
      completedData: true,
      waitData: true,
      waitReplyList: [],
      repliedList: [],
      current:1,
      current2:1,
      hasNext:'',
      hasNext2:'',
      tabValue:"0",
    };
  },
  methods: {
    loadMore(done){
      if(this.hasNext){
        this.getWaitReplyList(this.current + 1)
        setTimeout(() => {
          done()
        }, 1000);
      }
    },
    loadMore2(done){
      if(this.hasNext){
        this.getRepliedList(this.current + 1)
        setTimeout(() => {
          done()
        }, 1000);
      }
    },
    getWaitReplyList(c) {
      axios({
        url: "/api/system/ticket/paginate",
        method: "get",
        params: { current: c, status:"wait_reply" }
      }).then((res) => {
        for (let i = 0; i < res.data.data.records.length; i++) {
            if(res.data.data.records[i].type === 'recharge'){
              const d = res.data.data.records[i].content.split(';')
              res.data.data.records[i].type = d[0]
              res.data.data.records[i].address = d[1]
              res.data.data.records[i].usdt = d[2]
              res.data.data.records[i].amount = d[3]
              this.waitReplyList.push(res.data.data.records[i]);
             
            }
        }
        this.current = res.data.data.current;
        this.hasNext = res.data.data.hasNext;
        if (this.waitReplyList.length > 0) {
          this.completedData = false;
        }
        Toast.hide("loading")
      });
    },
    getRepliedList(c) {
      axios({
        url: "/api/system/ticket/paginate",
        method: "get",
        params: { current: c, status:"replied" }
      }).then((res) => {
        for (let i = 0; i < res.data.data.records.length; i++) {
            if(res.data.data.records[i].type === 'recharge'){
              const d = res.data.data.records[i].content.split(';')
              res.data.data.records[i].type = d[0]
              res.data.data.records[i].address = d[1]
              res.data.data.records[i].usdt = d[2]
              res.data.data.records[i].amount = d[3]
              this.repliedList.push(res.data.data.records[i]);
            }
        }
        this.current2 = res.data.data.current;
        this.hasNext2 = res.data.data.hasNext;
        if (this.repliedList.length > 0) {
          this.waitData = false;
        }
        Toast.hide("loading")
      }).catch(function (error) {
          console.log(error);
          Toast.hide("loading");
        })
    },
    textToast(msg){
        Toast.loading(msg, {
          id:"loading",
          duration: 0,
          bgColor: "rgba(0, 0, 0, 0.9)",
          cover:true,
        });
      }
  },
  created() {
    this.imgUrl = localStorage.getItem('countryImgUrl')
    this.textToast(this.$t('global.loading'))
    this.getWaitReplyList(1);
    this.getRepliedList(1);
  },

  setup() {
    const state = reactive({
      currentPage1: 1,
    });
    const router = useRouter();
    const methods = {
      backClick() {
        router.push({
          name: "rechargeUSDT",
        });
      },
    };
    return {
      ...toRefs(state),
      ...methods
    };
  },
};
</script>

<style scoped>

.nut-tabpane{
  overflow-y: auto;
  overflow-x: hidden;
  top:0; bottom: 0;
  height: 750px;
  background: none;
}
.nut-pagination {
  margin-top: 10px;
  justify-content: center;
}
.csls-item {
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #eee;
}
.csls-item h4 {
  margin: 5px 0;
  line-height: 24px;
}
.csls-item p {
  line-height: 24px;
  padding: 0;
  margin: 0;
  color: #999;
}
.csls-item .tx-right-r {
  float: right;
  line-height: 22px;
  background: #fff3f3;
  color: #cf0505;
  border-radius: 15px;
  font-size: 10px;
  padding: 0px 10px;
}
.csls-item .tx-right-g {
  float: right;
  line-height: 22px;
  background: #f1ffe8;
  color: #5c9834;
  border-radius: 15px;
  font-size: 10px;
  padding: 0px 10px;
}
.csls-item .bd ul li{ font-size: 10px; padding: 3px; line-height: 12px; color: #857939;}
.csls-answer {
  background: #f1ffe8;
  padding: 10px;
  color: #5c9834;
  margin-top: 10px;
  border-radius: 0 15px 15px 15px;
  border: 1px solid #d2e8c3;
}
</style>

<style>
.nav-icon-right .nut-navbar__title .nut-icon {
  position: absolute !important;
  right: 10px !important;
}
</style>