
export function isBlank(obj) {
  return obj === undefined || obj === null || obj === '';
}

export function getCurrencySymbol() {
  const commonInfo = JSON.parse(window.localStorage.getItem('commonInfo'));
  return isBlank(commonInfo) ? '' : commonInfo['currencySymbol']
}

export function withCurrencySymbol(amount) {
  return isBlank(amount) ? '' : getCurrencySymbol() + amount;
}
