import {isNotBlank} from "@/assets/js/commonUtil";

/**
 * 注册
 * @param inviteCode
 */
export function eventTrackingRegister(inviteCode, inviterId) {
    console.log("[eventTrackingRegister] " + inviteCode + "," + inviterId);

    //套壳App
    shellAppEvent('register', inviteCode);

    //tiktok
    try {
        window.bb88.onEvent("signup", JSON.stringify(
            {
                "user_id": inviteCode,
            }
        ));
    } catch (e) {
        console.log("eventTrackingRegister exception: ", e)
    }

    //源头上包
    try {
        window.jsBridge.postMessage("register", JSON.stringify(
            {
                "user_id": inviteCode,
            }
        ));
    } catch (e) {
        console.log("eventTrackingPay[yuantou] exception: ", e)
    }

    //Meta
    if (isNotBlank(inviterId) && inviterId === 209143) {
        try {
            window.fbq('track', 'Lead', {content_ids: inviteCode});
            console.log("Meta eventTrackingRegister")
        } catch (e) {
            console.log("Meta eventTrackingRegister:", e)
        }
    }
}

/**
 * 登录
 * @param inviteCode
 */
export function eventTrackingLogin(inviteCode, inviterId) {
    console.log("[eventTrackingLogin] " + inviteCode + "," + inviterId);
    //tiktok
    try {
        window.bb88.onEvent("login", JSON.stringify(
            {
                "user_id": inviteCode,  //用户唯一标识
            }
        ));
    } catch (e) {
        console.log("eventTrackingLogin exception: ", e)
    }
}

/**
 * 付费
 * @param inviteCode
 * @param amount
 */
export function eventTrackingPay(inviteCode, inviterId, amount, firstTime) {
    console.log("[eventTrackingPay] " + inviteCode + "," + inviterId + "," + amount + "," + firstTime);

    //套壳App
    shellAppEvent('play', inviteCode);

    //tiktok
    try {
        window.bb88.onEvent("pay", JSON.stringify(
            {
                "user_id": inviteCode,
                "amount": amount
            }
        ));
    } catch (e) {
        console.log("eventTrackingPay exception: ", e)
    }

    //源头上包
    try {
        if (firstTime) {
            window.jsBridge.postMessage("firstrecharge", JSON.stringify(
                {
                    "user_id": inviteCode,
                    "amount": amount
                }
            ));
        } else {
            window.jsBridge.postMessage("recharge", JSON.stringify(
                {
                    "user_id": inviteCode,
                    "amount": amount
                }
            ));
        }
    } catch (e) {
        console.log("eventTrackingPay[yuantou] exception: ", e)
    }

    //Meta
    if (isNotBlank(inviterId) && inviterId === 209143) {
        try {
            window.fbq('track', 'Purchase', {content_ids: inviteCode, currency: 'Rs', value: amount});
            console.log("Meta eventTrackingPay")
        } catch (e) {
            console.log("Meta eventTrackingPay:", e)
        }
    }
}

/**
 * 提现
 * @param inviteCode
 * @param amount
 */
export function eventTrackingWithdraw(inviteCode, inviterId, amount) {
    console.log("[eventTrackingWithdraw] " + inviteCode + "," + inviterId + "," + amount);

    //源头上包
    try {
        window.jsBridge.postMessage("withdrawOrderSuccess", JSON.stringify(
            {
                "user_id": inviteCode,
                "amount": amount
            }
        ));
    } catch (e) {
        console.log("eventTrackingPay[yuantou] exception: ", e)
    }
}

function shellAppEvent(ename, uid) {
    //eslint-disable-next-line
    if (window['luck_m'] && window['luck_m']['onEvent_andironsBranch'] && luck_m.onEvent_andironsBranch != null) {
        //eslint-disable-next-line
        luck_m.onEvent_andironsBranch(ename, uid);
    }
}
