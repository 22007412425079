<template>
  <h4>{{ $t('userRegister.privacyPolicy') }}</h4>
  <div class="txbd">
    <p>{{$t('privacyPolicy.p1')}}</p>
    <p>{{$t('privacyPolicy.p2')}}</p>
    <p>{{$t('privacyPolicy.p3')}}</p>
    <p>{{$t('privacyPolicy.p4')}}</p>
    <p>{{$t('privacyPolicy.p5')}}</p>
    <p>{{$t('privacyPolicy.p6')}}</p>
    <p>{{$t('privacyPolicy.p7')}}</p>
    <p>{{$t('privacyPolicy.p8')}}</p>
    <p>{{$t('privacyPolicy.p9')}}</p>
    <p>{{$t('privacyPolicy.p10')}}</p>
    <p>{{$t('privacyPolicy.p11')}}</p>
    <p>{{$t('privacyPolicy.p12')}}</p>
    <p>{{$t('privacyPolicy.p13')}}</p>
    <p>{{$t('privacyPolicy.p14')}}</p>
    <p>{{$t('privacyPolicy.p15')}}</p>
    <p>{{$t('privacyPolicy.p16')}}</p>
    <p>{{$t('privacyPolicy.p17')}}</p>
    <p>{{$t('privacyPolicy.p18')}}</p>
    <p>{{$t('privacyPolicy.p19')}}</p>
    <p>{{$t('privacyPolicy.p20')}}</p>
    <p>{{$t('privacyPolicy.p21')}}</p>
    <p>{{$t('privacyPolicy.p22')}}</p>
    <p>{{$t('privacyPolicy.p23')}}</p>
    <p>{{$t('privacyPolicy.p24')}}</p>
    <p>{{$t('privacyPolicy.p25')}}</p>
    <p>{{$t('privacyPolicy.p26')}}</p>
    <p>{{$t('privacyPolicy.p27')}}</p>
    <p>{{$t('privacyPolicy.p28')}}</p>
    <p>{{$t('privacyPolicy.p29')}}</p>
    <p>{{$t('privacyPolicy.p30')}}</p>
    <p>{{$t('privacyPolicy.p31')}}</p>
    <p>{{$t('privacyPolicy.p32')}}</p>
    <p>{{$t('privacyPolicy.p33')}}</p>
    <p>{{$t('privacyPolicy.p34')}}</p>
    <p>{{$t('privacyPolicy.p35')}}</p>
    <p>{{$t('privacyPolicy.p36')}}</p>
    <p>{{$t('privacyPolicy.p37')}}</p>
    <p>{{$t('privacyPolicy.p38')}}</p>
    <p>{{$t('privacyPolicy.p39')}}</p>
    <p>{{$t('privacyPolicy.p40')}}</p>
    <p>{{$t('privacyPolicy.p41')}}</p>
    <p>{{$t('privacyPolicy.p42')}}</p>
    <p>{{$t('privacyPolicy.p43')}}</p>
    <p>{{$t('privacyPolicy.p44')}}</p>
    <p>{{$t('privacyPolicy.p45')}}</p>
    <p>{{$t('privacyPolicy.p46')}}</p>
    <p>{{$t('privacyPolicy.p47')}}</p>
    <p>{{$t('privacyPolicy.p48')}}</p>
    <p>{{$t('privacyPolicy.p49')}}</p>
    <p>{{$t('privacyPolicy.p50')}}</p>
    <p>{{$t('privacyPolicy.p51')}}</p>
    <p>{{$t('privacyPolicy.p52')}}</p>
    <p>{{$t('privacyPolicy.p53')}}</p>
    <p>{{$t('privacyPolicy.p54')}}</p>
    <p>{{$t('privacyPolicy.p55')}}</p>
    <p>{{$t('privacyPolicy.p56')}}</p>
    <p>{{$t('privacyPolicy.p57')}}</p>
    <p>{{$t('privacyPolicy.p58')}}</p>
    <p>{{$t('privacyPolicy.p59')}}</p>
    <p>{{$t('privacyPolicy.p60')}}</p>
    <p>{{$t('privacyPolicy.p71')}}</p>
    <p>{{$t('privacyPolicy.p72')}}</p>
    <p>{{$t('privacyPolicy.p73')}}</p>
    <p>{{$t('privacyPolicy.p74')}}</p>
    <p>{{$t('privacyPolicy.p75')}}</p>
    <p>{{$t('privacyPolicy.p76')}}</p>
    <p>{{$t('privacyPolicy.p77')}}</p>
    <p>{{$t('privacyPolicy.p78')}}</p>
    <p>{{$t('privacyPolicy.p79')}}</p>
    <p>{{$t('privacyPolicy.p80')}}</p>
    <p>{{$t('privacyPolicy.p81')}}</p>
    <p>{{$t('privacyPolicy.p82')}}</p>
    <p>{{$t('privacyPolicy.p83')}}</p>
    <p>{{$t('privacyPolicy.p84')}}</p>
    <p>{{$t('privacyPolicy.p85')}}</p>
    <p>{{$t('privacyPolicy.p86')}}</p>
    <p>{{$t('privacyPolicy.p87')}}</p>
    <p>{{$t('privacyPolicy.p88')}}</p>
    <p>{{$t('privacyPolicy.p89')}}</p>
    <p>{{$t('privacyPolicy.p90')}}</p>
    <p>{{$t('privacyPolicy.p91')}}</p>
    <p>{{$t('privacyPolicy.p92')}}</p>
    <p>{{$t('privacyPolicy.p93')}}</p>
    <p>{{$t('privacyPolicy.p94')}}</p>
    <p>{{$t('privacyPolicy.p95')}}</p>
    <p>{{$t('privacyPolicy.p96')}}</p>
    <p>{{$t('privacyPolicy.p97')}}</p>
    <p>{{$t('privacyPolicy.p98')}}</p>
    <p>{{$t('privacyPolicy.p99')}}</p>
  </div>
</template>

<script>

export default {
  name: 'PrivacyPolicy',
  props: {},
  data() {
    return {};
  },
  methods: {
  },
  created() {
  },
  setup() {}
};
</script>

<style scoped>
</style>
