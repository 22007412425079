<template>
  <div>
    <nut-navbar
      :left-show="false"
      :title="$t('rechargeList.h1')"
      fixed=true
      class="mb0 nav-icon-right nut-navbar--fixed">
      <template #left>
        <i class="nutui-iconfont nut-icon nut-icon-left" @click="backClick"></i>
      </template>
    </nut-navbar>


    <div class="pt50 scroll-body">
      <div class="p20">

          <ListEmpty v-show="listNoData"></ListEmpty>

          <div class="csls" id="scroll">
            <nut-infiniteloading
                containerId = 'scroll'
                :use-window='false'
                :has-more="hasNext"
                :load-txt='$t("global.loading")'
                :load-more-txt='$t("global.noMore")'
                :load-icon= 'null'
                @load-more="loadMore"
            >
              <div
                class="csls-item"
                v-for="(item, index) in list"
                :key="index">
                <h4>
                  <span :class="item.warning ? 'fc-red' : 'fc-green'">{{ item.orderNum }}</span>
                  <span :class="item.warning ? 'tx-right-r' : 'tx-right-g'">
                    <i v-if="item.status === 'C2C_USER_CONFIRM'">{{$t('c2c.status_success')}}</i>
                    <i v-else>{{ item.status }}</i>
                  </span>
                </h4>
                <div class="bd">{{$t("rechargeList.applyAmount")}}  : {{ item.applyAmount }}</div>
                <p>{{$t("rechargeList.giftAmount")}} : {{ item.giftAmount }}</p>
                <p>{{ item.createTime }}</p>
              </div>
            </nut-infiniteloading>
          </div>

      </div>
    </div>

    <nut-drag direction="y" :style="{ top: '350px', right:'5px', zIndex: '1000 !important'}" @click="showPopUps = true">
      <div class="drag-wb-history">
        <img :src="require('../assets/images/global_icon/202405-66.png')"/>
      </div>
    </nut-drag>

    <nut-popup pop-class="pop-demo" v-model:visible="showPopUps" :style="{height: '200px', width: '300px'}">
        <div class="hd">{{ $t('withdrawal.tx_contact_us') }}</div>
        <div class="bd">
          If there is any problem with the deposit, please contact us in the <span style="font-weight: 600; color: #007f59;">Support Center</span> or through
          <strong style="color: #8e4e00;">{{$t('bankCard.email')}}: {{ eMail }}</strong>
          , working hours: 7:00-20:00
        </div>
        <div class="btn" style="margin: 20px 0; padding: 0;">
          <nut-button  type="primary" @click="showPopUps = false" 
            style=" width: 40%; margin: 0 1%;border: 1px solid #333; 
            background: #fff; color: #000;">{{ $t('game.gotitBtn') }}</nut-button>
          <nut-button type="primary" @click="toHelp"  style=" width:55%; margin: 0 1%;border: 1px solid #333;color: #000; ">{{ $t('HELP.H1') }}</nut-button>

          <!-- <nut-button v-if="commonInfo.supportCenterOn"  type="primary" @click="toHelp"  style=" width:55%; margin: 0 1%;border: 1px solid #333;color: #000; ">{{ $t('HELP.H1') }}</nut-button> -->
        </div>
      </nut-popup>

      
  </div>
</template>

<script>
import { reactive, toRefs, onMounted} from "vue";
import { useRouter } from "vue-router";
import axios from "axios";
import { Toast } from '@nutui/nutui';
import ListEmpty from "../components/ListEmpty.vue"
import { isBlank } from '@/assets/js/commonUtil';
import { getCountry } from "@/assets/js/environment";
export default {
  components: {
    ListEmpty: ListEmpty,
  },
  data() {
    return {
      amountType:'',
      listNoData: true,
      list: [],
      showPopUps: false,
      current: 1,
      hasNext:'',
      country: '',
    };
  },
  methods: {
    loadMore(done){
      if(this.hasNext){
        this.getCoinList(this.current + 1)
        setTimeout(() => {
          done()
        }, 500);
      }
    },
    filters(s) {
      let txt = "";
      switch (s) {
        case 'refund':
          txt = "Refund";
          break;
        case 'processing':
          txt = "Processing";
          break;
        case 'success':
          txt = "Success";
          break;
        case 'failed':
          txt = "Failed";
          break;
      }
      return txt;
    },
    getCoinList(c) {
      axios({
        url: "/api/fund/recharge/paginate",
        method: "get",
        params: { current: c}
      }).then((res) => {
        for (let i = 0; i < res.data.data.records.length; i++) {
          this.list.push(res.data.data.records[i]);
        }
        this.current = res.data.data.current;
        this.hasNext = res.data.data.hasNext;
        if (this.list.length > 0) {
          this.listNoData = false;
        }
        Toast.hide("loading")
      }).catch(function (error) {
          console.log(error);
          Toast.hide("loading");
      })
    },
    textToast(msg) {
      Toast.loading(msg, {
        id:"loading",
        duration: 0,
        bgColor: "rgba(0, 0, 0, 0.9)",
        cover:true,
      });
    },
  },
  created() {
    this.textToast(this.$t('global.loading'))
    this.getCoinList(1);
    this.country = getCountry()
  },
  setup() {
    const state = reactive({
      eMail:'',
      commonInfo: JSON.parse(window.localStorage.getItem("commonInfo")),
    });
    onMounted(() => {
      state.eMail = isBlank(state.commonInfo) ? ' ': state.commonInfo.serviceEmail
    });

    const router = useRouter();
    const methods = {
      backClick() {
        router.go(-1);
      },
      toHelp() {
        router.push({
          name: "helpSupport",
        });
      },
    };
    return {
      ...toRefs(state),
      ...methods,
    };
  },
};
</script>

<style scoped>
.ml20{ margin-left: 20px;}
.fc-red{ font-weight: 300;}
.fc-green{font-weight: 300;}
.nut-pagination {
  margin-top: 10px;
  justify-content: center;
}
.csls{
  overflow-y: auto;
  overflow-x: hidden;
  position: absolute;
  left: 10px;right: 10px;top: 60px; bottom: 10px;
}
.csls-item {
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #eee;
}
.csls-item h4 {
  margin: 5px 0;
  line-height: 24px;
}

.csls-item p {
  line-height: 24px;
  padding: 0;
  margin: 0;
  color: #999;
}
.csls-item .tx-right-r {
  float: right;
  line-height: 22px;
  background: #fff3f3;
  color: #cf0505;
  border-radius: 15px;
  font-size: 10px;
  padding: 0px 10px;
}
.csls-item .tx-right-g {
  float: right;
  line-height: 22px;
  background: #f1ffe8;
  color: #5c9834;
  border-radius: 15px;
  font-size: 10px;
  padding: 0px 10px;
}
.csls-item h4 .tx-right-r,
.csls-item h4 .tx-right-g {
  font-weight: 300;
}
.csls-answer {
  background: #f1ffe8;
  padding: 10px;
  color: #5c9834;
  margin-top: 10px;
  border-radius: 0 15px 15px 15px;
  border: 1px solid #d2e8c3;
}
.csls-item .right-btn {
  float: right;
}
</style>

<style>
.nav-icon-right .nut-navbar__title .nut-icon {
  position: absolute !important;
  right: 10px !important;
}
</style>