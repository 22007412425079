import {isBlank, isNotBlank} from "@/assets/js/commonUtil";
import io from "socket.io-client";
import {Dialog, Toast} from "@nutui/nutui";
import router from "@/router";
import {getDeviceId} from "@/assets/js/visitor";
import axios from "axios";

/**
 * 游戏类型
 * @type {{mine: string, parity: string, crash: string}}
 */
export const gameType = {
    CRASH: "crash",
    PARITY: "parity",
    MINE: "mine",
    WHEEL: 'wheel',
    DICE: 'dice'
}

/**
 * 初始化游戏
 * @returns {Promise<AxiosResponse<any>>}
 */
export async function initGame(gameType) {
    const deviceId = await getDeviceId();
    localStorage.setItem('di', deviceId);
    return axios.get('/api/' + gameType + '/init', {})
}

//==================== socket ====================
/**
 * socket通用事件类型
 * @type {{logout: string, ready: string, join: string, errorMsg: string}}
 */
export const commonEvent = {
    ready: "s_ready",
    errorMsg: "s_error_msg",
    logout: "s_logout",
    join: "c_join",
    bigWin: "s_big_win",
    broadcastMsg: "s_broadcast_msg",
}

/**
 * 每个游戏socket专属加载中的遮罩id
 * @param gameType
 * @returns {string}
 */
function getSocketLoadingId(gameType) {
    return gameType + "-loading";
}

/**
 * 创建Socket client
 * @param gameType
 */
export function createSocket(gameType, bigwinData, broadcastMsgData) {
    let socketLoadingId = getSocketLoadingId(gameType);

    let socketParam = {};
    let token = localStorage.getItem("token")
    if (isBlank(token)) {
        let visitorToken = localStorage.getItem("vtoken");
        if (isBlank(visitorToken)) {
            console.log("cannot find visitor token, socket client will not be created.")
            return;
        } else {
            socketParam.vtoken = visitorToken;
        }
    } else {
        socketParam.token = token;
    }

    //创建socket client
    let socket = io(
        {
            path: '/ws/' + gameType,
            query: socketParam,
            reconnectionAttempts: 3,
            autoConnect: false,
            transports: ['websocket']
        });

    //连接成功
    socket.on('connect', () => {
        Toast.hide(socketLoadingId);
    });

    //试图重连
    socket.on('reconnect_attempt', () => {
        Toast.loading("connecting", {
            id: socketLoadingId,
            duration: 0
        });
    });

    //重连失败
    socket.on('reconnect_failed', () => {
        Toast.hide(socketLoadingId);
        Dialog({
            content: 'Please check your network!',
            noCancelBtn: true,
            okText: 'Retry',
            onOk: () => {
                router.go(0);
                // connectSocket(gameType, socket);
            }
        });
    });

    //断开连接
    socket.on('disconnect', () => {
        Toast.hide(socketLoadingId);
    });

    //错误信息
    socket.on(commonEvent.errorMsg, (msg) => {
        Toast.fail(msg);
    });

    //登出
    socket.on(commonEvent.logout, () => {
        router.push('/userLogin');
    });

    //BigWin
    socket.on(commonEvent.bigWin, (event) => {
        bigwinData.list.push(event)
        if(!bigwinData.start){
            bigwinData.start = true
            if(bigwinData.list.length > 0){
                bigwinData.data = bigwinData.list[0]
                bigwinData.show = true
                bigwinData.list.splice(0,1)
                setTimeout(() => {
                    bigwinData.show = false
                    bigwinData.start = false
                },5000)
            }
        }
    });

    //Broadcast Msg
    socket.on(commonEvent.broadcastMsg, (event) => {
        broadcastMsgData.type = event.type
        broadcastMsgData.message = event.message
        broadcastMsgData.show = true
        setTimeout(() => {broadcastMsgData.show = false},8000)
    });

    //连接
    connectSocket(gameType, socket);
    return socket;
}

/**
 * 手动连接socket
 * @param gameType
 * @param socket
 */
export function connectSocket(gameType, socket) {
    if (isBlank(socket) || socket.connected) {
        //已经连接
        return;
    }
    Toast.loading('connecting', {
        id: getSocketLoadingId(gameType),
        duration: 0
    });
    socket.connect();
}

/**
 * 断开socket
 * @param gameType
 * @param socket
 */
export function disconnectSocket(gameType, socket) {
    if (isNotBlank(socket)) {
        socket.disconnect();
        socket = null;
    }
    Toast.hide(getSocketLoadingId(gameType));
}
