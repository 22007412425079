<template>
  <div>
    <nut-navbar
      :left-show="false"
      @on-click-icon="iconClick"
      :title="$t('invite.h1')"
      titIcon="horizontal"
      fixed=true
      class="mb0 nav-icon-right nut-navbar--fixed nut-navbar-b0"
    >

    <!-- <template #left>
      <img src="../assets/images/global_icon/202405-65.png" @click="contactUsClick" style="width: 30px;">
    </template> -->


    </nut-navbar>

    <div class="agent-main">
      
      <div class="agent-top">
        <div class="agent-info">
          <div class="agent-info-l">
            <div class="a-i-t">
              <span class="tx">{{$t('invite.agentAmount')}}</span>
              <strong class="aa">
                {{ $filters.currencySymbol(inviteInfo.availableAmount) }}
              </strong>
              <div :class="'lv lv_'+ cLevel" @click="showLvTable"><span>{{ inviteInfo.vipLevel }}</span></div>
              <div class="btn">
                <nut-button plain type="info" size="mini" @click="withdrawClick('cash')">{{$t('invite.withdraw')}}</nut-button>
              </div>
            </div>


            <div class="flbd">
              <table>
                <tr>
                  <th @click="infoToast(tx)">Total Invite<i class="icon-new icon-i1"></i></th>
                  <th>Total Effective Invite</th>
                  <th>Total Income</th>
                </tr>
                <tr>
                  <td>{{ inviteInfo.totalInviteCount }}</td>
                  <td>{{ inviteInfo.totalEffectiveCount }}</td>
                  <td>{{ $filters.currencySymbol(inviteInfo.totalCashIncomeAmount) }}</td>
                </tr>
                <tr>
                  <th @click="infoToast(tx)">Today Invite<i class="icon-new icon-i1"></i></th>
                  <th>Today Effective Invite</th>
                  <th>Today Income</th>
                </tr>
                <tr>
                  <td>{{ inviteInfo.todayInviteCount }}</td>
                  <td>{{ inviteInfo.todayEffectiveCount }}</td>
                  <td>{{ $filters.currencySymbol(inviteInfo.todayCashIncomeAmount) }}</td>
                </tr>

              </table>
              <div class="btn" @click="historyList()" >Income History >></div>
            </div>


          </div>
        </div>
        <!-- <div class="agent-vip" @click="growthPlan">
          <img class="img100" :src="require('../assets/images/global/bg67c.png')" />
        </div> -->
      </div>





      <div class="ap-bn">
        <img  @click="growthPlanPro" v-if="forbidCashUser" class="img100" src="../assets/images/bg319.jpg" />

        <img @click="toReferBonus" v-if="!show_w3" class="img100" src="../assets/images/bn5.jpg" style="border-radius: 10px;" />
        <img @click="toReferBonus" v-else class="img100" src="../assets/images/bn3b.jpg" style="border-radius: 10px;" />

        <img @click="growthPlan" class="img100" src="../assets/images/bn2.jpg" style="border-radius: 10px;" />
        <img @click="showRound = true" class="img100" src="../assets/images/global/bg317.png" />
      </div>


      <div class="agent-table">
        
        <h4><span class="icon-a">4-Level Agent System</span></h4> 
        <div class="tab-bd"> 

          <div class="tab-bd-img">


            <div class="tab-bd-4x">
              <dl>
                <dt>{{$t('invite.Grade')}}:1</dt>
                <dd><span>{{$t('invite.Bonuss')}}:</span><strong>30%</strong></dd>
              </dl>
              <dl>
                <dt>{{$t('invite.Grade')}}:2</dt>
                <dd><span>{{$t('invite.Bonuss')}}:</span><strong>20%</strong></dd>
              </dl>
              <dl>
                <dt>{{$t('invite.Grade')}}:3</dt>
                <dd><span>{{$t('invite.Bonuss')}}:</span><strong>7%</strong></dd>
              </dl>
              <dl>
                <dt>{{$t('invite.Grade')}}:4</dt>
                <dd><span>{{$t('invite.Bonuss')}}:</span><strong>3%</strong></dd>
              </dl>

            </div>
          </div>

        </div>
      </div>

      <div class="agent-table">

        <div class="tab-bd">
          <table class="table-th">
            <tr>
              <th></th>
              <td></td>
            </tr>
          
            <tr>
              <th>Bet Rebate Commission</th>
              <td></td>
            </tr>

          </table>
          <div class="table-td">
            <table>
              <tr>
                <th v-for="(item, index) in asList" :key="index">{{$t('invite.affinity')}}{{item.affinity}}</th>
              </tr>

              <tr>
                <td v-for="(item, index) in asList" :key="index">{{ $filters.currencySymbol(item.subBetCommissionAmount) }}</td>
              </tr> 

            </table>
          </div>
        </div>
      </div>

      <div class="tg-btn"  style=" padding-bottom: 50px;" >
          <img  src="../assets/images/global/bg208.png" @click="telegramClick"/>
      </div> 



      <nut-popup position="bottom" closeable round style="height: '53%';z-index:3000; background: #30284C; " v-model:visible="showRound">
        <div class="copy-link">
          <h4>{{$t('invite.h4')}}</h4>
          <strong class="ft30">{{ inviteInfo.inviteCode }}</strong>
          <p>{{$t('invite.h4Title')}}</p>
          <nut-button block type="info" @click="copyLink(inviteInfo.inviteCode)">{{$t('invite.btn')}}</nut-button>
          <p>{{$t('invite.to')}}</p>
        </div>

        <div class="copy-link-dl">
          <dl>
            <ShareNetwork v-for="network in networks" :key="network.network"
                :network="network.network"
                :url="sharing.url"
                :title="sharing.title"
                :description="sharing.description"
                :quote="sharing.quote"
                :hashtags="sharing.hashtags"
                :twitterUser="sharing.twitterUser"
              >
              <i class="fab fah fa-lg fa-twitter"></i>
              <dd :class="network.class"><span>{{ network.name }}</span></dd>
            </ShareNetwork>
          </dl>
        </div>
      </nut-popup>
    </div>

    <!--**************************************** 活动弹框 ****************************************-->
    <nut-popup pop-class="pop-ab" v-model:visible="abPopup">
      <div class="bd" style="background: none !important;" >

        <nut-swiper v-if="show_w4" :init-page="1" :pagination-visible="true"  pagination-color="#426543" auto-play="6000" :is-preventDefault="false">
          <nut-swiper-item  @click="abPopup = false">
            <img  :src="require('../assets/images/pop1.png')" />
          </nut-swiper-item>
        </nut-swiper>

        <nut-swiper v-if="show_w3" :init-page="1" :pagination-visible="true"  pagination-color="#426543" auto-play="3000" :is-preventDefault="false">
          <nut-swiper-item @click="toReferBonus">
            <img  :src="require('../assets/images/pop2.png')" />
          </nut-swiper-item>
        </nut-swiper>

      </div>
      <nut-button type="primary" @click="abPopup = false">{{ $t('game.gotitBtn') }}</nut-button>
    </nut-popup>

    <nut-popup pop-class="pop-demo" v-model:visible="showContactUs" :style="{height: '200px', width: '250px'}">
        <div class="hd">{{ $t('withdrawal.tx_contact_us') }}</div>
        <div class="bd">
          {{$t('withdrawal.tx4b')}}<strong>
          {{$t('bankCard.email')}}: {{ eMail }}</strong>{{$t('withdrawal.tx5_2')}} 
        </div>
        <div class="btn" style="margin: 20px 0; padding: 0;">
          <nut-button  type="primary" @click="showContactUs = false" 
            style=" width: 48%; margin: 0 1%;border: 1px solid #333; 
            background: #fff; color: #000;">{{ $t('game.gotitBtn') }}</nut-button>
          <!-- <nut-button v-if="commonInfo.supportCenterOn"  type="primary" @click="toHelp"  style=" width:48%; margin: 0 1%;">{{ $t('HELP.H1') }}</nut-button> -->
        </div>
      </nut-popup>


      <!--**************************************** lv 等级弹框 ****************************************-->
    <nut-popup pop-class="pop-lv" v-model:visible="lvTable">
      <div class="hd">Level Description</div>
        <div class="bd">
          <table>
            <tr>
              <th>Agent Level</th><th>Total Income</th>
            </tr>
            <tr>
              <td>Lv0</td><td class="ta-r">0</td>
            </tr>
            <tr>
              <td>Lv1</td><td class="ta-r">200</td>
            </tr>
            <tr>
              <td>Lv2</td><td class="ta-r">1000</td>
            </tr>
            <tr>
              <td>Lv3</td><td class="ta-r">5000</td>
            </tr>
            <tr>
              <td>Lv4</td><td class="ta-r">10000</td>
            </tr>
            <tr>
              <td>Lv5</td><td class="ta-r">50000</td>
            </tr>
            <tr>
              <td>Lv6</td><td class="ta-r">100000</td>
            </tr>
            <tr>
              <td>Lv7</td><td class="ta-r">300000</td>
            </tr>
            <tr>
              <td>Lv8</td><td class="ta-r">500000</td>
            </tr>
            <tr>
              <td>Lv9</td><td class="ta-r">1000000</td>
            </tr>
            <tr>
              <td>Lv10</td><td class="ta-r">100000000</td>
            </tr>
            <tr>
              <td>Lv11</td><td class="ta-r">500000000</td>
            </tr>
          </table>
        </div>
      <nut-button type="primary" @click="lvTable = false">{{ $t('game.gotitBtn') }}</nut-button>
    </nut-popup>

    <nut-drag direction="y" :style="{ bottom: '60px', right:'5px', zIndex: '1000 !important'}"  @click="toHelp" >
      <div class="drag-wb-history">
        <img :src="require('../assets/images/global_icon/202405-66.png')"/>
      </div>
    </nut-drag>

    <tabbar :activeIndex="tabbarActiveIndex"></tabbar>
  </div>
</template>

<script>
import { reactive, toRefs, onMounted,getCurrentInstance } from "vue";
import { useRouter } from "vue-router";
import TabbarMain from "../components/TabbarMain.vue";
import axios from "axios";
import {Dialog, Toast} from "@nutui/nutui";

export default {
  components: {
    tabbar: TabbarMain,
  },
  data() {
    return {
      s: '',
      imgUrl: '',
      commonInfo:'',
      telegramChannelUrl:'',
      lvTable: false,
      sharing: {
        url: this.$t('invite.url'),
        title: this.$t('invite.title'),
        description: this.$t('invite.description'),
        quote: this.$t('invite.quote'),
        hashtags: this.$t('invite.hashtags'),
        twitterUser: this.$t('invite.twitterUser')
      },
      i18n:{
        loading: this.$t('global.loading'),
        copied: this.$t('global.Your_invitation_link_has_been_copied'), 
      }
    };
  },
  created() {
    this.imgUrl = localStorage.getItem('countryImgUrl')
    this.s = document.querySelector('body').getAttribute('s');
    this.commonInfo = JSON.parse(window.localStorage.getItem('commonInfo'))
    this.telegramChannelUrl = this.commonInfo.telegramChannelUrl
  },
  methods: {
    showLvTable(){
      this.lvTable = true
    },
    infoToast(msg){
      Dialog({
        content: msg,
        okText: this.$t('global.okText'),
        noCancelBtn: true
      });
    },
    telegramClick(){
      Toast.loading("Loading", {
        id: "loading",
        duration: 0,
        bgColor: "rgba(0, 0, 0, 0.9)",
        cover: true,
      });
      window.open(this.telegramChannelUrl, "_self");
    },
  },
  setup() {
    const _data = getCurrentInstance();
    const state = reactive({
      commonInfo: JSON.parse(window.localStorage.getItem("commonInfo")),
      tabActive:true,
      showRound: false,
      rewardPopup: false,
      cLevel: 0,
      s: '',
      tx:'',
      currentDate:{
        year: '',
        month: '',
        day: '',
        week: '',
        hours: '',
      },
      showDate: {
        year: '',
        month: '',
        day: '',
        week: '',
      },
      EndDate: {
        year: '',
        month: '',
        day: '',
        week: '',
      },
      showContactUs: false,
      abPopup: false,
      show_w3: false,
      show_w4: false,
      show_m9: false,
      limitedTime:false,
      forbidCashUser:false,
      periodTime: '',
      tabbarActiveIndex: 2,
      effectiveCount: "",
      registerReward:'',
      asList:[],
      inviteInfo: {
        availableAmount: "",
        inviteCode: "",
        inviteCount: "",
        inviteeRewardAmount: "",
        maxInviteRewardAmount: "",
        vipLevel: "",
        todayCashIncomeAmount: null,
        todayEffectiveCount: null,
        todayInviteCount: null,
        totalCashIncomeAmount: null,
        totalEffectiveCount: null,
        totalInviteCount: null,
      },
      networks: [
        { network: 'facebook', name: 'Facebook', icon: 'fab fah fa-lg fa-facebook-f', class: 'i1' },
        { network: 'twitter', name: 'Twitter', icon: 'fab fah fa-lg fa-twitter', class: 'i2' },
        { network: 'messenger', name: 'Messenger', icon: 'fab fah fa-lg fa-facebook-messenger', class: 'i3' },
        { network: 'telegram', name: 'Telegram', icon: 'fab fah fa-lg fa-telegram-plane', class: 'i4' },
        { network: 'whatsapp', name: 'Whatsapp', icon: 'fab fah fa-lg fa-whatsapp', class: 'i5' }
      ]
    });
    const router = useRouter();
    const iconClick = () => {
      router.push({
        path: "transactionsHistoryList",
        query: {
          listType: 'cash'
        }
      })
    };
    
    const tabToggle = (cls) => {
      state.tabActive = cls === 'active' ? true : false
    };
    const withdrawClick = (t) => {
      router.push({
        path: 'withdrawalBalance',
				query: {
					amountType: t
				}
			})
    };
    const growthPlan = () => {
      router.push({
        name: "agentGrowth",
      });
    };
    const growthPlanPlus = () => {
      router.push({
        name: "agentGrowthPlus",
      });
    };
    const growthPlanPro = () => {
      router.push({
        name: "promotionPartner",
      });
    };
    const transferClick = () => {
        router.push({
          name: "cashTransfer",
        });
    };
    const toAgentSupportPlan = () => {
        router.push({
          name: "agentSupportPlan",
        });
    };

    

    const copyLink = () => {
      var cInput = document.createElement("input")
      cInput.value = _data.data.sharing.url
      document.body.appendChild(cInput)
      cInput.select()
      document.execCommand("copy")
      Toast.success(_data.data.i18n.copied)
      document.body.removeChild(cInput)
    };
    const methods = {
      textToast(msg) {
        Toast.loading(msg, {
          id: "loading",
          duration: 0,
          bgColor: "rgba(0, 0, 0, 0.9)",
          cover: true,
        });
      },
      toTelegram(){
          const commonInfo = JSON.parse(window.localStorage.getItem("commonInfo"));
          window.open(commonInfo.telegramChannelUrl,"_self");
      },
      contactUsClick(){
       state.showContactUs = true
      },
      historyList(){
        router.push({
          name: "inviteHistoryBonus",
        });
      },
      toHelp() {
        router.push({ path: '/helpSupport' });
      },
      toReferBonus(){
        state.abPopup = false
        router.push({
          name: "referBonus",
        });
      },
    };

    onMounted(() => {
      methods.textToast(_data.data.i18n.loading);
      axios
        .get("/api/invite", {})
        .then(function (response) {
          state.asList = response.data.data.affinityStatisticsList;
          state.inviteInfo.availableAmount =response.data.data.fundAccount.availableAmount;
          state.inviteInfo.inviteCode = response.data.data.inviteCode;
          localStorage.setItem("inviteCode", response.data.data.inviteCode);
          state.inviteInfo.inviteCount = response.data.data.inviteCount;
          state.inviteInfo.inviteeRewardAmount = response.data.data.inviteeRewardAmount;
          state.inviteInfo.maxInviteRewardAmount = response.data.data.maxInviteRewardAmount;
          state.inviteInfo.vipLevel = response.data.data.vipLevel;
          state.effectiveCount = response.data.data.effectiveCount;
          state.forbidCashUser = response.data.data.forbidCashUser;
          Toast.hide("loading");
          state.registerReward = state.commonInfo.registerReward;
          _data.data.sharing.url = state.commonInfo.frontBaseUrl + '?invite=' + state.inviteInfo.inviteCode
          state.inviteInfo.todayCashIncomeAmount = response.data.data.todayCashIncomeAmount	//今日现金收入
          state.inviteInfo.todayEffectiveCount = response.data.data.todayEffectiveCount //今日有效邀请人数
          state.inviteInfo.todayInviteCount = response.data.data.todayInviteCount //今日邀请人数
          state.inviteInfo.totalCashIncomeAmount = response.data.data.totalCashIncomeAmount //累计现金收入
          state.inviteInfo.totalEffectiveCount = response.data.data.totalEffectiveCount //累计有效邀请人数
          state.inviteInfo.totalInviteCount = response.data.data.totalInviteCount //累计邀请人数
          if(response.data.data.totalCashIncomeAmount < 200){
            state.cLevel = 0
          }else if(response.data.data.totalCashIncomeAmount < 1000){
            state.cLevel = 1
          }else if(response.data.data.totalCashIncomeAmount < 5000){
            state.cLevel = 2
          }else if(response.data.data.totalCashIncomeAmount < 10000){
            state.cLevel = 3
          }else if(response.data.data.totalCashIncomeAmount < 50000){
            state.cLevel = 4
          }else if(response.data.data.totalCashIncomeAmount < 100000){
            state.cLevel = 5
          }else if(response.data.data.totalCashIncomeAmount < 300000){
            state.cLevel = 6
          }else if(response.data.data.totalCashIncomeAmount < 500000){
            state.cLevel = 7
          }else if(response.data.data.totalCashIncomeAmount < 1000000){
            state.cLevel = 8
          }else if(response.data.data.totalCashIncomeAmount < 100000000){
            state.cLevel = 9
          }else if(response.data.data.totalCashIncomeAmount < 500000000){
            state.cLevel = 10
          }else if(response.data.data.totalCashIncomeAmount >= 500000000){
            state.cLevel = 11
          }
          if(response.data.data.startTime){
            state.periodTime = response.data.data.startTime.slice(6,8) + '/' + response.data.data.startTime.slice(4,6) + '~' + response.data.data.endTime.slice(6,8) + '/' + response.data.data.endTime.slice(4,6)
          }

          state.tx = 'The users you invite must meet the minimum standard for the number of effective users required by the system (' + response.data.data.cmsRequireEffectivePerInvite + ' users, at least 1 becomes a effective user), otherwise it will not be counted. Invite more effective users and restore statistics!'

          const d = new Date()
          state.currentDate = {
            year: d.getFullYear(),
            month: d.getMonth() + 1, 
            day: d.getDate(),
            week: d.getDay(), //星期日 = 0
            hours: d.getHours(), //24小时制 0～24
          }          

          //星期3 1100活动
          state.show_w3 = (d.getDay() === 3)

          //星期4活动
          //state.show_w4 = (d.getDay() === 4)

          //每月9日活动
          //state.show_m9 = (d.getDate() >= 8) && (d.getDate() <= 10)

          //日期范围
          //state.limitedTime = (state.currentDate.month === 7 && d.getDate() >= 3) && (d.getDate() <= 5)

          state.abPopup = state.show_w3
        })
        .catch(function (error) {
          console.log(error);
          Toast.hide("loading");
        })

        
    });
    return {
      ...toRefs(state),
      ...methods,
      iconClick,
      tabToggle,
      withdrawClick,
      growthPlan,
      growthPlanPlus,
      growthPlanPro,
      copyLink,
      transferClick,
      toAgentSupportPlan,
      
    };
  },
};
</script>

<style scoped>


.nut-popup{background-color: #30284C !important;}
.img100{ width:96%; margin:0 2%;  }

.agent-table { border-radius: 10px; overflow: hidden;  background: #221C36;}
.agent-table h4{ margin: 0; padding: 5px 5px 5px 10px; line-height: 30px; color: #fff; font-size: 16px; color: #fff;
  background:linear-gradient(89deg,#3e00c1 .38%,#fa00ff 107.78%);position: relative; }
.agent-table h4 span{ color: #fff;}
.agent-table h4 .icon-a{background:url(../assets/images/global_icon/202405-23.png) left center no-repeat; background-size:28px; padding-left: 32px; }
.agent-table h4 .icon-b{background:url(../assets/images/global_icon/202405-36.png) left center no-repeat; background-size:28px; padding-left: 32px; }

.tab-hd{ height: 34px;position: relative; z-index: 105;}



.tab-hd li{ float: left;height: 30px; line-height: 30px; padding: 0 30px;border: 1px solid #ccc; border-top: 5px solid #ccc; border-bottom: 0;border-radius: 5px 5px 0 0; margin-right:3px;}
.tab-hd li.r{float: right;padding: 0px;border: 0px;border-radius:0;}
.tab-hd li.active{ border: 1px solid #3d2505; border-top: 5px solid #3d2505;  border-bottom: 0; height: 31px; background: #fff; font-weight: 600;  }
.tab-hd li span{ font-size: 14px; color: #333;}
.tab-hd li.active span{ color: #3d2505;}
.tab-bd { position: relative; z-index: 103; padding-top:10px;  padding-bottom:10px;}

.tab-bd-img dl{ min-height: 34px; font-size: 16px; background: #00EDA6; margin: 1px; border-radius: 3px 15px 3px 3px; width: 100%; text-align: center; padding-bottom: 5px;} 
.tab-bd-img dl.title{background: #30284C;padding-top: 13px; padding-left: 75px; text-align: left;  min-height: 50px; background:url(../assets/images/global_icon/202405-44.png) 5px 0 no-repeat; background-size:70px auto;}
.tab-bd-img dl.title dd{font-size: 16px; line-height: 16px; padding-top: 5px; color: #00EDA6; }
.tab-bd-4x dl:nth-child(1){  height: 170px;}
.tab-bd-4x dl:nth-child(2){ background: #18ca95; height: 140px;}
.tab-bd-4x dl:nth-child(3){ background: #25b187; height: 110px;}
.tab-bd-4x dl:nth-child(4){ background: #28886b;  height: 80px;}
.tab-bd-4x dl:nth-child(1) dd strong{ color: #ff5405;}
.tab-bd-4x dl:nth-child(2) dd strong{ color: #ffd500;}


.tab-bd-img dd span{font-size: 12px; color: #000;}
.tab-bd-img dd{color: #000;}
.tab-bd-img dd strong{ color: #FFE114; display: block;}
.tab-bd-img dt{ color: #000; padding-top: 10px;}
.tab-bd-p{ background: #30284C; border-radius: 5px; margin: 10px; padding:10px;}
.tab-bd-p h5 { margin: 0; color: #fff; padding-left:26px; height:22px; line-height: 22px; background:url(../assets/images/global_icon/202405-22.png) left center no-repeat; background-size:22px;}
.tab-bd-p p{ margin: 3px 0; color:#C1B3DB; line-height: 16px; font-size: 12px;}
.agent-main {
  margin-top: 40px;
}
.nut-navbar, .nut-navbar-b0, .nut-navbar-b0 .nut-navbar {
  box-shadow: 0 0 0 !important;
}
.agent-top { margin-top: 0px; padding: 0 10px;}
.agent-info {
  border-radius: 10px;
  background: #221C36;
  background-size: 100% 100%;
  min-height: 165px;
  position: relative;
  z-index: 2002;
  color: #fff;
  z-index: 320; border: 2px solid #000; margin-bottom: 10px;
}
.agent-info .nut-button--plain.nut-button--info {
  color: #000 !important;
  background: #00EDA6 !important;
  border-color: #00EDA6 !important;
  margin:0 3px;
  vertical-align: middle;
  font-weight: 600;
  font-size: 10px;
  padding: 0 20px;
}
.agent-info-l {
  padding: 10px;
  text-align: center;
}
.agent-info-l strong {
  line-height: 30px;
  display: block;
  font-size: 24px;
  font-weight: 600;
  color: #FFE114;
}
.agent-info-l strong i{ margin-left: 2px; background: #FFE114; padding: 1px 5px; font-size: 12px; border-radius: 20px; vertical-align: top; color: #000; font-weight: 600;}


.a-i-t{ background: url(../assets/images/global/bg5.png) center top no-repeat; background-size: 100% 100%; height: 100px; text-align: left;}
.a-i-t .tx{ padding: 10px 0 0 20px; display: block;}
.a-i-t .aa{padding: 10px 0 0 20px;}
.a-i-t .lv{ position: absolute; right: 20px; top: 10px;  width: 100px; height: 100px;}
.a-i-t .lv_0{  background: url(../assets/images/global_icon/lv-0.png) center center no-repeat; background-size: 100% auto; }
.a-i-t .lv_1{  background: url(../assets/images/global_icon/lv-1.png) center center no-repeat; background-size: 100% auto; }
.a-i-t .lv_2{  background: url(../assets/images/global_icon/lv-2.png) center center no-repeat; background-size: 100% auto; }
.a-i-t .lv_3{  background: url(../assets/images/global_icon/lv-3.png) center center no-repeat; background-size: 100% auto; }
.a-i-t .lv_4{  background: url(../assets/images/global_icon/lv-4.png) center center no-repeat; background-size: 100% auto; }
.a-i-t .lv_5{  background: url(../assets/images/global_icon/lv-5.png) center center no-repeat; background-size: 100% auto; }
.a-i-t .lv_6{  background: url(../assets/images/global_icon/lv-6.png) center center no-repeat; background-size: 100% auto; }
.a-i-t .lv_7{  background: url(../assets/images/global_icon/lv-7.png) center center no-repeat; background-size: 100% auto; }
.a-i-t .lv_8{  background: url(../assets/images/global_icon/lv-8.png) center center no-repeat; background-size: 100% auto; }
.a-i-t .lv_9{  background: url(../assets/images/global_icon/lv-9.png) center center no-repeat; background-size: 100% auto; }
.a-i-t .lv_10{  background: url(../assets/images/global_icon/lv-10.png) center center no-repeat; background-size: 100% auto; }
.a-i-t .lv_11{  background: url(../assets/images/global_icon/lv-11.png) center center no-repeat; background-size: 100% auto; }

.a-i-t .lv span{ display: none;}

.a-i-t .btn{ margin-left: 18px;}
.flbd { background: #30284C; margin: 10px 0 0 0;  padding: 10px; border-radius: 5px;}
.flbd .fl {
  width:50%;
  height: 54px;
   
}
.flbd .fl:nth-last-child(1) {
  margin-right: 0;
}
.flbd .fl strong {
  font-size: 16px;
}
.flbd table{ width: 100%; text-align: center;}
.flbd table th{ width: 33%; background: #564d77; border: 1px solid #30284C; line-height: 12px; padding: 5px 0;}
.flbd table td{border: 1px solid #120c29;padding: 5px 0;}

.flbd .btn{ background: #161126; margin-top: 3px; border-radius:5px; color: #beb7d2; line-height: 24px; height:24px; font-size: 12px;}

.agent-vip {
  background: #333333;
  height: auto;
  border-radius: 0 0 10px 10px;
  z-index: 90;
  margin-top: -10px;
  position: relative;
  color: #aa9d75;
  text-align: left;
  padding: 10px 0 0 0;
}
.agent-vip .img100{width: 100% !important; margin: 0;margin-bottom: -10px;}
.ap-bn{ position: relative; margin: 0px 10px 0 10px;} 
.ap-bn .img100{width: 100% ;margin:0;}
.ap-bn .time{ position: absolute; left:20px; top:0; padding: 2px 15px; height: 18px; line-height: 20px; opacity: .8;
   font-size: 14px; color: #fff; background: #4b64ff; border-radius:20px 5px 20px 5px; font-style: italic;}

.agent-vip .r {
  float: right;
  padding-right: 10px;
  color: #f5ce95;
}
.shares-btn {
  padding: 0px 10px 60px 10px;
  position: relative;
}

.shares-btn p {
  padding: 0 10px;
  margin: 0;
  color: #C1B3DB;
}
.r-tx{ float: right; color: #ccc !important; font-weight: 300 !important;}
.agent-table {
  margin: 10px;
  overflow: hidden;
  position: relative;
  
}
.agent-table table {
  width: 100%;
  color: #00EDA6;
}
.agent-table table th {
  border: 1px #473f65 solid;
  background: #30284C;
  line-height: 16px;
  height: 38px;
  padding: 0 5px;
  font-weight: 400;
  color: #fff; text-align: left; width: 150px;

}
.agent-table table td {
  border: 1px #473f65 solid;
  line-height: 16px;
  height: 38px;
  padding: 0 5px;
  text-align: center;
}

.agent-table .table-th{
  position: absolute;
  width: calc(100% - 5px);
  left:5px;
  z-index: 100;
}
.agent-table .table-th th{
  width: 85px;
}
.agent-table .table-td{
  position: relative;
  z-index: 110;
  margin-left: 101px;
  overflow-x:scroll;
  overflow-y: hidden;
}
.agent-table .table-td table{
  width: auto;
}
.agent-table .table-td td{ min-width:45px;}
.copy-link {
  padding: 20px;
  padding-bottom: 0;
  text-align: center;
}
.copy-link h4 {
  margin: 5px 0 30px 0;
  font-weight: 300;
  color: #fff;
}
.copy-link .ft30 {
  font-size: 40px;
  font-weight: 300;
  padding: 10px;
  background: #221C36;
  border-radius: 10px;
  color: #00EDA6;
}
.copy-link p {
  margin: 20px 0;
  color: #C1B3DB;
}
.copy-link-dl dl {
  margin-top: 0px;
}
.copy-link-dl dl {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.copy-link-dl dl dd {
  align-items: flex-start;
  text-align: center;
  margin: 5px;
  font-size: 10px;
  padding-top: 38px;
  color: #666;
  text-decoration:none;
}
.copy-link-dl dl dd.i1 {
  background: url(../assets/images/global/bg21.png) center top no-repeat;
  background-size: 30px;
}
.copy-link-dl dl dd.i2 {
  background: url(../assets/images/global/bg22.png) center top no-repeat;
  background-size: 30px;
}
.copy-link-dl dl dd.i3 {
  background: url(../assets/images/global/bg23.png) center top no-repeat;
  background-size: 30px;
}
.copy-link-dl dl dd.i4 {
  background: url(../assets/images/global/bg24.png) center top no-repeat;
  background-size: 30px;
}
.copy-link-dl dl dd.i5 {
  background: url(../assets/images/global/bg25.png) center top no-repeat;
  background-size: 30px;
}
.copy-link-dl dl dd.i6 {
  background: url(../assets/images/global/bg26.png) center top no-repeat;
  background-size: 30px;
}

.img101{ border-radius: 10px; width: 100%; height: auto; margin-top: 10px;}

.tab-bd-4x{ display: flex;justify-content: space-between;align-items: flex-end; }
</style>

<style>
.pop-lv{ background: #fff; width: 210px; padding:5px 20px; text-align: center; border-radius: 10px;}
.pop-lv .hd{ line-height: 30px; color: #32026c;}
.pop-lv table{ width: 100%; margin-bottom: 10px;}
.pop-lv table th{  border: 1px solid #d8d7da;padding: 2px 3px; background: #f6f6ff;}
.pop-lv table td{ border: 1px solid #d8d7da; padding: 2px 3px;}
.pop-lv table td.ta-r{ text-align: right; color: #ff8a00;}
.pop-ab {
  padding: 0px !important;
  width: 100% !important;
  min-height: 100px;
  top: 50%;
  border-radius: 0px;
  background: transparent !important;  text-align: center;overflow-y: hidden !important;
}
.pop-ab .nut-button--round{height: 30px !important; margin-top:5px;}
.pop-ab .bd{ margin: 0; padding: 3px 0px !important; background: #000;}
.pop-ab img{ width: 100% !important; height: auto; margin: 0; max-width: 400px !important;}
.pop-ab .nut-swiper-inner{ height: auto !important; }

.pop-reward{ height: 426px;  width: 330px;text-align: center;
  background:transparent url(../assets/images/global/bg300.png) 0px 0px no-repeat !important ;background-size: 100% auto !important;}

.pop-reward .hd{height: 140px;}
.pop-reward .bd {height: 195px;}
.pop-reward .bd h4{ margin: 0; line-height: 40px; font-size: 18px;color: #7e3500;}
.pop-reward .bd h5{ margin: 5px 0 10px 0;line-height: 50px;font-size: 35px; color: #ff8a00; font-weight: 600;}
.pop-reward .bd p{ margin: 8px 0; line-height: 20px;font-size: 16px;color: #7e3500;}
.pop-reward .bd p strong{font-size: 18px;color: #ff8a00; margin-right: 5px;}
.pop-reward .nut-button--primary{ background: none !important; color: #06c98f; font-weight: 600;font-size: 16px;}
.pop-reward .btn p{ margin: 5px 0 0 0; color: #fff; opacity: .2;font-size: 12px;}
</style>
