<template>
  <div class="index gameLobby" >
    <div class="inx-top">
      <div class="c">
          <div class="l">
            <span class="icon-b" v-show="downloadAppShow">
              <a :href="appDownloadUrl">
                <span @click="downloadAppClose">Download APP</span>
              </a>
            </span>
          </div>
          <div class="r">
            <div class="inx-tg-btn">
              <img src="../assets/images/global/bg207.png" @click="toTelegram"/>
            </div>
          </div>
        </div>
    </div>

    <div class="index-main" >

      <div class="inx-bn">
        <nut-swiper :init-page="0" :pagination-visible="true" pagination-color="#00EDA6" pagination-unselected-color="#ffffff" auto-play="3000" height="auto" :is-prevent-default="false" >


          <nut-swiper-item>
              <router-link to="/businessPartners"><img src="../assets/images/bn11.jpg"/></router-link>
          </nut-swiper-item>

          <nut-swiper-item v-if="show_w3">
            <router-link to="/agentPromotion">
              <img src="../assets/images/bn3b.jpg" />
            </router-link>
          </nut-swiper-item>

          <nut-swiper-item>
            <router-link  :to="{ path: 'liveGame', query: { id: 'CQ9_cq9_GINKGO01', platform:'cq9', name:'motivation'}}">
              <img src="../assets/images/bn10.jpg"/>
            </router-link>
          </nut-swiper-item>

          <nut-swiper-item>
              <img src="../assets/images/bn1.jpg"/>
          </nut-swiper-item>

          <nut-swiper-item>
            <router-link to="/rechargeBalance">
              <img src="../assets/images/bn9.jpg"/>
            </router-link>
          </nut-swiper-item>

          <nut-swiper-item v-if="!show_w3">
            <router-link to="/agentPromotion">
              <img src="../assets/images/bn3.jpg"/>
            </router-link>
          </nut-swiper-item>
 
          <nut-swiper-item>
            <router-link to="/agentrgGrowth"><img src="../assets/images/bn2.jpg"/></router-link>
          </nut-swiper-item>


        </nut-swiper>
      </div>

      <div class="index-nav">
        <div class="s1"><strong>Secure</strong><span>Secure & Real</span></div>
        <div class="s2"><strong>Easy</strong><span>Quick deposits</span></div>
        <div class="s3"><strong>Fast</strong><span>60s withdraw success</span></div>
      </div>

      <div class="inx-hd" v-if="!showLogin || userInfo.avatar" >
        <div class="avatar-l">
          <img :src="userInfo.avatar"/>
        </div>
        <div class="btn-r" >
          <span>{{$t('withdrawal.gameAccount')}}</span>
          <div class="avatar-r" style="position: relative;">

            <strong v-if="userInfo.vipLevel > 0 || regStoreCoin === 0">
              {{ $filters.currencySymbol(userInfo.availableAmount) }}
            </strong>
            <strong v-else style="opacity: .5;">
              {{ $filters.currencySymbol(regStoreCoin) }}
            </strong>
            <div v-if="userInfo.vipLevel === 0 && regStoreCoin > 0" class="reg-coin-p" style="right: 172px; top: 3px; border-radius: 5px;">Complete any first deposit,unlock ₹{{ regStoreCoin }} extra bonus</div>

            <div style="margin-left: 10px;">
              <nut-button size="small" plain type="info" style="min-width: 80px;height: 25px;padding:0;" @click="rechargeClick">
                {{$t('home.recharge')}}
              </nut-button>
              <nut-button size="small" type="info"  class="ml5" style="min-width: 80px;height: 25px;padding:0;" @click="withdrawalClick('coin')">
                {{$t('home.withdrawal')}}
              </nut-button>
            </div>

          </div>
        </div>
      </div>

      <div class="inx-fd lobby-tab">

        <h4>
          <strong :class="lobbyTabType ===  'hot' ? 'a': ''" @click="lobbyTabClick('hot')">Hot Game</strong>
          <strong :class="lobbyTabType ===  'new' ? 'a': ''" @click="lobbyTabClick('new')">New Game</strong>
          <strong :class="lobbyTabType ===  'recent' ? 'a': ''" @click="lobbyTabClick('recent')">Recent Play</strong>
        </h4>

        <div class="link-8x-box">

          <div class="link-3x link-8x" v-if="lobbyTabType ===  'hot'">
            <div v-for="(item, index) in promoteGameList" :key="index"  class="link-i" @click="toGameHome(item.gameId, item.platform, item.name)">
              <img  @error="errorImg"  :src="getImgUrl(item.platform, item.gameId)" />
            </div>
          </div>

          <div class="link-3x link-8x" v-else-if="lobbyTabType ===  'new'">
            <div v-for="(item, index) in newGameList" :key="index"  class="link-i" @click="toGameHome(item.gameId, item.platform, item.name)">
              <img  @error="errorImg"  :src="getImgUrl(item.platform, item.gameId)" />
            </div>
          </div>

          <div class="link-3x link-last-game" v-else-if="lobbyTabType ===  'recent'">
              <div v-for="(item, index) in lastGameList" :key="index"  class="link-i" @click="toGameHome(item.gameId, item.platform, item.name)"  v-show="index < 4">
                <img  @error="errorImg"  :src="getImgUrl(item.platform, item.gameId)" />
              </div>
          </div>

        </div>

      </div>

      

      <div class="lobby-tab">
        <nut-tabs style="height:480px" v-model="tabValue" title-scroll direction="vertical"  @click="gameTabsClick">
          <nut-tabpane  title="Originals" >
            <div class="link-2x">
              <div  v-for="(item, index) in lobbyGames" :key="index"  class="link-i" @click="toGameHome(item.gameId, item.platform, item.name)">
                <img v-if="item.gameId === 'crash2'" :src="require('../assets/images/global/gm1.png')" />
                <img v-if="item.gameId === 'parity'" :src="require('../assets/images/global/gm3.png')" />
                <img v-if="item.gameId === 'mine'" :src="require('../assets/images/global/gm2.png')" />
                <img v-if="item.gameId === 'wheel'" :src="require('../assets/images/global/gm4.png')" />
                <img v-if="item.gameId === 'andar'" :src="require('../assets/images/global/gm5.png')"/>
                <img v-if="item.gameId === 'dice'"  :src="require('../assets/images/global/gm7.png')"/>
              </div>
            </div>
          </nut-tabpane>
          <nut-tabpane  title="Slots">
            <div class="link-2x link-2x-jili">
              <div v-for="(item, index) in jiliGamesSlots" :key="index"  class="link-i" @click="toGameHome(item.gameId, item.platform, item.name)" v-show="index < 12">

                <img @error="errorImg"  :src="getImgUrl(item.platform, item.gameId)" />
                <p class="name">{{item.name}}</p>
              </div>
            </div>
            <div class="view-all"  @click="toGamesList('slots')"><span>View All Slots</span></div>
          </nut-tabpane>
          <nut-tabpane  title="Poker">
            <div class="link-2x link-2x-jili">
              <div v-for="(item, index) in jiliGamesPoker" :key="index"  class="link-i" @click="toGameHome(item.gameId, item.platform, item.name)"  v-show="index < 12">
                <img  @error="errorImg"  :src="getImgUrl(item.platform, item.gameId)" />
                <p class="name">{{item.name}}</p>
              </div>
            </div>
            <div class="view-all"  @click="toGamesList('poker')"><span>View All Poker</span></div>
          </nut-tabpane>

          <nut-tabpane  title="Casino" >
            <div class="link-2x link-2x-jili">
              <div v-for="(item, index) in jiliGamesCasino" :key="index"  class="link-i" @click="toGameHome(item.gameId, item.platform, item.name)"  v-show="index < 12">
                <img  @error="errorImg"  :src="getImgUrl(item.platform, item.gameId)" />
                <p class="name">{{item.name}}</p>
              </div>
            </div>
            <div class="view-all"  @click="toGamesList('casino')"><span>View All Casino</span></div>
          </nut-tabpane>


          <nut-tabpane  title="Fishing" >
            <div class="link-2x link-2x-jili">
              <div v-for="(item, index) in jiliGamesFish" :key="index"  class="link-i" @click="toGameHome(item.gameId, item.platform, item.name)"  v-show="index < 12">
                <img @error="errorImg"  :src="getImgUrl(item.platform, item.gameId)" />
                <p class="name">{{item.name}}</p>
              </div>
            </div>
            <div class="view-all"  @click="toGamesList('fishing')"><span>View All Fishing</span></div>
          </nut-tabpane>
          

          
        </nut-tabs>
      </div>

      <div class="view-all"  @click="toGamesList('all')">
        <span>View All</span>
      </div>


      <div class="live-game-btn" @click="toLiveGame('CQ9_cq9_GINKGO01', 'cq9', 'motivation')">
        <img src="../assets/images/lobby_icon/live-bn.jpg" />
      </div>
      
      <div class="pinpai">
        <dl  @click="toGamesList('all')">
          <dt>JILI GAME</dt>
        </dl>
        <dl  @click="toGamesList('all')">
          <dt>CQ9 GAME</dt>
        </dl>
        <dl  @click="toLiveGame('CQ9_cq9_GINKGO01', 'cq9', 'motivation')">
          <dt>Live GAME</dt>
        </dl>
      </div>

      <div class="inx-fd">
        <h4><strong class="icon-top">{{$t('global.top10')}}</strong></h4>
        <div class="top-10">
          <ul>
            <li v-for="(item, index) in winRank" :key="index">
              <i>{{ index+1 }}</i>
              <img :src="require('../assets/images/avatar/' + item.avatar.slice(item.avatar.length-6))"/>
              <span>{{ item.desensitizedMobile }}</span>
              <strong>{{ $filters.currencySymbol(item.winAmount) }}</strong>
            </li>
          </ul>
        </div>
      </div>

    </div>

    <!--**************************************** tg 提醒 ****************************************-->
    <nut-popup pop-class="pop-vip-lobby" v-model:visible="vipTgDialog" :z-index="100">
      <VipRules :myLevel="0" :popTitle="popTitle"></VipRules>
      <div class="bd">
        {{ $t('global.Prediction_Channel_Text') }}<br/>
      </div>
      <div class="btn" style="margin: 0;">
        <nut-button style="width: 48%;margin: 0 1%;" type="primary" @click="vipTgDialog = false">{{ $t('global.Got_It') }}</nut-button>
        <nut-button style="width: 48%;margin: 0 1%;" type="primary" @click="rechargeBtnClick">{{ $t('global.Upgrade') }}</nut-button>
      </div>
    </nut-popup>

  </div>
  <tabbar :activeIndex="tabbarActiveIndex"></tabbar>

  <nut-drag direction="y" :style="{ bottom: '60px', right:'5px', zIndex: '1000 !important'}"  @click="toHelp" >
    <div class="drag-wb-history">
      <img :src="require('../assets/images/global_icon/202405-66.png')"/>
    </div>
  </nut-drag>
  <!-- <div class="to-foot" @click="toFoot()"></div> -->

  <div class="reg-store-coin" v-show="regCoing">
    <div class="bd">
        <h3><span>Welcome to WINZONE777!</span></h3>
        <p>We have prepared generous bonuses for you. If you complete any <strong>FIRST DEPOSIT</strong>, you can get <strong>10%</strong> first deposit bonus and <strong>₹{{ regStoreCoin }}</strong> new user bonus.</p>
        <h4>For example</h4>
        <div class="example">
          <p>First deposit ₹200, receive ₹250! Get ₹{{ regStoreCoin + 20 }} extra bonus!--₹20 (10% first deposit bonus) + ₹{{ regStoreCoin }} (new user bonus) = <strong>₹{{ regStoreCoin + 20 }}</strong></p>
          <p>First deposit ₹1000, receive ₹1130! Get ₹{{ regStoreCoin + 100 }} extra bonus!--₹100 (10% first deposit bonus) + ₹{{ regStoreCoin }} (new user bonus) = <strong>₹{{ regStoreCoin + 100 }}</strong></p>
        </div>
        <div class="btn">
          <nut-button type="primary" @click="hideRegStoreCoin">Close</nut-button>
        </div>
    </div>
  </div>
</template>

<script>
import {reactive, toRefs, onMounted, getCurrentInstance} from "vue";
import {useRouter} from "vue-router";
import TabbarMain from "../components/TabbarMain.vue";
import { Toast } from "@nutui/nutui";
import axios from "axios";
import {isBlank} from "@/assets/js/commonUtil";
import { getCountry, searchPlatform} from "@/assets/js/environment";
import VipRules from "../components/VipRules.vue";
import { getClientType } from "@/assets/js/commonUtil";

export default {
  name: "gamesLobby",
  components: {
    tabbar: TabbarMain,
    VipRules: VipRules,
  },
  data() {
    return {
      country: '',
      commonInfo: '',
      downloadTitle:'',
      downloadAppShow: true,
      menuDownloadAppShow: '',
      appDownloadUrl:'',
      addDesktopShow: '',
      target: 300,
      show_w3:false,
      popTitle: this.$t('recharge.popTitle_1'),
      i18n:{
        loading: this.$t('global.loading'),
      },
      clientType: null,
      errorImg: 'this.src="' + require('../assets/images/global/error-img.png') + '"',
    };
  },
  created() {
    this.country = getCountry()
    axios({
      url: "/api/system/common",
      method: "get",
    }).then((res) => {
      localStorage.setItem("commonInfo",JSON.stringify(res.data.data));
      this.commonInfo = res.data.data
    });

    if(localStorage.getItem("downloadApp") === 'close'){
      this.downloadAppShow = false
      this.addDesktopShow = false
    }else{
      this.showDownloadTip();
    }
    let d = new Date()
    // 1100活动 星期3
    this.show_w3 = (d.getDay() === 3)

    
  },
  // mounted() {
  // },
  methods: {
    getImgUrl(platform,gameId){
        try {
          if(platform === 'cq9'){
            return require('@/assets/images/lobby_icon/'+ platform + '/280_280_EN/280x280_EN_GAMEID_' + gameId + '.png')
          }else if(platform === 'jili'){
            return require('@/assets/images/lobby_icon/'+ platform + '/260_380_EN/260x380_EN_GAMEID_' + gameId + '.png')
          }else{
            // lobby yw类型的时候 返回jili目录下的图标
            return require('@/assets/images/lobby_icon/jili/260_380_EN/260x380_EN_GAMEID_' + gameId + '.png')
          }
        } catch (error) {
          return require('../assets/images/global/error-img.png')
        }
    },
    //下载app
    downloadApp(){
        window.open(this.appDownloadUrl);
    },
    downloadAppClose(){
      // this.addDesktopShow = false;
      // this.downloadAppShow = false;
      // localStorage.setItem("downloadApp", 'close');
    },
    showDownloadTip() {
      this.clientType = getClientType();
      if (this.clientType == "ios") {
        this.addDesktopShow = true;
        this.downloadAppShow = false;
        this.downloadTitle = this.$t('global.addDesktop');
      } else if (this.clientType == "android") {
        this.addDesktopShow = false;
        this.downloadAppShow = true;
        this.menuDownloadAppShow = true;
        if (window.localStorage.getItem("commonInfo")) {
          let commonInfo = JSON.parse(window.localStorage.getItem("commonInfo"));
          this.appDownloadUrl = commonInfo.appDownloadUrl;
        }
        if(this.appDownloadUrl === '-') {
          this.downloadAppShow = false;
          return
        }
        this.downloadTitle = this.$t('global.downloadApp');
      }
    }
  },
  setup() {
    const _data = getCurrentInstance();
    const state = reactive({
      commonInfo2: JSON.parse(window.localStorage.getItem("commonInfo")),
      regCoing: false,
      regStoreCoin: 0,
      regStoreCoinOn: false,
      tabValue: '0',
      list5: [1,2,3],
      lobbyGames: [],
      jiliGamesSlots: [],
      jiliGamesPoker: [],
      jiliGamesOther: [],
      jiliGamesFish: [],
      jiliGamesCasino: [],
      jiliGamesSlotsData: [],
      jiliGamesPokerData: [],
      jiliGamesOtherData: [],
      jiliGamesFishData: [],
      jiliGamesCasinoData: [],
      promoteGameList: [],
      newGameList: [],
      tabbarActiveIndex: 0,
      showLogin: true,
      loadingCompleted: false,
      userInfo: {
        mobile: "",
        inviteCode: "",
        avatar: "",
        vipLevel: "",
        hasPassword: "",
        vipTitle: "",
        vipBg: "",
        availableAmount: 0,
      },
      winRank: [],
      vipTgDialog: false,
      myLastTimeGameList: [],
      lastGameList: [],
      lobbyTabType: 'hot',
    });
    const router = useRouter();
    const methods = {

      lobbyTabClick(type){
        state.lobbyTabType = type
      },
      showRegStoreCoin(){
        if(state.regStoreCoin > 0 && state.userInfo.availableAmount === 0 && state.regStoreCoinOn){
          state.regCoing = true
        }
      },
      hideRegStoreCoin(){
        state.regCoing = false
        localStorage.removeItem('regStoreCoin')
      },
      gameTabsClick(val){
        switch (val.title) {
          case 'Slots':
            state.jiliGamesSlots = state.jiliGamesSlotsData
            return 'r';
          case 'Poker':
            state.jiliGamesPoker = state.jiliGamesPokerData
            return 'r';
          case 'Casino':
            state.jiliGamesCasino = state.jiliGamesCasinoData
            return 'r';
          case 'Fishing':
            state.jiliGamesFish = state.jiliGamesFishData
            return 'r';
          case 'Popular':
            state.jiliGamesOther = state.jiliGamesOtherData
            return 'r';
        }
      },
      toFoot(){
        this.$nextTick(() =>{
          this.$refs.mainScroll.scrollIntoView({
            behavior: 'smooth',  
            block: 'end',  
            inline: 'nearest'  
          })
        })
      },
      toTelegram(){
          Toast.loading("Loading", {
            id: "loading",
            duration: 0,
            bgColor: "rgba(0, 0, 0, 0.9)",
            cover: true,
          });
          window.open(state.commonInfo2.telegramChannelUrl,"_self");
      },
      rechargeBtnClick() {
        router.push({ path: '/rechargeBalance' });
      },
      toGamesList(t){
        let _type = t ? t : 'all'
        router.push({
          path: '/gamesList',
          query: {
            tabType: _type
          }
        })


      },
      toHelp() {
        router.push({ path: '/helpSupport' });
      },
      toReferBonus(){
        router.push({
          name: "referBonus",
        });
      },
      toLiveGame(id, platform, name){
        let _id = id
        let _name = name
        let _platform = platform
        router.push({
          path: 'liveGame',
          query: {
            id: _id,
            name: _name,
            platform: _platform
          }
        })
      },
      toGameHome(id, platform, name){
        let _id = id
        let _name = name
        let _platform = platform

        if(platform === 'lobby'){
          //setLastTimeGameName(_id,'lobby')
          router.push({ path: '/' + _id });
        }else{
          //setLastTimeGameName(_id, _platform)
          router.push({
            path: 'playGame',
            query: {
              id: _id,
              name: _name,
              platform: _platform
            }
          })
        }
      },
      showLastGameList(){
        state.myLastTimeGameList = JSON.parse(localStorage.getItem('myLastTimeGameList'))
        for(let i in state.myLastTimeGameList){
          let _gameId = state.myLastTimeGameList[i]
          let _platform = searchPlatform(_gameId)
          let d = {
            gameId : _gameId,
            platform : _platform,
            name: '',
          }
          state.lastGameList.push(d)
        }
      },
      toMyHome(){router.push({name: "myHome"});},
      toAgentPromotion(){router.push({name: "agentPromotion"});},
      toTaskReward(){router.push({name: "taskReward"});},
      rechargeClick() {router.push({name: "rechargeBalance"});},
      userLoginClick() {router.push({name: "userLogin"});},
      registerClick() {router.push({name: "userRegister"});},

      withdrawalClick(t) {
        router.push({
          path: 'withdrawalBalance',
					query: {
						amountType: t
					}
				})
      },
      textToast(msg) {
        Toast.loading(msg, {
          id: "loading",
          duration: 0,
          bgColor: "rgba(0, 0, 0, 0.9)",
          cover: true,
        });
      }
    };
    onMounted(() => {
      
      methods.textToast(_data.data.i18n.loading);
      axios
        .get("/api/game/lobby", {})
        .then(function (res) {
         
          for (const key in res.data.data.games) {
            if(res.data.data.games[key].promote && state.promoteGameList.length < 8){
              state.promoteGameList.push(res.data.data.games[key])
            }
            if(res.data.data.games[key].newGame && state.newGameList.length < 8){
              state.newGameList.push(res.data.data.games[key])
            }
            if (res.data.data.games[key].platform === 'lobby') {
              state.lobbyGames.push(res.data.data.games[key])
            }else if(res.data.data.games[key].platform === 'jili' || res.data.data.games[key].platform === 'cq9'){

              if(res.data.data.games[key].type === 'slots' && state.jiliGamesSlotsData.length < 13){
                state.jiliGamesSlotsData.push(res.data.data.games[key])
              }else if(res.data.data.games[key].type === 'casino' && state.jiliGamesCasinoData.length < 13){
                state.jiliGamesCasinoData.push(res.data.data.games[key])
              }else if(res.data.data.games[key].type === 'fish' && state.jiliGamesFishData.length < 13){
                state.jiliGamesFishData.push(res.data.data.games[key])
              }else if(res.data.data.games[key].type === 'poker' && state.jiliGamesPokerData.length < 13){
                state.jiliGamesPokerData.push(res.data.data.games[key])
              }else if(res.data.data.games[key].type === 'other' && state.jiliGamesOtherData.length < 13){
                state.jiliGamesOtherData.push(res.data.data.games[key])
              }
            }
          }
          if(isBlank(localStorage.getItem('firstVisit'))) {
            localStorage.setItem("firstVisit", 'lobby')
          }
          state.winRank = res.data.data.winRank
          if(localStorage.getItem("token")){
            state.showLogin = (!isBlank(res.data.data.user)) ? false : true
            state.userInfo.mobile = res.data.data.user.mobile;
            state.userInfo.inviteCode = res.data.data.user.inviteCode;
            state.userInfo.avatar = 
            require('../assets/images/avatar/' + res.data.data.user.avatar.slice(res.data.data.user.avatar.length-6))
            state.userInfo.vipLevel = res.data.data.user.vipLevel;
            state.userInfo.hasPassword = res.data.data.user.hasPassword;
            state.userInfo.availableAmount = res.data.data.fundAccount.availableAmount;
          }else{
            state.showLogin = true
          }
          Toast.hide("loading");
          state.loadingCompleted = true
        })
        .catch(function (error) {
          console.log(error);
          Toast.hide("loading");
        })
        methods.showLastGameList()
        if(state.commonInfo2.regStoreCoin){
          state.regStoreCoin = state.commonInfo2.regStoreCoin
        }
        state.regStoreCoinOn = localStorage.getItem("regStoreCoin") > 0 ? true : false
        methods.showRegStoreCoin()
    });
    return {
      ...toRefs(state),
      ...methods,

    };
  },
};
</script>

<style scoped>

.icon-b{ background: #00eda6 url(../assets/images/global_icon/202405-23.png) 7px 5px no-repeat; background-size:auto 18px; display: inline-block;width: 100px; height: 32px; line-height: 32px; 
  vertical-align: super;  font-size: 12px; text-align: center; border-radius:0 17px 17px 0; text-align: left; padding-left: 28px; margin-top: 3px;  }
.icon-b a{text-decoration: none; font-weight: 500; color: #001f3b; font-size: 12px; }

.index{ min-width: 340px; margin: 0 auto; background:#110E1B; padding-bottom: 50px;}
.inx-top { position: fixed;top: 0; left: 0;right: 0;height: 40px;z-index: 2000;
  background:#30284cf1 !important;box-shadow: 0 1px 7px #170126;}
.inx-top .l { width: 45%;float: left; line-height: 40px; height: 40px; color: #fff; font-size: 14px;}
.inx-top .r {width: 45%; float: right;line-height: 40px; height: 40px; text-align: right;}
.inx-top .r i { width: 16px;height: 16px;line-height: 18px;border: 2px solid #fff; display: inline-block; font-weight: 600;
  border-radius: 20px;font-size: 16px;color: #fff;text-align: center;font-style: normal; margin: 3px 10px 0 0;opacity: .7;}
.inx-hd{ height: 40px; padding:0 10px; display: flex; width: 100%;}
.inx-hd .avatar-r{display: flex;justify-content: space-between; }
.avatar-l{ width: 35px; height:35px; border-radius:50%; margin-top:0px; border: 2px solid #eab000;}
.avatar-l img{ width: 35px;}
.btn-r{margin-top: 0px; padding-left: 3px; width: calc(100% - 70px);}
.btn-r span{font-size: 12px; display: block;color: #fff; opacity: .8; line-height: 10px;}
.btn-r strong{ height: 30px;line-height: 30px; color: #f7c929; font-size: 17px; margin-bottom: 0px; display: block;}
.btn-r strong span{vertical-align: middle;display: inline-block; height: 16px;}
.btn-r strong span .nut-icon{width: 25px; height: 9px; line-height: 20px;}
.inx-body{ margin: 10px; background: #221C36; border-radius:10px;}

.inx-fd{ min-height: 150px;  background: #30284C;border-radius:15px; overflow: hidden; margin: 10px; padding-bottom: 0px; }
.inx-fd h4{border-top: 0px; margin: 0 10px; color: #fff; position: relative;}
.inx-fd h4 .icon-last{opacity: .6;}
.inx-fd h4.last-game-list .icon-hot{ opacity: .6;}
.inx-fd h4.last-game-list .icon-last{opacity: 1;}


.inx-tg-btn{ position: absolute; right: 0px; top: 4px; }
.inx-tg-btn img{ height: 32px; width: auto;}


.link-img{ margin-bottom: 5px; position: relative;}
.link-img img{ width: 100%;}

.inx-new{  margin: 0 10px 10px 10px; background: #30284C; border-radius: 15px;background: linear-gradient(90deg, #eb02e2, #2797ff); padding: 2px; overflow: hidden;}
.inx-new .bd{background: #221C36;height: 130px;border-radius: 15px; display: flex;}
.inx-new .bd .l { background: #110E1B; width: 120px; text-align: center; height: 110px;  margin-top: 10px; margin-left: 10px;border-radius: 15px;}
.inx-new .bd .l img{ width: 110px; height: auto;}
.inx-new .bd .r{ color: #fff; margin-left: 20px;}
.inx-new .bd .r h4{ margin: 5px 0 0px 0; font-size: 20px;}
.inx-new .bd .r strong{ color: #C1B3DB; font-weight: 300; line-height: 16px; font-size: 14px;}
.inx-new .bd .r .btn{background: linear-gradient(90deg, #eb02e2, #2797ff); width: 120px; padding: 3px; text-align: center;border-radius: 30px; margin-top: 10px; }
.inx-new .bd .r .btn strong{ color: #FFE114; background: #110E1B; display: block; line-height: 32px; border-radius: 30px; font-size: 20px;font-weight: 700;}

.index-nav { display: flex; justify-content: space-between; flex-wrap: wrap; margin:0 10px 10px 10px;}
.index-nav div{ width: 32.8%; background: #30284C; border-radius: 5px; color: #fff; height: 45px;}
.index-nav div.s1{background:#30284C url(../assets/images/global_icon/202405-26.png) 5px center no-repeat; background-size:35px auto; }
.index-nav div.s2{background:#30284C url(../assets/images/global_icon/202405-28.png) 5px center no-repeat; background-size:35px auto; }
.index-nav div.s3{background:#30284C url(../assets/images/global_icon/202405-29.png) 0px center no-repeat; background-size:35px auto; }

.index-nav div.s3 strong{padding-left: 43px; }
.index-nav div.s3 span{padding-left: 43px;font-size: 10px;}


.index-nav strong{ display: block; line-height: 20px; padding-left: 45px; margin-top:2px; font-size: 14px; margin-bottom:0px;}
.index-nav span{ display: block; line-height: 10px;padding-left: 45px;font-size: 10px; color: #C1B3DB;}

.inx-fd h4{ margin: 0; padding: 2px 15px; line-height: 26px; color: #fff; font-size: 14px;
  background:linear-gradient(89deg,#3e00c1 .38%,#fa00ff 107.78%);position: relative;}
.inx-fd h4 .icon-hot{background:#3e00c1 url(../assets/images/global_icon/202405-36.png) 5px center no-repeat; background-size:24px; padding:3px 20px 3px 30px; border-radius: 20px; }
.inx-fd h4 .icon-last{background:#fa00ff url(../assets/images/global_icon/202405-38.png) 10px center no-repeat; background-size:20px;
  padding: 0px 20px 0px 35px;border-radius: 20px 0px 20px 0px; margin-left: 10px;float: right;}

.inx-fd h4 .icon-top{background:url(../assets/images/global_icon/202405-27.png) left center no-repeat; background-size:24px; padding-left: 24px; }



.lobby-tab h4{ background: #110E1B !important; padding:0; display: flex;}
.lobby-tab strong{background: linear-gradient(89deg,#201a36 .38%,#6d00c1 107.78%); color: #f4dcff;
  padding:8px 0px; text-align: center; border-radius: 10px 50px 0 0; margin: 0 2px 0 0; width: calc(33% - 2px); font-size: 15px; line-height: 18px;}
.lobby-tab strong:last-child{margin: 0 0 0 0px;width: calc(33% + 4px);}
.lobby-tab strong.a{ background: linear-gradient(89deg,#201a36 .38%,#fa00ff 107.78%); color: #fff200;}


.top-10{  padding-bottom: 5px;padding: 0 10px; }
.top-10 li{padding: 10px 0; margin: 0 2px; line-height: 30px; display: flex; border-top: 1px solid rgba(88, 72, 139, .4);;}
.top-10 li img{ width: 30px; height: 30px; border-radius:50%; margin: 0 10px;}
.top-10 li i{border-radius:13px; width: 26px; height: 26px; background: #00EDA6; color: #000; text-align: center;line-height: 28px;font-size: 16px; font-weight: 600; overflow: hidden; margin-top: 3px;}
.top-10 li span{ font-size: 12px; color: #B6BDCC;}
.top-10 li strong{ margin-left: auto; color:#FFE114; font-weight: 600;font-size: 16px;}


.link-2x{ display: flex; justify-content: center; flex-wrap: wrap; justify-content: flex-start; margin: 10px 0 0 0; width: 100%;}
.link-2x .link-i{ width: 44%; position: relative;margin:0 3%}
.link-2x .link-i img{ width: 100%;}


.link-3x{ display: flex; justify-content: center; flex-wrap: wrap; justify-content: flex-start; margin: 20px 0 0 0; width: 100%;}
.link-3x .link-i{ width: 33.3%; position: relative;margin:0}
.link-3x .link-i img{ width: 100%;}

.inx-fd .link-3x{ margin-top: 5px;}
.inx-fd .link-3x .link-i{ width: calc(25% - 10px); margin:0 5px}
.inx-fd .link-3x .link-i img{ border-radius: 10px; overflow: hidden;}

.coming-soon{ position: absolute; bottom: 22px; left: 0 ; right: 0;  text-align: center; height: 30px; line-height: 24px;
background: url(../assets/images/game_wheel/bg201.png) 0 0  no-repeat; background-size:100% 30px; color: #fff;font-size: 15px; font-weight: 600;}
.to-foot{ position: fixed; right: 0px; bottom: 50px; width: 50px; height: 40px;border-radius:20px 0 0 20px;
  background:#007aed url(../assets/images/global_icon/202405-41.png) 12px 3px no-repeat; background-size:auto 80% ;}
.to-help{position: fixed;right: 0px; bottom: 50px; width: 44px; height: 44px;border-radius:30px 0 0 30px;
  background: url(../assets/images/global_icon/202405-66.png) 0px 0px no-repeat; background-size:44px auto;}
 

  .inx-bn { padding: 40px 0 0 0; margin: 0; width: 100%; position: relative; overflow: hidden;}
  .inx-bn img { width: calc(100% - 0px); height: auto; }

</style>
<style>

</style>
