<template>
  <div>
    <nut-navbar
    :left-show="false"
      :title="$t('myPlayList.h1')"
      fixed=true
      class="mb0 nav-icon-right nut-navbar--fixed"
    >
    <template #left>
      <i class="nutui-iconfont nut-icon nut-icon-left" @click="backClick"></i>
    </template>
    </nut-navbar>


    <div class="pt50 scroll-body">
      <div class="p20">

          <div class="tabs-nav">
            <ul>
              <li v-for="(item) in queryLimitEnum" :key="item.code" :class="item.active ? 'active' : ''" @click="tabsQueryLimit(item)">{{ item.description }}</li>
            </ul>
          </div>

          <ListEmpty v-show="listNoData"></ListEmpty>


          <div class="csls" id="scroll" style="top: 110px;">
            <nut-infiniteloading
                containerId = 'scroll'
                :use-window='false'
                :has-more="hasNext"
                :load-txt='$t("global.loading")'
                :load-more-txt='$t("global.noMore")'
                :load-icon= 'null'
                @load-more="loadMore"
            >
              <div
                class="csls-item"
                v-for="(item, index) in list"
                :key="index"
              >
                <h4>
                  <span class="fc-red">{{$t('myPlayList.bet')}}{{ $filters.currencySymbol(item.betAmount) }}</span>
                  <span :class="item.warning ? 'tx-right-g' : 'tx-right-r'"><strong>{{ item.statusName }}</strong></span>
                </h4>
                <div class="bd" v-if="item.status === 'escape'">
                  <span style="padding-right:20px;">{{$t('myPlayList.ratio')}}: {{ item.escapeRatio }}x</span>
                  <span style="padding-right:20px;">{{$t('myPlayList.originWinAmount')}}{{ $filters.currencySymbol(item.originWinAmount) }}</span>
                  <span>{{$t('myPlayList.feeAmount')}}{{ $filters.currencySymbol(item.feeAmount) }}</span>
                </div>
                <!-- <p>{{$t("myPlayList.time")}}:{{ item.betTime }}</p> -->
              </div>
            </nut-infiniteloading>
          </div>

      </div>
    </div>

  </div>
</template>

<script>
import { reactive, toRefs} from "vue";
import { useRouter } from "vue-router";
import axios from "axios";
import { Toast } from '@nutui/nutui';
import ListEmpty from "../components/ListEmpty.vue"

export default {
  components: {
    ListEmpty: ListEmpty,
  },
  data() {
    return {
      queryLimit:'today',
      queryLimitEnum:[
        {"code":"today","description": this.$t('global.Today'),"active":true},
        {"code":"one_week","description": this.$t('global.Week'),"active":false},
        {"code":"one_month","description": this.$t('global.Month1'),"active":false},
        {"code":"three_month","description": this.$t('global.Month3'),"active":false}],
      listNoData: true,
      list: [],
      current: 1,
      hasNext:''
    };
  },
  methods: {
    loadMore(done){
      if(this.hasNext){
        this.getCoinList(this.current + 1)
        setTimeout(() => {
          done()
        }, 500);
      }
    },
    tabsQueryLimit(li) {
      for(let k in this.queryLimitEnum){
        this.queryLimitEnum[k].active = false
      }
      li.active = true
      this.queryLimit = li.code
      this.list = []
      this.getCoinList(1);
    },
    getCoinList(c) {
      this.textToast(this.$t('global.loading'))
      axios({
        url: "/api/crash2/order/paginate",
        method: "get",
        params: { current: c, queryLimit: this.queryLimit}
      }).then((res) => {
        for (let i = 0; i < res.data.data.records.length; i++) {
            this.list.push(res.data.data.records[i]);
        }
        this.current = res.data.data.current;
        this.hasNext = res.data.data.hasNext;
        if (this.list.length > 0) {
          this.listNoData = false;
        }else{
          this.listNoData = true;
        }
        Toast.hide("loading")
      }).catch(function (error) {
          console.log(error);
          Toast.hide("loading");
      })
    },
    textToast(msg) {
      Toast.loading(msg, {
        id:"loading",
        duration: 0,
        bgColor: "rgba(0, 0, 0, 0.9)",
        cover:true,
      });
    },
  },
  created() {
    this.textToast(this.$t('global.loading'))
    this.getCoinList(1);
  },

  setup() {
    const state = reactive({
    });
    const router = useRouter();
    const methods = {
      backClick() {
        router.go(-1);
      },
    };
    return {
      ...toRefs(state),
      ...methods,
    };
  },
};
</script>


