<template>
<div>
  <nut-navbar
    :left-show="false"
    title="Promotion Partner Dashboard"
    fixed=true
    class="mb0 navbar-red"
  >
    <template #left>
      <i class="nutui-iconfont nut-icon nut-icon-left" @click="backClick"></i>
    </template>
  </nut-navbar>
  

  <div class="agentList">
    <img class="img-w100" src="../assets/images/bg319.jpg" />

    <div class="agentList-item agentList-pro">
      <div class="item-t">
          <h3>Today Income: <strong>{{ $filters.currencySymbol(todayRewardAmount) }}</strong></h3>
      </div>
      <div class="item-b">
        <div>
          <h4>Today Invite Count</h4>
          <p><strong>{{ todayInvitePCount }}</strong></p>
        </div>
        <div>
          <h4>Today Deposit Count</h4>
          <p><strong>{{ todayRechargeCount }}</strong></p>
        </div>
        <div>
          <h4>Today Deposit Amount</h4>
          <p><strong>{{ $filters.currencySymbol(todayRechargeAmount) }}</strong></p>
        </div>
      </div>
    </div>

    <div class="agentList-item agentList-pro">
      <div class="item-t">
        <h3>Week Income: <strong>{{ $filters.currencySymbol(weekRewardAmount) }}</strong></h3>
      </div>
      <div class="item-b">
        <div>
          <h4>Week Invite Count</h4>
          <p><strong>{{ weekInvitePCount }}</strong></p>
        </div>
        <div>
          <h4>Week Deposit Count</h4>
          <p><strong>{{ weekRechargeCount }}</strong></p>
        </div>
        <div>
          <h4>Week Deposit Amount</h4>
          <p><strong>{{ $filters.currencySymbol(weekRechargeAmount) }}</strong></p>
        </div>
      </div>
    </div>

    <div class="agentList-item agentList-pro">
      <div class="item-t">
        <h3>Last Week Income: <strong>{{ $filters.currencySymbol(lastWeekRewardAmount) }}</strong></h3>
      </div>
      <div class="item-b">
        <div>
          <h4>Last Week Invite Count</h4>
          <p><strong>{{ lastWeekInvitePCount }}</strong></p>
        </div>
        <div>
          <h4>Last Week Deposit Count</h4>
          <p><strong>{{ lastWeekRechargeCount }}</strong></p>
        </div>
        <div>
          <h4>Last Week Deposit Amount</h4>
          <p><strong>{{ $filters.currencySymbol(lastWeekRechargeAmount) }}</strong></p>
        </div>

      </div>
    </div>

    <div class="agentList-item agentList-pro">
      <div class="item-t">
          <h3>Total Income: <strong>{{ $filters.currencySymbol(totalRewardAmount) }}</strong></h3>
      </div>
      <div class="item-b">
        <div>
          <h4>Total Invite Count</h4>
          <p><strong>{{ totalInvitePCount }}</strong></p>
        </div>
        <div>
          <h4>Total Deposit Count</h4>
          <p><strong>{{ totalRechargeCount }}</strong></p>
        </div>
        <div>
          <h4>Total Deposit Amount</h4>
          <p><strong>{{ $filters.currencySymbol(totalRechargeAmount) }}</strong></p>
        </div>
      </div>
    </div>
  </div>

</div>
</template>

<script>
import { reactive,toRefs,onMounted } from 'vue';
import { useRouter } from "vue-router";
import axios from "axios";
import { Toast } from "@nutui/nutui";

export default {
  data(){
    return{
    }
  },
  created() {
  },
  setup() {
    const state = reactive({
      todayInvitePCount:0,
      todayRechargeCount:0,
      todayRechargeAmount:0,
      todayRewardAmount:0,
      weekInvitePCount:0,
      weekRechargeCount:0,
      weekRechargeAmount:0,
      weekRewardAmount:0,
      lastWeekInvitePCount: 0,
      lastWeekRechargeAmount: 0,
      lastWeekRechargeCount: 0,
      lastWeekRewardAmount: 0,
      totalInvitePCount:0,
      totalRechargeCount:0,
      totalRechargeAmount:0,
      totalRewardAmount:0,
    });
    const router = useRouter();
    const methods = {
      backClick() {
        router.go(-1);
      },
      textToast(msg) {
        Toast.loading(msg, {
          id: "loading",
          duration: 0,
          bgColor: "rgba(0, 0, 0, 0.9)",
          cover: true,
        });
      },
    };

    onMounted(() => {
      methods.textToast("Loading");
      axios.get("/api/invite/forbid-cash-page", {}).then(function (res) {
          if(res.data.data){
            state.todayInvitePCount = res.data.data.todayInvitePCount;
            state.todayRechargeCount = res.data.data.todayRechargeCount;
            state.todayRechargeAmount = res.data.data.todayRechargeAmount;
            state.todayRewardAmount = res.data.data.todayRewardAmount;
            state.weekInvitePCount = res.data.data.weekInvitePCount;
            state.weekRechargeCount = res.data.data.weekRechargeCount;
            state.weekRechargeAmount = res.data.data.weekRechargeAmount;
            state.weekRewardAmount = res.data.data.weekRewardAmount;
            state.lastWeekInvitePCount = res.data.data.lastWeekInvitePCount;
            state.lastWeekRechargeAmount = res.data.data.lastWeekRechargeAmount;
            state.lastWeekRechargeCount = res.data.data.lastWeekRechargeCount;
            state.lastWeekRewardAmount = res.data.data.lastWeekRewardAmount;
            state.totalInvitePCount = res.data.data.totalInvitePCount;
            state.totalRechargeCount = res.data.data.totalRechargeCount;
            state.totalRechargeAmount = res.data.data.totalRechargeAmount;
            state.totalRewardAmount = res.data.data.totalRewardAmount;
          }
          Toast.hide("loading");
        }).catch(function (error) {
          console.log(error);
          Toast.hide("loading");
        })
    });
    return {
      ...toRefs(state),
      ...methods,
    };
  },
};
</script>

<style scoped>
.agentList-pro .item-t{ text-align: center; color: #1d1d1d;  height: 45px !important; line-height: 45px !important; border-radius: 0 !important;}
.agentList-item.agentList-pro .item-t h3{padding: 0px; margin: 0; line-height: 45px; padding-top: 0px;text-align: left; font-weight: 500;  padding-left: 34px; background: url(../assets/images/global_icon/202405-24.png) 5px center no-repeat; background-size: 25px auto; }
.agentList-item.agentList-pro .item-t h3 strong{  font-size: 20px; margin-left: 5px; color: #dc8000;float: right; margin-right: 20px;}
.agentList-item.agentList-pro .item-b{ display: flex; padding: 5px !important; margin: 0;}
.agentList-pro .item-b div{ width: 33%; text-align: center; border-right:1px solid #eee ;}
.agentList-pro .item-b div:last-child{ border-right:0px}
.agentList-pro .item-b div h4{ font-weight: 300; height: 40px; line-height: 16px;}
.agentList-pro .item-b div p strong{  font-size: 20px;color: #d1840a; font-weight: 300;}
.agentList {padding: 40px 10px; padding-bottom: 20px; background: #000000;min-height: 700px; margin-top: 6px;}
.agentList h2{ margin: 10px; line-height: 30px; color: #d8ae4d; text-align: center;}
.p-info{ padding: 0; line-height: 18px; color: #fff0b5; margin-top:0px; margin-bottom: 20px; text-align: center; }
.p-info strong{ font-size: 20px; }
.p-info span{ display: block;font-size:12px;background: #4a3910; border: 1px solid #7f621d; margin-top:10px; border-radius: 3px; color: #fff; line-height: 18px; padding: 3px 0;}
.img-w100{ width:100% ;}
.navbar-red, .navbar-red .nut-navbar{
   background:linear-gradient(315deg, #110123 0%, #111a37 100%) !important;  box-shadow: 0 1px 7px #110123 !important;
  }
.agentList-item{ margin-bottom: 0px;}
.agentList-item .item-t{ height: 60px; border: 0px solid #b59e7e; background: #dce6e6; border-radius:10px; position: relative;box-shadow: 0px 3px 3px #ccc }
.agentList-item .item-t.lv1{background:#fff9e0 url(../assets/images/global/bg17-3.png) 5px center no-repeat; background-size:45px; }
.agentList-item .item-t h3{margin:0px 0 0 60px; font-weight: 300;padding-right: 67px; line-height: 26px; padding-top: 19px;}
.agentList-item .item-t span.r{ position: absolute; right: 10px; top:0; line-height: 60px;color: #e17800; font-size: 22px; font-weight: 600;}
.agentList-item .item-t span.l{ position: absolute; left: 13px; top:0; line-height: 68px; color: #ffee00; font-size: 16px;width: 30px;text-align: center;}
.agentList-item .item-b{ background: #fff;border-radius:0;  margin:-10px 5px 0 5px;box-shadow: 0 1px 7px #753c00; padding: 20px 10px 10px 10px; }
.agentList-item .item-b h4{ padding: 5px 0px; margin: 5px 0; line-height: 20px;}
.agentList-item .item-b h4 .r{ float:right; font-weight: 400; }
.agentList-item .item-b p{padding: 0; margin: 5px 0; line-height: 20px;}

.nut-popup.popup-top{background:#ffe26f !important}
.popup-top {
  overflow: hidden;
}
.pop-top {
  background: url(../assets/images/global_icon/202405-60.png) center 10px no-repeat !important;
  background-size: 80px auto !important; height: 165px;}
.pop-top h4 {
  padding-left: 130px;
  padding-top: 25px;
  margin-bottom: 0;
  font-size: 18px;
}
.pop-top p {
  padding-left: 130px;
  padding-top: 0;
  margin: 0;
}
.pop-top p strong {
  font-size: 36px;
  color: #940e05;
  font-weight: 500;
}
</style>

<style>
</style>
