<template>
  <div>
    <nut-navbar
      :left-show="false"
      :title="$t('invite.cashHistory')"
      fixed=true
      class="mb0 nav-icon-right nut-navbar--fixed"
    >
    <template #left>
        <i class="nutui-iconfont nut-icon nut-icon-left" @click="backClick"></i>
      </template>
    </nut-navbar>


    <div class="pt50">
      <div class="p20">

          <ListEmpty v-show="listNoData"></ListEmpty>

          <div class="csls" id="scroll">
            <nut-infiniteloading
                containerId = 'scroll'
                :use-window='false'
                :has-more="hasNext"
                :load-txt='$t("global.loading")'
                :load-more-txt='$t("global.noMore")'
                :load-icon= 'null'
                @load-more="loadMore"
            >
              <div
                class="csls-item"
                v-for="(item, index) in list"
                :key="index"
              >
              <h4>
                <span :class="[item.income ? 'fc-green' : 'fc-red']"
                  >{{ item.income ? "+" : "-" }} {{ $filters.currencySymbol(item.changeAmount) }}</span
                ><span :class="[item.income ? 'tx-right-r' : 'tx-right-g']"
                  >{{ item.preAmount }} >> {{ item.postAmount }}</span
                >
              </h4>
              <div class="bd">{{ item.name }}</div>
              <p>{{ item.createTime }}</p>
              </div>
            </nut-infiniteloading>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs } from "vue";
import { useRouter } from "vue-router";
import axios from "axios";
import { Toast } from '@nutui/nutui';
import ListEmpty from "../components/ListEmpty.vue"

export default {
  components: {
    ListEmpty: ListEmpty,
  },
  data() {
    return {
      listNoData: true,
      list: [],
      current: 1,
      hasNext:''
    };
  },
  methods: {
    loadMore(done){
      if(this.hasNext){
        this.getCashList(this.current + 1)
        setTimeout(() => {
          done()
        }, 1000);
      }
    },
    getCashList(c) {
      axios({
        url: "/api/fund/ledger/paginate",
        method: "get",
        params: { current: c, type: "cash" },
      }).then((res) => {
        for (let i = 0; i < res.data.data.records.length; i++) {
            this.list.push(res.data.data.records[i]);
        }
        this.current = res.data.data.current;
        this.hasNext = res.data.data.hasNext;
        if (this.list.length > 0) {
          this.listNoData = false;
        }
        Toast.hide("loading")
      }).catch(function (error) {
          console.log(error);
          Toast.hide("loading");
      })
    },
    textToast(msg) {
      Toast.loading(msg, {
        id:"loading",
        duration: 0,
        bgColor: "rgba(0, 0, 0, 0.9)",
        cover:true,
      });
    },
  },
  created() {
    this.textToast(this.$t('global.loading'));
    this.getCashList(1);
  },

  setup() {
    const state = reactive({
    });
    const router = useRouter();
    const methods = {
      backClick() {
        router.go(-1);
      },
    };
    return {
      ...toRefs(state),
      ...methods,
    };
  },
};
</script>

<style scoped>
/* .nut-pagination {
  margin-top: 10px;
  justify-content: center;
}

.csls-item p {
  line-height: 24px;
  padding: 0;
  margin: 0;
  color: #999;
}
.csls-item .tx-right-r {
  float: right;
  line-height: 22px;
  background: #fff3f3;
  color: #cf0505;
  border-radius: 15px;
  font-size: 10px;
  padding: 0px 10px;
}
.csls-item .tx-right-g {
  float: right;
  line-height: 22px;
  background: #f1ffe8;
  color: #5c9834;
  border-radius: 15px;
  font-size: 10px;
  padding: 0px 10px;
}
.csls-item h4 .tx-right-r,
.csls-item h4 .tx-right-g {
  font-weight: 300;
}
.csls-answer {
  background: #f1ffe8;
  padding: 10px;
  color: #5c9834;
  margin-top: 10px;
  border-radius: 0 15px 15px 15px;
  border: 1px solid #d2e8c3;
}
.csls-item .right-btn {
  float: right;
} */
</style>