<template>
  <div>
    <nut-navbar
    :left-show="false"
      :title="$t('myPlayList.h1')"
      fixed=true
      class="mb0 nav-icon-right nut-navbar--fixed"
    >
    <template #left>
      <i class="nutui-iconfont nut-icon nut-icon-left" @click="backClick"></i>
    </template>
    </nut-navbar>


    <div class="pt50 scroll-body">
      <div class="p20">
          <div class="tabs-nav">
            <ul>
              <li v-for="(item) in queryLimitEnum" :key="item.code" :class="item.active ? 'active' : ''" @click="tabsQueryLimit(item)">{{ item.description }}</li>
            </ul>
          </div>

          <ListEmpty v-show="listNoData"></ListEmpty>

          <div class="csls" id="scroll">
            <nut-infiniteloading
                containerId = 'scroll'
                :use-window='false'
                :has-more="hasNext"
                :load-txt='$t("global.loading")'
                :load-more-txt='$t("global.noMore")'
                :load-icon= 'null'
                @load-more="loadMore">

              <div class="csls-item-parity" v-for="(item, index) in list" :key="index">
                <div class="hd">
                  <span>{{ item.orderNum }}</span> - 
                  <span>{{ item.createTime }}</span>
                </div>
                <div class="bd">
                  <ul>
                    <li>
                      {{ $t('MINE.ROW') }}
                      <span :class="item.rowCount === 2 ? 'a':''">2</span>
                      <span :class="item.rowCount === 4 ? 'a':''">4</span>
                      <span :class="item.rowCount === 6 ? 'a':''">6</span>
                    </li>
                    <li>{{ $t('PARITY.POINT') }}: {{ $filters.currencySymbol(item.originBetAmount)}}</li>
                    <li>{{ $t('PARITY.FEE') }}: {{ $filters.currencySymbol(item.feeAmount)}}</li>
                    <li>{{ $t('PARITY.AMOUNT') }}: <strong> {{ $filters.currencySymbol(item.winAmount)}}</strong></li>
                    
                  </ul>
                  <ol :class="'x' + item.rowCount">
                    <li v-for="index of item.rowCount * item.rowCount"  :key="index">
                      <div v-for="i in item.sweptCells" :key="i" :class="index === i ? 'c' : ''"></div>
                      <div v-if="index === item.bombCell" class="b"></div>
                    </li>
                  </ol>
                </div>
                <div class="fd-w" v-if="item.winAmount > 0">
                  <strong>{{ $t('PARITY.WIN') }}</strong>
                </div>
                <div class="fd-l" v-else>
                  <strong>{{ $t('PARITY.LOSE') }}</strong>
                </div>
              </div>

            </nut-infiniteloading>
          </div>

      </div>
    </div>

  </div>
</template>

<script>
import { reactive, toRefs} from "vue";
import { useRouter } from "vue-router";
import axios from "axios";
import { Toast } from '@nutui/nutui';
import ListEmpty from "../components/ListEmpty.vue"

export default {
  components: {
    ListEmpty: ListEmpty,
  },
  data() {
    return {
      queryLimit:'today',
      queryLimitEnum:[
        {"code":"today","description": this.$t('global.Today'),"active":true},
        {"code":"one_week","description": this.$t('global.Week'),"active":false},
        {"code":"one_month","description": this.$t('global.Month1'),"active":false},
        {"code":"three_month","description": this.$t('global.Month3'),"active":false}],
      listNoData: true,
      list: [],
      current: 1,
      hasNext:''
    };
  },
  watch: {
    '$route': 'gettingData'
  },
  created() {
    this.getCoinList(1);
    this.textToast(this.$t('global.loading'))
  },
  methods: {
    loadMore(done){
      if(this.hasNext){
        this.getCoinList(this.current + 1)
        setTimeout(() => {
          done()
        }, 500);
      }
    },
    tabsQueryLimit(li) {
      for(let k in this.queryLimitEnum){
        this.queryLimitEnum[k].active = false
      }
      li.active = true
      this.queryLimit = li.code
      this.list = []
      this.getCoinList(1);
    },
    getCoinList(c) {
      this.textToast(this.$t('global.loading'))
      axios({
        url: "/api/mine/order/paginate",
        method: "get",
        params: { 
          current: c, 
          queryLimit: this.queryLimit,
        }
      }).then((res) => {
        for (let i = 0; i < res.data.data.records.length; i++) {
            this.list.push(res.data.data.records[i]);
        }
        this.current = res.data.data.current;
        this.hasNext = res.data.data.hasNext;
        if (this.list.length > 0) {
          this.listNoData = false;
        }else{
          this.listNoData = true;
        }
        Toast.hide("loading")
      }).catch(function (error) {
          console.log(error);
          Toast.hide("loading");
      })
    },
    textToast(msg) {
      Toast.loading(msg, {
        id:"loading",
        duration: 0,
        bgColor: "rgba(0, 0, 0, 0.9)",
        cover:true,
      });
    },
  },
  setup() {
    const state = reactive({
    });
    const router = useRouter();
    const methods = {
      backClick() {
        router.go(-1);
      },
    };
    return {
      ...toRefs(state),
      ...methods,
    };
  },
};
</script>

<style scoped>
.csls-item-parity{margin-top: 10px;border: 1px solid #ccc;border-radius:5px;overflow: hidden;background: #fff;}
.csls-item-parity .hd{display: flex; line-height: 30px;justify-content:space-between; background: #f5f5f5; padding: 0 10px; color: #504f3c;font-size: 10px;}
.csls-item-parity .bd{ text-align: center; display: flex;line-height: 20px;justify-content:space-between;padding: 10px;}
.csls-item-parity .bd ul{ width: 50% calc(100% - 90px); text-align: left;font-size: 12px; line-height: 25px;}
.csls-item-parity .bd ul span{ width: 20px; height: 20px; line-height: 20px; background: #eee; color: #666; 
  margin-right: 5px; border-radius:10px; display: inline-block; text-align: center;}
  .csls-item-parity .bd ul span.a{background: #40713b; color: #fff;  }
  .csls-item-parity .bd ul strong{ color: #40713b; font-size: 16px;}
.csls-item-parity .bd ol{ width: 110px; display: flex;flex-wrap: wrap; justify-content:center;}
.csls-item-parity .bd ol li{ height: 20px; width: 20px; padding: 0; margin: 1px; background: #eee; overflow: hidden; position: relative;}
.csls-item-parity .bd ol li div.c{ display: block;background: #c5b982; width: 100%; height: 100%;}
.csls-item-parity .bd ol li div.b{background: url(../assets/images/game_mine/bg130.png) 0 0 no-repeat; background-size:100% auto; width: 100%; height: 100%;}
.csls-item-parity .bd ol.x2 li{height: 50px; width: 50px; }
.csls-item-parity .bd ol.x4 li{height: 24px; width: 24px; }
.csls-item-parity .bd ol.x6 li{height: 16px; width: 16px; }
.csls-item-parity .bd span{ font-size: 12px;}
.csls-item-parity .fd-w{ line-height: 30px;border: 1px solid #c9e5c7;}
.csls-item-parity .fd-l{line-height: 30px; border: 1px solid #eee;}
.csls-item-parity .fd-l strong, .csls-item-parity .fd-w strong{ width: 100%; text-align: center; background: #eee; color: #666; 
    line-height: 30px; display: inline-block; padding: 0 10px;}
.csls-item-parity .fd-w strong{background: #c9e5c7; color: #40713b;}
</style>
