import FingerprintJS from "@fingerprintjs/fingerprintjs";
import {isBlank} from "@/assets/js/commonUtil";

async function getFp() {
    const fp = await FingerprintJS.load({
        monitoring: false
    });
    return await fp.get()
}

export async function getDeviceId() {
    let deviceId = localStorage.getItem("di")
    if (isBlank(deviceId)) {
        const fp = await getFp();
        deviceId = fp.visitorId;
        localStorage.setItem("di", deviceId);
    }
    return deviceId;
}
