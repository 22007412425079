<template>
  <div class="transactionsHistoryList">
    <nut-navbar
    :left-show="false"
      title="Game Order"
      fixed=true
      class="mb0 nav-icon-right nut-navbar--fixed"
    >
    <template #left>
      <i class="nutui-iconfont nut-icon nut-icon-left" @click="backClick"></i>
    </template>
    </nut-navbar>
    
    <div class="pt50 scroll-body">

      <div class="pt10">
          
          <div class="tabs-nav">
            <ul>
              <li v-for="(item) in queryLimitEnum" :key="item.code" :class="item.active ? 'active' : ''" @click="tabsQueryLimit(item)">{{ item.description }}</li>
            </ul>
          </div>
          
          <div v-if="gameName" class="top-title">
            Current: <strong>{{ gameName }}</strong> <button class="btn" @click="cancelAll">Cancel</button>
          </div>

          <ListEmpty v-show="listNoData"></ListEmpty>
          
          <div class="csls" id="scroll" style="top: 120px;">
            <nut-infiniteloading
                containerId = 'scroll'
                :use-window='false'
                :has-more="hasNext"
                :load-txt='$t("global.loading")'
                :load-more-txt='$t("global.noMore")'
                :load-icon= 'null'
                @load-more="loadMore">

              <div class="game-csls-item" v-for="(item, index) in list" :key="index">
                <h3>{{ item.gameName }}<button class="btn" @click="gameIdAll(item.gameId, item.gameName )">All</button></h3>
                <div class="flex-box">
                  <div class="left">
                    <img v-if="item.platform === 'cq9'" :src="require('../assets/images/lobby_icon/cq9/280_280_EN/280x280_EN_GAMEID_' + item.gameId + '.png')" />
                    <img v-else :src="require('../assets/images/lobby_icon/jili/260_380_EN/260x380_EN_GAMEID_' + item.gameId + '.png')" />
                  </div>
                  <div class="right">
                    <p><span>Bet Coin Amount:</span><strong>{{ $filters.currencySymbol(item.betCoinAmount) }}</strong></p>
                    <p><span>Bet Time:</span>{{ item.createTime }}</p>
                    <p class="win" v-if="item.win">Win: {{ item.winCoinAmount }}</p>
                    <p class="fail" v-else>Fail</p>
                  </div>
                </div>
              </div>

            </nut-infiniteloading>
          </div>

      </div>
    </div>
  </div>

</template>

<script>
import { reactive, toRefs} from "vue";
import { useRouter } from "vue-router";
import axios from "axios";
import { Toast } from '@nutui/nutui';
import ListEmpty from "../components/ListEmpty.vue"

export default {
  components: {
    ListEmpty: ListEmpty,
  },
  data() {
    return {
      queryLimit:'today',
      queryLimitEnum:[
        {"code":"today","description": this.$t('global.Today'),"active":true},
        {"code":"one_week","description": this.$t('global.Week'),"active":false},
        {"code":"one_month","description": this.$t('global.Month1'),"active":false},
        {"code":"three_month","description": this.$t('global.Month3'),"active":false}],
      list: [],
      gameId: null,
      gameName: null,
      listNoData: true,
      current: 1,
      hasNext:'',
      systemNotification: false
    };
  },
  methods: {
    loadMore(done){
      if(this.hasNext){
        this.getCoinList(this.current + 1)
        setTimeout(() => {
          done()
        }, 500);
      }
    },
    tabsQueryLimit(li) {
      for(let k in this.queryLimitEnum){
        this.queryLimitEnum[k].active = false
      }
      li.active = true
      this.queryLimit = li.code
      this.list = []
      this.getCoinList(1);
    },
    typeClick(id){
      this.gameId = id
      this.list = []
      this.getCoinList(1);
    },
    gameIdAll(id, name){
      this.gameId = id;
      this.gameName = name;
      this.list = []
      this.getCoinList(1)
    },
    cancelAll(){
      this.gameId = null;
      this.gameName = null;
      this.list = []
      this.getCoinList(1)
    },
    getCoinList(c) {
      this.textToast(this.$t('global.loading'))
      axios({
        url: "/api/game/order/paginate",
        method: "get",
        params: { current: c, gameId: this.gameId, queryLimit: this.queryLimit},
      }).then((res) => {
        for (let i = 0; i < res.data.data.records.length; i++) {
            this.list.push(res.data.data.records[i]);
        }
        this.current = res.data.data.current;
        this.hasNext = res.data.data.hasNext;
        if (this.list.length > 0) {
          this.listNoData = false;
        }else{
          this.listNoData = true;
        }
        Toast.hide("loading")
      });
    },
    textToast(msg) {
      Toast.loading(msg, {
        id:"loading",
        duration: 0,
        bgColor: "rgba(0, 0, 0, 0.9)",
        cover:true,
      });
    },
  },
  created() {
    if(this.$route.query.gameId){
      this.gameId = this.$route.query.gameId;
    }
    this.getCoinList(1);
  },

  setup() {
    const state = reactive({
    });
    const router = useRouter();
    const methods = {
      backClick() {
        router.go(-1);
      },
    };
    return {
      ...toRefs(state),
      ...methods,
    };
  },
};
</script>

<style scoped>
.pt10{ padding-top:0px;}
.nut-pagination {margin-top: 10px;justify-content: center;}
.csls-item .right-btn {float: right;}
</style>