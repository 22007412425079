<template>
  <div>
    <div class="wheel-main">

      <div class="wheel-hd">
        <div class="c">
          <span class="title">{{ $t('WHEEL.H1') }}</span>
          <span class="l" @click="rechargeClick">{{ $filters.currencySymbol(me.availableAmount) }}</span>
          <span class="r" @click="toMyPlayList()"><i> </i></span>
          <span class="r2" @click="toGameLobby()"><i> </i></span>
        </div>
      </div>


      <div class="wheel-body " :class="wheelBg">
          <div class="rule-btn" @click="rulePopup = true"></div>
          <div class="tx-last"></div>
          <div class="tx-History">{{$t('WHEEL.HISTORY')}}</div>
          <div class="h380">
            
            <div class="canvas-main">
              <!-- 画板 -->
              <canvas id="canvas" width="100%" height="100%" style="overflow: hidden;"></canvas>

              <!-- 状态倒计时 -->
              <div class="rocket-data" v-show="pointer">
                <div class="c1" v-if="game.stage === this.stageEnum.bet">
                  <strong>
                    <nut-countdown 
                      :endTime="game.betDeadline" 
                      millisecond 
                      format="mm:ss"
                      style="font-size:20px;color: #c12900; display: inline;"/>
                  </strong>
                  <p><span>{{$t('WHEEL.BET_TIME')}}</span></p>
                </div>
                <div class="c1" v-else-if="game.stage === this.stageEnum.rotate || game.stage === this.stageEnum.wait">
                  <strong>
                    {{ $t('WHEEL.PLAYING') }}
                  </strong>
                  <p><span>{{ $t('WHEEL.PLEASE_WAIT') }}</span></p>
                </div>
                <div class="c1" v-else-if="game.stage === this.stageEnum.pause">
                  <strong>
                    {{ $t('game.paused') }}
                  </strong>
                  <p><span>{{ $t('game.paused') }}</span></p>
                </div>

                <div :class="textAnimation ? 'c2 text-animation' : 'c2'">
                  <strong :class="text === textStart ? 'bg-g' : 'bg-r'"><span>{{ text }}</span></strong>
                </div>

                <div v-show="settle.myWin" class="my-win">
                  <span>{{ $t('game.winAmount') }}<strong>{{ settle.totalWinAmount  }}</strong></span>
                </div>
              </div>
              
              <div class="all-user"></div>

              <div v-for="(item, index) in this.game.currentOrders" :key="index" :class="item.color ? 'icon-gold-user gold-user-'+ item.color : 'icon-gold-user'"></div>

              <div v-for="(item, index) in 20" :key="index" v-show="iconGold" :class="'icon-gold gold-'+ (index+1) + '-' + iconGoldColor"></div>

              
            </div>
          </div>

        
        <div class="h300">
          <div class="w435">
            <div class="ls-result">
              <ul>
                <li v-for="(item, index) in game.histories" 
                  :key="index"
                  :class="'result-'+ item.resultColor">
                  <!--{{ item.shortSerialNum }}-->
                </li>
              </ul>
            </div>
            <div class="bet-container">
              <ul>
                <button @click="bet(betColor.yellow)" :disabled="!betBtnState.yellow" :class="betBtnState.yellow ? '':'disabled'">
                  <span>{{ $filters.currencySymbol(parseInt(addUserBet.yellow)) }}</span>
                  <h5><strong>2x</strong></h5>
                  <strong v-if="myBet.colorBetAmount.yellow > 0" class="pa c-y">{{ $t('PARITY.MY_POINT') }}: {{ $filters.currencySymbol(myBet.colorBetAmount.yellow) }}</strong>
                  <strong v-else  class="pa">{{ $t('WHEEL.CLICK_TO_BET') }}</strong>
                </button>
                <button @click="bet(betColor.red)" :disabled="!betBtnState.red" :class="betBtnState.red ? '':'disabled'">
                  <span>{{ $filters.currencySymbol(parseInt(addUserBet.red)) }}</span>
                  <h5><strong>9x</strong></h5>
                  <strong v-if="myBet.colorBetAmount.red > 0" class="pa c-y">{{ $t('PARITY.MY_POINT') }}: {{ $filters.currencySymbol(myBet.colorBetAmount.red) }}</strong>
                  <strong v-else  class="pa">{{ $t('WHEEL.CLICK_TO_BET') }}</strong>
                </button>
                <button @click="bet(betColor.blue)" :disabled="!betBtnState.blue" :class="betBtnState.blue ? '':'disabled'">
                  <span>{{ $filters.currencySymbol(parseInt(addUserBet.blue)) }}</span>
                  <h5><strong>2x</strong></h5>
                  <strong v-if="myBet.colorBetAmount.blue > 0" class="pa c-y">{{ $t('PARITY.MY_POINT') }}: {{ $filters.currencySymbol(myBet.colorBetAmount.blue) }}</strong>
                  <strong v-else  class="pa">{{ $t('WHEEL.CLICK_TO_BET') }}</strong>
                </button>
              </ul>
              <div class="btn-block" v-show="btnMask">
                <nut-icon name="loading" size="25" style="margin-top: 31px;"></nut-icon>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="wheel-fd">
        <div class="w435">
          <div class="my-data">
            <label>{{ $t('PARITY.MY_POINT') }}:</label>
            <strong>{{ $filters.currencySymbol(myBet.colorBetAmount.yellow) }}</strong>
          </div>
          <ul>
            <li v-for="(item, index) in initData.recommendBetAmountList" 
              :key="index" class="b-btn" 
              :class="{avatar:initData.iscur == index}"
              @click="betAmountBtnClick(item, index)">
              <span v-if="(item >= initData.betAmountMin && item <= initData.betAmountMax) ? true : false">{{ item }}</span>
              <span v-else style="opacity: .3;">{{ item }}</span>
            </li>
            <li class="b-btn" :class="{avatar:initData.iscur == initData.recommendBetAmountList.length}" @click="betAmountBtnClick(me.availableAmount, initData.recommendBetAmountList.length)"><span>ALL</span></li>
          </ul>
        </div>

      </div>

      <div class="icon-bet-gold" :class="'icon-bet-gold-' + betGoldColor"></div>

      <!--**************************************** 游戏规则 ****************************************-->
      <nut-popup position="top" closeable round :style="{ height: '50%',  background:'#30284C' }" v-model:visible="rulePopup">
          <GameRulesWheel></GameRulesWheel>
      </nut-popup>

    <!--**************************************** Big win ****************************************-->
    <nut-popup pop-class="pop-top-bigwin" v-model:visible="bigwinData.show" position="left" :closeable="true" :overlay="false" style="top: 5px !important;">
      <div class="bd">
        <div class="t1"><img class="avatar" :src="require('../assets/images/global/bigwin.png')" >
            <span>{{ bigwinData.data.mobile }}</span>
          </div>
        <div v-if="bigwinData.data.appTypeCode === 'crash'" class="t2 crash"><span>{{ bigwinData.data.crashEscapeRatio }}x</span></div>
        <div v-else-if="bigwinData.data.appTypeCode === 'parity'" class="t2 parity"><span>{{ bigwinData.data.parityRoomName }}</span></div>
        <div v-else-if="bigwinData.data.appTypeCode === 'wheel'" class="t2 wheel"><span> </span></div>
        <div v-else-if="bigwinData.data.appTypeCode === 'mine'" class="t2 mine"><span>{{ bigwinData.data.mineRowCount }} X {{ bigwinData.data.mineRowCount }}</span></div>
        <div v-else-if="bigwinData.data.appTypeCode === 'andar'" class="t2 andar"><span></span></div>
        <div v-else-if="bigwinData.data.appTypeCode === 'dice'" class="t2 dice"><span></span></div>
        <div class="t3"><span>WIN</span><strong>{{ bigwinData.data.winAmount }}</strong></div>
      </div>
    </nut-popup>
    
    <!--**************************************** Broadcast Msg ****************************************-->
    <nut-popup pop-class="pop-top-broadcast-msg" v-model:visible="broadcastMsgData.show" position="left" :closeable="true" :overlay="false" style="top: 5px !important;">
      <div class="bd">
        <div class="t4" v-if="broadcastMsgData.type === 'parity_prediction'">&nbsp;</div>
        <div class="t5"><span>{{ broadcastMsgData.message}}</span></div>
      </div>
    </nut-popup>


    <nut-overlay v-model:visible="overlayShow" :close-on-click-overlay="false" :z-index="2000" overlay-class="overlay-div overlay-bg0">
    </nut-overlay>


    </div>
  </div>

</template>

<script>
import { fabric } from "fabric";
import { reactive, toRefs } from "vue";
import { useRouter } from "vue-router";
import axios from "axios";
import { Toast } from "@nutui/nutui";
import { getCountry,setLastTimeGameName } from "@/assets/js/environment"
import { getCommonInfoAttr } from "@/assets/js/commonInfo";
import { isBlank, isNotBlank } from "@/assets/js/commonUtil";
import GameRulesWheel from "../components/GameRulesWheel.vue"
import { commonEvent, createSocket, disconnectSocket, gameType, initGame} from "@/assets/js/game";

export default {
  name: "wheelHome",
  components: {
    GameRulesWheel: GameRulesWheel,
  },
  data() {
    return {
      country: '', //国家
      commonInfo: null, //通用信息
      imgUrl: '', //资源图片路径
      rulePopup: false, //游戏说明 显示开关
      btnMask: false, //下注按钮遮罩
      pointer: false, //指针 显示开关
      wheelBg: 'wheel-bg-wait',
      iconGold: false, //金币
      iconGoldColor:'',  //赢取金币的颜色
      betGoldColor:'',
      iconGoldAllUser: false, //全部用户下注金币
      text: '', //文字
      textStart:'START',
      textStop:'STOP',
      textAnimation: false, //文字闪现 
      showDialogFill: true, //防止叠加弹窗
      toRechargeDialog: false,
      overlayShow: true,
      //画布
      draw: {
        canvas: null, //画布对象
        wheelImgUrl: '' //轮盘图片路径
      },
      //初始数据
      initData:{
        feeRatio: 0,
        recommendBetAmountList: [],
        betAmountMin: 10,
        betAmountMax: 100000,
        iscur: 0, //下注金额按钮默认index
        betDeadline: 15 * 1000,   //下注时间
        rotateDeadline: 3 * 1000, //旋转时间
        waitDeadline: 2 * 1000,   //减速时间
      },
      //socket事件
      eventEnum: {
        initRoom: "s_wl_init_room", //初始化房间
        startBet: "s_wl_start_bet", //开始下注阶段
        startRotate: "s_wl_start_rotate", //开始旋转阶段
        startWait: "s_wl_start_wait", //开始等待阶段 减速
        end: "s_wl_end", //本局游戏结束
        playerInfo: "s_wl_player", //当前局玩家信息
        pause: "s_wl_pause", //游戏暂停
        settle: "s_wl_settle" //结算
      },
      //游戏阶段
      stageEnum: {
        bet: "bet", //下注
        rotate: "rotate", //旋转
        wait: "wait", //等待
        pause: "pause", //暂停
        settle: "settle", //结算
        end: "end"
      },
      addUserBet: {
        red: 0,
        yellow: 0,
        blue: 0
      },
      //下注类型
      betColor:{
        red: 'Red',
        yellow: 'Yellow',
        blue: 'Blue'
      },
      //下注按钮状态
      betBtnState: {
        red: false,
        yellow: false,
        blue: false
      },
      //游戏数据
      game: {
        pause: false,
        stage: null,
        lastResultAngle: 0,
        resultAngle: 0,
        currentOrders:[],
        betDeadline: null,
        histories:[]
      },
      //结算信息
      settle: {
        serialNum: null,
        resultColor: null,
        availableAmount: null,
        needWithdraw: false,
        totalProfitAmount: 0,
        totalWinAmount: 0,
        orders: [],
        myWin:false,
      },
      //我的信息
      me: {
        vipLevel: 0,
        avatar: '',
        availableAmount: 0,
        chipAmount: 0,
        rechargeAmount: null,
        withdrawAmount: null,
        inRookieProcess: null, //是否处于 新手流程
        needWithdraw: null, //是否处于 需要提现
        currentOrders:[]
      },
      //下注操作
      myBet: { 
        betColor: null,
        betAmount: 0,
        totalBetAmount: 0,
        colorBetAmount:{
          red: 0,
          yellow: 0,
          blue: 0
        },
        betting: false,
        orders: [], //本局下注成功的订单
      },
      //bigwin
      bigwinData: { 
        show: false,
        start: false,
        list: [],
        data: {}
      },
      //红绿预测广播
      broadcastMsgData: {
        show: false,
        type: '',
        message: '',
      },
      appTaskStatus: '',
      appTaskReward: '',
    };
  },
  created() {
    this.country = getCountry()
    this.imgUrl = localStorage.getItem('countryImgUrl')
    this.draw.wheelImgUrl = require('../assets/images/game_wheel/bg185.png') //轮盘图片路径
    this.commonInfo = JSON.parse(localStorage.getItem("commonInfo"))

    this.initData.recommendBetAmountList = this.commonInfo.recommendBetAmountList;
    this.initData.betAmountMax = Math.floor(this.commonInfo.betAmountRange.max)
    this.initData.betAmountMin = Math.floor(this.commonInfo.betAmountRange.min)


    this.myBet.betAmount = this.commonInfo.betAmountRange.min;
    setLastTimeGameName('wheel','lobby')
  },
  setup() {
    const state = reactive({
    });
    const router = useRouter();
    const methods = {
      //跳转到游戏大厅
      toGameLobby(){
        router.push({path: "lobby"});
      },
      //跳转到我的下注历史
      toMyPlayList() {
        router.push({path: "myPlayListWheel"});
      },
      //去充值
      rechargeClick() {
        if(this.commonInfo.firstRechargeAvailable){
          router.push('/firstRechargeBonus');
        }else{
          router.push('/rechargeBalance');
        }
      },
      //去提现
      withdrawClick() {
        router.push('/withdrawalBalance?amountType=coin');
      },
    };
    return {
      ...toRefs(state),
      ...methods,
    };
  },

  // ======================================== mounted ========================================
  mounted() {
    Toast.loading('Loading...', {
      id: "loading",
      duration: 0,
      bgColor: "rgba(0, 0, 0, 0.9)",
      cover: true,
    });
    /**
     * 初始化接口
     */
    initGame(gameType.WHEEL).then((response) => {
      const data = response.data.data;
      this.initData.feeRatio = data.feeRatio
      if(isNotBlank(data.appTaskStatus)){
        this.appTaskReward = data.appTaskReward
        this.appTaskStatus = data.appTaskStatus
      }
      Toast.hide("loading");
      //游客id
      if (isNotBlank(data.vtoken)) {
        localStorage.setItem("vtoken", data.vtoken);
      }
      //初始化socket
      this.initSocket();
      
    })
    //创建画布
    this.draw.canvas = new fabric.Canvas("canvas", {width: 350, height: 330});
    //加载历史结果
    this.loadHistory()
  },

  // ======================================== beforeRouteLeave ========================================
  beforeRouteLeave() {
    //断开游戏socket
    disconnectSocket(gameType.WHEEL, this.socket);
    Toast.hide()
  },

  // ======================================== methods ========================================
  methods: {
    textToast(msg) {
      Toast.loading(msg, {
        id: "loading",
        duration: 0,
        bgColor: "rgba(0, 0, 0, 0.9)",
        cover: true,
      });
    },
    //禁止拖拽
    noDragging(obj) {
      obj.hasBorders = false
      obj.hasControls = false
      obj.hasRotatingPoint = false
      obj.evented = false
    },
    //重置画布
    resetCanvas() {
      this.draw.canvas.clear();
    },
    //开始转盘
    wheelRotate(){
      this.resetCanvas();
      //创建转盘
      fabric.Image.fromURL(this.draw.wheelImgUrl, (oImg) => {
        oImg.hasBorders = false;
        oImg.hasControls = false;
        oImg.scaleToWidth(350);
        oImg.scaleToHeight(350);
        oImg.set({
          left:175,
          top:165,
          width:700,
          height:700,
          angle: 0,
          originX: 'center',
          originY: 'center'
        })
        var DRAW = this.draw;
        DRAW.canvas.add(oImg)
        //旋转状态
        if(this.game.stage === 'rotate'){
          oImg.animate('angle',-3600,{
            duration: 3500,
            onChange: DRAW.canvas.renderAll.bind(DRAW.canvas),
            onComplete: function(){
              wheelResultAnimate(oImg,) //回调 
            }
          })
        }
      })
      //转盘开结果
      const wheelResultAnimate = (o) =>{
        this.game.resultAngle += 360
        o.set({angle: 0,})
        this.draw.canvas.renderAll()
        o.animate('angle',-this.game.resultAngle,{
          duration: 2000,
          onChange: this.draw.canvas.renderAll.bind(this.draw.canvas),
          onComplete: function(){
            winTime()
          },
          easing: fabric.util.ease.easeOutElastic    //easeOutCubic  easeOutExpo        
        })
      }
      //结算动画
      const winTime = () =>{
        this.wheelBg = 'wheel-bg-win';
        this.iconGold = true;
        setTimeout(() => {
          this.iconGold = false;
        }, 1500);
      }
      //创建指针
      // let imgUrl2 = require('../assets/images/game_wheel/bg186.png')
      // fabric.Image.fromURL(imgUrl2, (oImg) => {
      //   oImg.hasBorders = false;
      //   oImg.hasControls = false;
      //   oImg.scaleToWidth(132);
      //   oImg.scaleToHeight(132);
      //   oImg.set({
      //     left:175,
      //     top:175,
      //     angle: 0,
      //     originX: 'center',
      //     originY: 'center'
      //   })
      //   this.draw.canvas.add(oImg)
      // })
    },
    //下注金额按钮
    betAmountBtnClick(a,i){
      if (a > this.initData.betAmountMax) {
        if(i === this.initData.recommendBetAmountList.length){
          this.initData.iscur = i;
          this.myBet.betAmount = Math.floor(this.initData.betAmountMax)
        }else{
          Toast.fail("VIP only") 
        }
      } else if (a < this.initData.betAmountMin) {
        Toast.fail("Min bet " + this.initData.betAmountMin) 
      } else {
        this.initData.iscur = i;
        this.myBet.betAmount = Math.floor(a)
      }
    },
    /**
     * 刷新游戏历史
     */
    loadHistory() {
      axios.get("/api/wheel/history/recent")
        .then((res) => {
          this.game.histories = res.data.data
        });
    },

    // ======================================== 下注 ========================================
    bet(betColor) {
      this.btnMask = true
      this.betGoldColor = ''
      if (isBlank(this.myBet.betAmount) || this.myBet.betAmount <= 0) {
        Toast.fail(this.$t('PARITY.PLEASE_CONFIRM_YOUR_INPUT'))
        this.btnMask = false
        return;
      }
      this.myBet.betColor = betColor
      this.myBet.betting = true;
      axios.post("/api/wheel/bet",
        {
          betColor: this.myBet.betColor,
          betAmount: this.myBet.betAmount
        }
      ).then((res) => {
        this.myBet.betting = false;

        let data = res.data;
        if (data.code === 0) {
          this.betGoldColor = this.myBet.betColor
          //Toast.success(this.$t('global.successfully') + ' ' + this.myBet.betAmount);
          switch (betColor) {
            case this.betColor.yellow:
              this.betBtnState.blue = false
              this.myBet.colorBetAmount.yellow += this.myBet.betAmount
              if(this.me.inRookieProcess){
                this.betBtnState.yellow = false
                this.betBtnState.red = false
              }
              break;
            case this.betColor.blue:
              this.betBtnState.yellow = false
              this.myBet.colorBetAmount.blue += this.myBet.betAmount
              if(this.me.inRookieProcess){
                this.betBtnState.blue = false
                this.betBtnState.red = false
              }
              break;
            case this.betColor.red:
              this.myBet.colorBetAmount.red += this.myBet.betAmount
              if(this.me.inRookieProcess){
                this.betBtnState.yellow = false
                this.betBtnState.blue = false
                this.betBtnState.red = false
              }
              break;
          }
          this.me.availableAmount = data.data.availableAmount;
          this.myBet.orders = data.data.orders;
          //计算总下注金额
          // this.myBet.totalBetAmount = 0;
          // this.myBet.orders.forEach(myPlay => {
          //   this.myBet.totalBetAmount = this.myBet.totalBetAmount + myPlay.originBetAmount;
          // });
        }
        this.btnMask = false
      });
    },
    //转化时间
    convertTime(timeSerial) {
      return timeSerial.substring(0, 2) + ':' + timeSerial.substring(2, 4)
    },
    // ======================================== websocket ========================================
    initSocket() {
      if (this.socket != null) {
        return;
      }
      // 创建
      this.socket = createSocket(gameType.WHEEL,this.bigwinData, this.broadcastMsgData);
      // 就绪
      this.socket.on(commonEvent.ready, () => {
        this.socket.emit(commonEvent.join);
        this.overlayShow = false
      });
      /**
       * 初始化房间
       */
      this.socket.on(this.eventEnum.initRoom, (room) => {
        this.game.stage = room.stage
        this.wheelRotate()
        //游戏状态
        if (this.game.stage === this.stageEnum.pause) {
          this.game.pause = true;
        }
        //游戏状态
        if (this.game.stage === this.stageEnum.bet) {
          //this.game.resultAngle = room.lastResultAngle
          this.betBtnState.yellow = true
          this.betBtnState.blue = true
          this.betBtnState.red = true
          //下注阶段剩余时间
          let betRemainMillis = room.betRemainMillis;
          if (isNotBlank(betRemainMillis)) {
            this.game.betDeadline = Date.now() + betRemainMillis;
          }
          //其他玩家订单
          this.game.currentOrders = room.currentOrders
          for (let k in this.game.currentOrders) {
            setTimeout(() => {
              switch (this.game.currentOrders[k].betColor) {
                case this.betColor.yellow:
                  this.game.currentOrders[k].color = this.betColor.yellow
                  this.addUserBet.yellow += room.currentOrders[k].originBetAmount
                  break;
                case this.betColor.blue:
                  this.game.currentOrders[k].color = this.betColor.blue
                  this.addUserBet.blue += room.currentOrders[k].originBetAmount
                  break;
                case this.betColor.red:
                  this.game.currentOrders[k].color = this.betColor.red
                  this.addUserBet.red += room.currentOrders[k].originBetAmount
                  break;
              }    
            }, room.currentOrders[k].betWaitMillis)
          }

        }
        
        //显示指针
        this.pointer = true

      });

      /**
       * 我的信息
       */
      this.socket.on(this.eventEnum.playerInfo, (playerInfo) => {
        //玩家信息
        this.me.vipLevel = playerInfo.vipLevel
        this.me.avatar = ''
        this.me.availableAmount = playerInfo.availableAmount
        this.me.chipAmount = playerInfo.chipAmount
        this.me.rechargeAmount = playerInfo.rechargeAmount
        this.me.withdrawAmount = playerInfo.withdrawAmount
        this.me.inRookieProcess = playerInfo.inRookieProcess
        this.me.needWithdraw = playerInfo.needWithdraw
        this.me.currentOrders = playerInfo.currentOrders

        //纯净模式下如果小于最小下注金额 提示试玩
        if(this.commonInfo.pureMode && this.me.availableAmount < this.initData.recommendBetAmountList[0]){
          this.toRechargeDialog = true
        }

        //设置默认下注金额按钮选中
        this.initData.iscur = 0
        this.myBet.betAmount = this.initData.recommendBetAmountList[0]

      });
      /**
       * 进入下注阶段
       */
      this.socket.on(this.eventEnum.startBet, (event) => {
        this.text = this.textStart
        this.textAnimation = true
        setTimeout(() => {
          this.textAnimation = false
        }, 1500);
        this.wheelBg = 'wheel-bg-wait';
        this.game.stage = this.stageEnum.bet;
        this.game.betDeadline = Date.now() + this.initData.betDeadline;
        //恢复按钮可点击状态
        this.betBtnState = {
          red: true,
          yellow: true,
          blue: true
        },
        //清空其他玩家下注金额
        this.addUserBet = {
          red: 0,
          yellow: 0,
          blue: 0
        },
        //清空下注信息
        this.game.currentOrders = []
        this.myBet.orders = [];
        this.myBet.totalBetAmount = 0;
        this.myBet.colorBetAmount.red = 0;
        this.myBet.colorBetAmount.yellow = 0;
        this.myBet.colorBetAmount.blue = 0;
        this.game.pause = false;

        //其他玩家订单
        this.game.currentOrders = event.currentOrders
        for (let k in this.game.currentOrders) {
          setTimeout(() => {
            switch (this.game.currentOrders[k].betColor) {
              case this.betColor.yellow:
                this.game.currentOrders[k].color = this.betColor.yellow
                this.addUserBet.yellow += event.currentOrders[k].originBetAmount
                break;
              case this.betColor.blue:
                this.game.currentOrders[k].color = this.betColor.blue
                this.addUserBet.blue += event.currentOrders[k].originBetAmount
                break;
              case this.betColor.red:
                this.game.currentOrders[k].color = this.betColor.red
                this.addUserBet.red += event.currentOrders[k].originBetAmount
                break;
            }    
            //this.usersBetList.unshift(event.currentOrders[k])
          }, event.currentOrders[k].betWaitMillis)
        }

      });
      /**
       * 进入旋转阶段
       */
      this.socket.on(this.eventEnum.startRotate, () => {
        this.text = this.textStop
        this.textAnimation = true
        this.game.currentOrders = []
        setTimeout(() => {
          this.textAnimation = false
        }, 1500);
        this.wheelBg = 'wheel-bg-start';
        this.game.stage = this.stageEnum.rotate;
        //设置下注按钮不可点击
        this.betBtnState = {
          red: false,
          yellow: false,
          blue: false
        }
        this.wheelRotate()
      });
      /**
       * 进入等待阶段 减速
       */
      this.socket.on(this.eventEnum.startWait, (event) => {
        this.game.stage = this.stageEnum.wait;
        this.game.pause = false;
        this.game.resultAngle = event.resultAngle
        this.iconGoldColor = event.resultColor
      });

      /**
       * 进入结束阶段
       */
      this.socket.on(this.eventEnum.end, () => {
        //this.draw.canvas.clear();
        this.game.stage = this.stageEnum.end;
        this.game.pause = false;
        this.loadHistory()
      });

      /**
       * 结算信息
       */
      this.socket.on(this.eventEnum.settle, (event) => {
        this.me.availableAmount = event.availableAmount;
        this.settle.serialNum = event.serialNum;
        this.settle.resultColor = event.resultColor;
        this.settle.orders = event.orders;
        this.settle.availableAmount = event.availableAmount;
        this.settle.totalWinAmount = event.totalWinAmount;
        this.settle.totalProfitAmount = event.totalProfitAmount;
        this.settle.needWithdraw = event.needWithdraw;
        if(event.totalWinAmount > 0){
          this.settle.myWin = true
          setTimeout(() => {
            this.settle.myWin = false
          }, 1500);
        }

      });
    },
    commonInfoAttr(key) {
      return getCommonInfoAttr(key);
    },

  },
};
</script>

<style scoped src="../assets/css/game_wheel.css"></style>
<style scoped>
</style>
<style>
.wheel-body .canvas-container {margin: 0 auto !important;}
</style>



