<template>
  <div>
    <nut-navbar
    :left-show="false"
      :title="$t('myPlayList.h1')"
      fixed=true
      class="mb0 nav-icon-right nut-navbar--fixed"
    >
    <template #left>
      <i class="nutui-iconfont nut-icon nut-icon-left" @click="backClick"></i>
    </template>
    </nut-navbar>


    <div class="pt50 scroll-body">
      <div class="p20">

          <div class="tabs-nav">
            <ul>
              <li v-for="(item) in queryLimitEnum" :key="item.code" :class="item.active ? 'active' : ''" @click="tabsQueryLimit(item)">{{ item.description }}</li>
            </ul>
          </div>

          <ListEmpty v-show="listNoData"></ListEmpty>

          <div class="csls" id="scroll">
            <nut-infiniteloading
                containerId = 'scroll'
                :use-window='false'
                :has-more="hasNext"
                :load-txt='$t("global.loading")'
                :load-more-txt='$t("global.noMore")'
                :load-icon= 'null'
                @load-more="loadMore">

              <div class="csls-item-parity" v-for="(item, index) in list" :key="index">
                <div class="hd">
                  <span>{{ item.serialNum }}</span>
                </div>
                <div class="bd">
                  <span v-if="isNotBlank(item.createTime)">{{ item.createTime }}</span>

                  <span class="d-f"  v-if="item.betType === 'suit'">
                    <div>Suit:</div>
                    <div>
                      <strong :class="'suit-' + item.betTarget"></strong>
                    </div>
                  </span>
                  <span class="d-f"  v-if="item.betType === 'num_invl'">
                    <div>Num Invl:</div>
                    <div>
                      <strong>
                        <span v-if="item.betTarget === 'below_7'">1-6</span>
                        <span v-else-if="item.betTarget === 'exactly_7'">7</span>
                        <span v-else-if="item.betTarget === 'above_7'">8-13</span>
                      </strong>
                    </div>
                  </span>
                  <span class="d-f"  v-if="item.betType === 'side'">
                    <div>Side:</div>
                    <div>
                      <strong>
                        <span v-if="item.betTarget === 'andar'">Andar</span>
                        <span v-else-if="item.betTarget === 'bahar'">Bahar</span>
                      </strong>
                    </div>
                  </span>
                  <span class="d-f"  v-if="item.betType === 'seq_invl'">
                    <div>Seq Invl:</div>
                    <div>
                      <strong>
                        <span v-if="item.betTarget === '1_5'">1-5</span>
                        <span v-else-if="item.betTarget === '6_10'">6-10</span>
                        <span v-else-if="item.betTarget === '11_15'">11-15</span>
                        <span v-else-if="item.betTarget === '16_25'">16-25</span>
                        <span v-else-if="item.betTarget === '26_30'">26-30</span>
                        <span v-else-if="item.betTarget === '31_35'">31-35</span>
                        <span v-else-if="item.betTarget === '36_40'">36-40</span>
                        <span v-else-if="item.betTarget === '41_'">41-51</span>
                      </strong>
                    </div>
                  </span>

                </div>

                <div class="bd">
                  <span>{{ $t('PARITY.INPUT') }}: {{ $filters.currencySymbol(item.originBetAmount) }}</span>
                  <span>{{ $t('PARITY.FEE') }}: {{ $filters.currencySymbol(item.feeAmount) }}</span>
                </div>

                <div class="fd-w" v-if="item.win">
                  <strong>{{ $t('PARITY.WIN') }} +{{ $filters.currencySymbol(item.winAmount) }}</strong>
                </div>
                <div class="fd-l" v-else>
                  <strong>{{ $t('PARITY.LOSE') }}</strong>
                </div>
              </div>

            </nut-infiniteloading>
          </div>

      </div>
    </div>

  </div>
</template>

<script>
import { reactive, toRefs} from "vue";
import { useRouter } from "vue-router";
import axios from "axios";
import { Toast } from '@nutui/nutui';
import {isNotBlank} from "@/assets/js/commonUtil";
import ListEmpty from "../components/ListEmpty.vue"

export default {
  components: {
    ListEmpty: ListEmpty,
  },
  data() {
    return {
      queryLimit:'today',
      queryLimitEnum:[
        {"code":"today","description": this.$t('global.Today'),"active":true},
        {"code":"one_week","description": this.$t('global.Week'),"active":false},
        {"code":"one_month","description": this.$t('global.Month1'),"active":false},
        {"code":"three_month","description": this.$t('global.Month3'),"active":false}],
      listNoData: true,
      list: [],
      current: 1,
      hasNext:''
    };
  },
  created() {
    this.getCoinList(1);
    this.textToast(this.$t('global.loading'))
  },
  methods: {
    isNotBlank(o) {
      return isNotBlank(o);
    },
    loadMore(done){
      if(this.hasNext){
        this.getCoinList(this.current + 1)
        setTimeout(() => {
          done()
        }, 500);
      }
    },
    tabsQueryLimit(li) {
      for(let k in this.queryLimitEnum){
        this.queryLimitEnum[k].active = false
      }
      li.active = true
      this.queryLimit = li.code
      this.list = []
      this.getCoinList(1);
    },
    getCoinList(c) {
      this.textToast(this.$t('global.loading'))
      axios({
        url: "/api/andar/order/paginate",
        method: "get",
        params: { 
          current: c, 
          queryLimit: this.queryLimit
        }
      }).then((res) => {
        for (let i = 0; i < res.data.data.records.length; i++) {
            this.list.push(res.data.data.records[i]);
        }
        this.current = res.data.data.current;
        this.hasNext = res.data.data.hasNext;
        if (this.list.length > 0) {
          this.listNoData = false;
        }else{
          this.listNoData = true;
        }
        Toast.hide("loading")
      }).catch(function (error) {
          console.log(error);
          Toast.hide("loading");
      })
    },
    textToast(msg) {
      Toast.loading(msg, {
        id:"loading",
        duration: 0,
        bgColor: "rgba(0, 0, 0, 0.9)",
        cover:true,
      });
    },
  },
  setup() {
    const state = reactive({
    });
    const router = useRouter();
    const methods = {
      backClick() {
        router.go(-1);
      },
    };
    return {
      ...toRefs(state),
      ...methods,
    };
  },
};
</script>

<style scoped>
.csls-item-parity{
  margin-bottom: 15px;
  border: 1px solid #f2f1d7;
  border-radius:5px;
   overflow: hidden;
   background: #fff;
}
.csls-item-parity .hd{display: flex; line-height: 30px;justify-content:space-between; background: #f2f1d7; padding: 0 10px; color: #504f3c;font-size: 12px;}
.csls-item-parity .bd{ text-align: center; display: flex;line-height: 30px;justify-content:space-between;padding: 0 5px;}
.csls-item-parity .bd span{ font-size: 12px;padding: 0 5px;}
.csls-item-parity .fd-w{
  line-height: 30px;
  border: 1px solid #c9e5c7;
}
.csls-item-parity .fd-l{
  line-height: 30px;
  border: 1px solid #eee;
}
.csls-item-parity .fd-l strong, .csls-item-parity .fd-w strong{ width: 100%; text-align: center; background: #eee; color: #666; 
    line-height: 30px; display: inline-block; padding: 0 10px;}
.csls-item-parity .fd-w strong{background: #c9e5c7; color: #40713b;}

.d-f{ display: flex;}
.d-f div{ margin: 0 5px;}
.suit-spade, .suit-heart, .suit-club,.suit-diamond{ display: inline-block; height: 20px; }
.suit-spade{background: url(../assets/images/game_andar/bg-ab09.png) center center no-repeat; background-size:20px auto; width: 20px; vertical-align: middle;}
.suit-heart{background: url(../assets/images/game_andar/bg-ab10.png) center center no-repeat; background-size:20px auto; width: 20px;vertical-align: middle;}
.suit-club{background: url(../assets/images/game_andar/bg-ab11.png) center center no-repeat; background-size:20px auto; width: 20px;vertical-align: middle;}
.suit-diamond{background: url(../assets/images/game_andar/bg-ab12.png) center center no-repeat; background-size:20px auto; width: 20px;vertical-align: middle;}


.nut-pagination {
  margin-top: 10px;
  justify-content: center;
}
.csls{
  overflow-y: auto;
  overflow-x: hidden;
  position: absolute;
  left: 10px;right: 10px;top: 110px; bottom: 10px;
}
.csls-item {
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #eee;
}
.csls-item h4 {
  margin: 5px 0;
  line-height: 24px;
}

.csls-item p {
  line-height: 24px;
  padding: 0;
  margin: 0;
  color: #999;
}
.csls-item .tx-right-r {
  float: right;
  line-height: 22px;
  background: #f1ffe8;
  color: #5c9834;
  border-radius: 15px;
  font-size: 10px;
  padding: 0px 10px;
}
.csls-item .tx-right-g {
  float: right;
  line-height: 22px;
  background: #fff3f3;
  color: #cf0505;
  border-radius: 15px;
  font-size: 10px;
  padding: 0px 10px;
}
.csls-item h4 .tx-right-r,
.csls-item h4 .tx-right-g {
  font-weight: 300;
}
.csls-answer {
  background: #f1ffe8;
  padding: 10px;
  color: #5c9834;
  margin-top: 10px;
  border-radius: 0 15px 15px 15px;
  border: 1px solid #d2e8c3;
}
.csls-item .right-btn {
  float: right;
}
</style>

<style>
.nav-icon-right .nut-navbar__title .nut-icon {
  position: absolute !important;
  right: 10px !important;
}
</style>
