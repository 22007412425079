<template>
<div>
  <nut-navbar
    :left-show="false"
    @on-click-icon="titIconClick"
    :title="$t('bankCard.h1')"
    titIcon="home"
    fixed=true
    class="mb0 nav-icon-right nut-navbar--fixed">
    <template #left>
      <i class="nutui-iconfont nut-icon nut-icon-left" @click="backClick"></i>
    </template>
  </nut-navbar>

  <div class="login">
    
    <div class="login-bn"></div>
    <div class="login-bd">
      <h3 class="s2">{{ edit ? editTitle : addTitle }}</h3>
      <p class="addcard-text">{{$t('bankCard.addcard_text')}}</p>

      <div class="login-inp">
        <label class="fs12 fc-999">{{$t('bankCard.realName')}}<span class="c-red">*</span></label>
        <p class="err-info" v-show="realNameErrText">{{realNameErrText}}</p>
        <login-input
          label=""
          :placeholder="$t('bankCard.realNamePlaceholder')"
          type="text"
          rule="^.{4,30}$"
          maxLength="30"
          v-model="bankInfo.realName"
          @inputChange="(res) => (bankInfo.realName = res)"
        />
      </div>

      <div class="login-inp">
        <label class="fs12 fc-999">{{$t('bankCard.ifscCode')}}<span class="c-red">*</span></label>
        <p class="err-info" v-show="ifscCodeErrText">{{ifscCodeErrText}}</p>
        <login-input
          label=""
          :placeholder="$t('bankCard.ifscCodePlaceholder')"
          type="text"
          rule="^.{4,30}$"
          maxLength="30"
          v-model="bankInfo.ifscCode"
          @inputChange="(res) => (bankInfo.ifscCode = res)"
        />
      </div>

      <!-- <div class="login-inp">
        <label class="fs12 fc-999">{{$t('bankCard.bankName')}}<span class="c-red">*</span></label>
        <p class="err-info" v-show="bankNameErrText">{{bankNameErrText}}</p>
        <login-input
          label=""
          :placeholder="$t('bankCard.bankNamePlaceholder')"
          type="text"
          rule="^.{4,30}$"
          maxLength="30"
          v-model="bankInfo.bankName"
          @inputChange="(res) => (bankInfo.bankName = res)"
        />
      </div> -->

      <div class="login-inp">
        <label class="fs12 fc-999">{{$t('bankCard.bankAccount')}}<span class="c-red">*</span></label>
        <p class="err-info" v-show="bankAccountErrText">{{bankAccountErrText}}</p>
        <login-input
          label=""
          :placeholder="$t('bankCard.bankAccountPlaceholder')"
          type="text"
          rule="^.{4,30}$"
          maxLength="30"
          v-model="bankInfo.bankAccount"
          @inputChange="(res) => (bankInfo.bankAccount = res)"
        />
      </div>

      <div class="login-inp">
        <label class="fs12 fc-999">{{$t('bankCard.upiVpa')}}<span class="c-red">*</span></label>
        <p class="err-info" v-show="upiVpaErrText">{{upiVpaErrText}}</p>
        <login-input
          label=""
          :placeholder="$t('bankCard.upiVpaPlaceholder')"
          type="text"
          rule="^.{4,30}$"
          maxLength="30"
          v-model="bankInfo.upiVpa"
          @inputChange="(res) => (bankInfo.upiVpa = res)"
        />
      </div>

      <!-- <div class="login-inp">
        <label class="fs12 fc-999">{{$t('bankCard.email')}}<span class="c-red">*</span></label>
        <p class="err-info" v-show="emailErrText">{{emailErrText}}</p>
        <login-input
          label=""
          :placeholder="$t('bankCard.emailPlaceholder')"
          type="text"
          rule="^.{4,30}$"
          maxLength="30"
          v-model="bankInfo.email"
          @inputChange="(res) => (bankInfo.email = res)"
        />
      </div> -->

      
      <div class="login-btn">
        <nut-button block type="info" @click="addBankCard"
          >{{$t('global.confirm')}}</nut-button
        >
      </div>
      
    </div>
  </div>

</div>


</template>

<script>
import { reactive } from "vue";
import { useRouter } from "vue-router";
import loginInput from "@/components/loginInput.vue";
import axios from "axios";
import { Toast } from "@nutui/nutui";

export default {
  components: {
    loginInput,
  },
  data() {
    return {
      editTitle: this.$t('bankCard.editTitle'),
      addTitle: this.$t('bankCard.addTitle'),
      edit:false,
      bankNamePopup: false,
      id: null,
      target: null,
      bankInfo: {
        realName: "",
        ifscCode: "",
        bankAccount: "",
        upiVpa:"",
      },
      realNameErrText: '',
      ifscCodeErrText: '',
      bankAccountErrText: '',
      upiVpaErrText: '',
    };
  },

  methods: {
    gettingData() {
      if(this.$route.query.id){
        this.edit = true
        this.id = this.$route.query.id
        this.bankInfo.realName = this.$route.query.realName
        this.bankInfo.ifscCode = this.$route.query.ifscCode
        this.bankInfo.bankAccount = this.$route.query.bankAccount
        this.bankInfo.upiVpa = this.$route.query.upiVpa
      }
      if(this.$route.query.target){
        this.target  = this.$route.query.target
      }
    },
    addBankCard() {
      this.bankInfo.realName = this.bankInfo.realName.replace(/\s*/g,"")
      this.realNameErrText = ''
      this.ifscCodeErrText = ''
      this.bankAccountErrText = ''
      this.upiVpaErrText = ''
      if(this.bankInfo.realName === ""){
        this.realNameErrText = this.$t('global.errInfoRequired')
        return
      }else if(this.bankInfo.ifscCode === ""){
        this.ifscCodeErrText = this.$t('global.errInfoRequired')
        return
      }else if(this.bankInfo.bankAccount === ""){
        this.bankAccountErrText = this.$t('global.errInfoRequired')
        return
      }else if(this.bankInfo.upiVpa === ""){
        this.upiVpaErrText = this.$t('global.errInfoRequired')
        return
      }else {
        axios.post("/api/user/bank/save", {
            id: this.id,
            bankInfo: this.bankInfo
          })
          .then((response) => {
            if (response.data.code === 0) {
              Toast.success(this.$t('bankCard.success'));
              setTimeout(() => {
                if(this.target){
                  this.$router.push({
                    path: this.target,
                    query: {
                      amountType: 'coin'
                    }
                  });
                }else{
                  this.$router.push("/bankCard");
                }
              }, 1000);
            } else if (response.data.code === 1004){
              let res = response.data.data
              Toast.fail(this.$t('global.INVALID_INPUT_VALUE'));
              for(var i in res){	
                switch (res[i].fieldName) {
                  case "realName":
                    this.realNameErrText = res[i].description
                    break;
                  case "ifscCode":
                    this.ifscCodeErrText = res[i].description
                    break;
                  case "bankAccount":
                    this.bankAccountErrText = res[i].description
                    break;
                  case "upiVpa":
                    this.upiVpaErrText = res[i].description
                    break;
                }
              }
            }
          })
      }
    }
  },
  watch: {
    '$route': 'gettingData'
  },
  created() {
    this.gettingData()
  },
  setup() {
    const state = reactive({});
    const router = useRouter();
    const methods = {
      backClick() {
        router.go(-1);
      },
      titIconClick(){
        router.push({
        name: "myHome",
      });
      }
    };
    return {
      ...methods,
      state,
    };
  },
};
</script>

<style scoped></style>
<style></style>